import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import Sidebar from "./components/SidebarR.jsx";
import { domain, headers, colors, settings } from './Variables'

import dashboardRoutes from "./routers.jsx";
import { connect } from 'react-redux'

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      count: 0,
      modules:[]
    }
  }

  componentWillMount(){
    this.fetchModules()
  }

  fetchModules() {

    fetch(domain + '/modules/getAll', {
      method: 'POST',
      body: JSON.stringify({}),
      headers: headers
    })

      .then(response => response.json()).then(data => {
        if (data.status == 200) {

          // console.log(data)
          this.setState({ modules: data.info })
        }

      }).catch(error => this.setState({ error, isLoading: false }));
  }

  
  // componentDidMount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.mainPanel);
  //     // document.body.classList.toggle("perfect-scrollbar-on");
  //   }

  // }
  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //     // document.body.classList.toggle("perfect-scrollbar-on");

  //   }
  // }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  }
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  }




 


  render() {

    return (
      <div className="" >
        {/* <Sidebar
          {...this.props}
          routes={dashboardRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        /> */}
        
        <div className=" " ref="mainPanel" style={{ width: 'calc(100%)' }} >
          {/* backgroundColor: '#f6f6f8ee' */}
          {/* <Header {...this.props} /> */}
          <Switch>
            {dashboardRoutes.map((prop, key) => {

              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              if (this.props.senario.name != null || prop.public) {
                let MyComponent = prop.component
                return (
                <Route path={prop.path} render={(props) => <MyComponent modules={this.state.modules} {...props} />}  key={key}  />
                );
              }

            })}
          </Switch>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    senario: state.senario
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSenario: (name, id) => dispatch({ type: 'SET_SENARIO', name, id })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
