import React from "react";

class Header extends React.Component {

    state = {
        hover: false,
        open: false
    }
    

    render() {
        return (
            <div style={{ flex: 1, flexDirection: 'column', position: 'relative' }} >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div onClick={this.props.function} style={{ cursor: 'pointer', padding: 3, paddingLeft: 15, paddingRight: 15, borderRadius: 4, backgroundColor: (this.props.options.color?this.props.options.color:'#78b2d0') + '15', justifyContent: 'center', alignItems: 'center' }}>
                <p className={'mediumiransansfont'} style={{ margin: 7, color: '#fff', textAlign: 'center', fontSize: 10, margin: 0, color: (this.props.options.color?this.props.options.color:'#78b2d0') }}>{this.props.title}</p>
              </div>
            </div>
          </div>
        );
    }
}




export default Header;
