import React from "react";
// used for making the prop types of this component
// import PropTypes from "prop-types";
import {

    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap";

class Header extends React.Component {

    state = {
        hover: false,
        open: false
    }
    hover() {
        if (!this.state.hover) {
            this.setState({ hover: true })
        }
    }

    unHover() {
        this.setState({ hover: false })
    }

    dropdownToggle = (e) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    openDropDown = () => {
        if (!this.state.open) {
            document.addEventListener('mousedown', this.handleClickOutside);

        }
        this.setState({ open: !this.state.open })
    }


    //   componentDidMount() {
    //     document.addEventListener('mousedown', this.handleClickOutside);
    //   }

    //   componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.handleClickOutside);
    //   }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.open) {
            //   alert('You clicked outside of me!');
            this.setState({ open: false })
            document.removeEventListener('mousedown', this.handleClickOutside);

        }
    }


    render() {
        return (
            <div ref={ref => this.setWrapperRef(ref)} style={{ ...translate,padding: 0, padding: 0, borderRightColor: '#fff', borderRightWidth: 0, borderBottomColor: '#fff', borderBottomWidth: 0, borderBottomStyle: 'solid', borderRightStyle: 'solid', borderTopStyle: 'none',textAlign:'center' }} onMouseOver={() => this.hover()} onMouseLeave={() => this.unHover()} >
                <div style={{ flex: 1, flexDirection: 'column', position: 'relative' }}>
                    {/* <a onClick={()=>this.openDropDown()} style={{cursor:'pointer',flex:1}}> */}
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <div onClick={() => this.openDropDown()} style={{ cursor: 'pointer', padding: 3, paddingLeft: 15, paddingRight: 15, borderRadius: 4, backgroundColor:  '#78b2d020', opacity: this.state.hover ? 0.9 : 1, justifyContent: 'center', alignItems: 'center' }}>
                            <p className={'mediumiransansfont'} style={{ margin: 7, textAlign: 'center', fontSize: 10, margin: 0, color: '#78b2d0' }}>{this.props.title}</p>
                            {/* ebfeff
                        fff3f8 */}
                        </div>
                    </div>
                    {/* </a> */}

                    <div className={'mediumiransansfont'}  style={{ position: 'absolute', top: 35, display: this.state.open ? 'block' : 'none', zIndex: 10, left: 40 }}>
                        {/* <div style={{...shadow, borderRadius:2,width:15,height:15,backgroundColor:'#fff',position:'relative',left:70,top:5,zIndex:-1,borderColor:'#eee',borderWidth:1,borderStyle:'solid'}}></div> */}

                        <div style={{ ...shadow, backgroundColor: '#fff', zIndex: 12, borderColor: '#eee', borderWidth: 0, borderStyle: 'solid', padding: 15, borderRadius: 8, alignItems: 'center', justifyContent: 'center' }}>
                            <p className={'mediumiransansfont'} style={{ color: '#000', marginBottom: 0, marginTop: 5, opacity: 0.9, textAlign: 'center', fontSize: 10 }}>CHOOSE STATUS</p>
                            <p style={{ color: '#000', marginBottom: 10, marginTop: 0, opacity: 0.5, textAlign: 'center', fontSize: 9 }}>You can add more</p>


                            <div onClick={() => this.openDropDown()} style={{ width: 120, height: 30, cursor: 'pointer', flex: 1, backgroundColor: '#78b2d020', marginRight: 0, justifySelf: 'stretch', margin: 0, borderRadius: 4, justifyContent: 'center', alignItems: 'center', padding: 7 }}>
                                <p style={{ color: '#78b2d0', textAlign: 'center', fontSize: 12 }}>انجام شد</p>
                            </div>
                            <div onClick={() => this.openDropDown()} style={{ width: 120, height: 30, cursor: 'pointer', flex: 1, backgroundColor: '#df465e20', marginRight: 0, justifySelf: 'stretch', margin: 0, borderRadius: 4, justifyContent: 'center', alignItems: 'center', padding: 7, marginTop: 5 }}>
                                <p style={{ color: '#df465ecc', textAlign: 'center', fontSize: 12 }}>دچار اشکال</p>
                            </div>
                            <div onClick={() => this.openDropDown()} style={{ width: 120, height: 30, cursor: 'pointer', flex: 1, backgroundColor: '#fcbf7740', marginRight: 0, justifySelf: 'stretch', margin: 0, borderRadius: 4, justifyContent: 'center', alignItems: 'center', padding: 7, marginTop: 5 }}>
                                <p style={{ color: '#fcbf77', textAlign: 'center', fontSize: 12 }}>در حال انجام</p>
                            </div>

                            <p style={{ color: 'rgba(0,122,255,1)', fontWeight: 'bold', marginBottom: 0, marginTop: 15, opacity: 0.9, textAlign: 'center', fontSize: 10 }}>ADD / EDIT</p>

                        </div>
                    </div>
                    {/* 
                    <Dropdown    
                        isOpen={this.state.dropdownOpen}
                        toggle={e => this.dropdownToggle(e)}
                    >
                        <DropdownToggle caret nav>
                        <i className="nc-icon nc-bell-55" />
                        <p>
                            <span className="d-lg-none d-md-block">Some Actions</span>
                        </p>
                        </DropdownToggle>
                        <DropdownMenu right>
                        <DropdownItem tag="a">Action</DropdownItem>
                        <DropdownItem tag="a">Another Action</DropdownItem>
                        <DropdownItem tag="a">Something else here</DropdownItem>
                        </DropdownMenu>
              </Dropdown> */}


                </div>
            </div >
        );
    }
}

const translate = {
    transition: 'all 0.5s',

}

const shadow = {
    boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
}



export default Header;
