import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Modal extends React.Component {
    state = {
        opacity: 1,
        showModal: false,
        maxWidth: 600
    }

    hideModal = () => {
        this.setState({ opacity: 0 })
        // this.props.parent.setState({showModal:false})
        setTimeout(function () { //Start the timer
            this.setState({ showModal: false })

        }.bind(this), 550)

        if (this.props.cancelModal)
            this.props.cancelModal()
    }

    showModal = () => {
        this.setState({ opacity: 1 })
        this.setState({ showModal: true })

    }

    componentWillMount() {
        if (this.props.maxWidth) {
            this.setState({ maxWidth: this.props.maxWidth })
        }
    }

    render() {
        if (this.state.showModal) {
            return (
                <div style={{ ...styles, opacity: this.state.opacity, overflow: 'auto', zIndex: 100 + (this.props.zIndex ? this.props.zIndex : 0) }}>
                    <a style={{ ...backStyles }} onClick={() => this.hideModal()}></a>

                    <div className="test" style={{ ...contentStyle, maxWidth: this.state.maxWidth,  }}>
                        {/* <p className="mediumiransansfont" style={{textAlign:'right'}}>افزودن سناریو</p> */}
                        <div className="w-100" style={{ backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : '#fff',padding: this.props.padding ? this.props.padding : 30, borderRadius: 8 }}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div></div>
            )
        }
    }
}


const styles = {
    transition: 'all 0.5s ease-out',
    // transition: 'zIndex 0s ease-out',
    backdropFilter: 'blur(10px) saturate(180%)',
    WebkitBackdropFilter: 'blur(10px) saturate(180%)',

    backgroundColor: 'rgba(0,0,0,0.4)',
    position: 'fixed',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    // display:'flex',
    // alignItems:'center',
    // justifyContent:'center',

    // zIndex: 50000,
    top: 0,
    left: 0,
    // paddingTop: '5%',
    paddingLeft: '0%',
    // paddingBottom: '5%',

    // backDropFilter:'blur(10px)',


}


const backStyles = {
    // transition: 'all 0.5s ease-out',
    // backgroundColor:'rgba(0,0,0,0.6)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    // backDropFilter:'blur(10px)',
    // zIndex:2000,
    top: 0,
    left: 0,
    // paddingTop:'10%',
    // paddingLeft:'0%',

}



const contentStyle = {
    position: 'relative',
    margin: 'auto',
    border: '0px solid #888',
    width: '80%',
    borderRadius: 10,

    // overflow: 'auto',
    maxHeight: '100%',
    paddingTop: '5%',
    paddingBottom: '5%',


    // padding: 30
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    // -webkit-animation-name: animatetop;
    // -webkit-animation-duration: 0.4s;
    // animation-name: animatetop;
    // animation-duration: 0.4s
}

export default Modal;
