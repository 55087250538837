import React from "react";
// used for making the prop types of this component
// import PropTypes from "prop-types";

class Header extends React.Component {
    render() {
        return (
            <p className='mediumiransansfont' style={{ margin: 7, letterSpacing: 0, fontSize: 12, color: '#3b3a40', textAlign: 'center' }}>{this.props.title?String(this.props.title):''}</p>
        );
    }
}



export default Header;
