import Cookies from 'universal-cookie';
const cookies = new Cookies();

// const domain = "http://localhost/alvand"
// const contentUrl = "http://localhost/assets/images/"
// const content = "http://localhost/assets"
// const mainDomain = "https://alvandclinic.com"

// const domain = "http://lomanab.ir/lomanab"
// const contentUrl = "http://lomanab.ir/assets/images/"
// const content = "http://lomanab.ir/assets/"
// const mainDomain = "http://lomanab.ir"


// const domain = "http://localhost/shopera/"
// const contentUrl = "http://localhost/assets/images/"
// const content = "http://localhost/assets"

// const domain = "https://chilik.net/chilik"
// const contentUrl = "https://chilik.net/assets/images/"
// const content = "https://chilik.net/assets/"
// const mainDomain = "https://chilik.net"

// const domain = "https://opia-home.com/opia"
// const contentUrl = "https://opia-home.com/assets/images/"
// const content = "https://opia-home.com/assets/"
// const mainDomain = "https://opia-home.com"


// const domain = "http://api.gamas20.ir/gamas20"
// const contentUrl = "http://api.gamas20.ir/assets/images/"
// const content = "http://api.gamas20.ir/assets/"
// const mainDomain = "http://api.gamas20.ir"

const domain = "https://anbarmazad.com/anbarmazad"
const contentUrl = "https://anbarmazad.com/assets/images/"
const content = "https://anbarmazad.com/assets/"
const mainDomain = "https://anbarmazad.com"

// const domain = "http://doctoradib.com/dradib"
// const contentUrl = "http://doctoradib.com/assets/images/"
// const content = "http://doctoradib.com/assets/"
// const mainDomain = "http://doctoradib.com"


// const domain = "http://raeesmelk.com/raeesmelk"
// const contentUrl = "http://raeesmelk.com/assets/images/"
// const content = "http://raeesmelk.com/assets/"
// const mainDomain = "http://raeesmelk.com"

// const domain = "http://alvand.antbuildz.com/alvand"
// const contentUrl = "http://alvand.antbuildz.com/assets/images/"
// const content = "http://alvand.antbuildz.com/assets/"
// const mainDomain = "https://alvandclinic.com"


// const domain = "http://sarvdietclinic.com/sarv"
// const contentUrl = "http://sarvdietclinic.com/assets/images/"
// const content = "http://sarvdietclinic.com/assets/"
// const mainDomain = "http://sarvdietclinic.com"


// const domain = "http://altonhomekaraj.com/alton"
// const contentUrl = "http://altonhomekaraj.com/assets/images/"
// const content = "http://altonhomekaraj.com/assets/"
// const mainDomain = "http://altonhomekaraj.com"


// const domain = "http://configure.antbuildz.com/configure"
// const contentUrl = "http://configure.antbuildz.com/assets/images/"
// const content = "http://configure.antbuildz.com/assets/"
// const mainDomain = "http://configure.antbuildz.com"


// const domain = "http://ops.antbuildz.com/rashac"
// const contentUrl = "http://ops.antbuildz.com/assets/images/"
// const content = "http://ops.antbuildz.com/assets/"
// const mainDomain = "http://ops.antbuildz.com"

// const domain = "http://rashac.com/rashac"
// const contentUrl = "http://rashac.com/assets/images/"
// const content = "http://rashac.com/assets/"
// const mainDomain = "http://rashac.com"

// const domain = "http://ozhenhome.com/ozhen"
// const contentUrl = "http://ozhenhome.com/assets/images/"
// const content = "http://ozhenhome.com/assets/"
// const mainDomain = "http://ozhenhome.com"


// const domain = "https://test.bayamarketing.ir/baya"
// const contentUrl = "https://test.bayamarketing.ir/assets/images/"
// const content = "https://test.bayamarketing.ir/assets/"
// const mainDomain = "https://test.bayamarketing.ir"

// const domain = "https://baya.chilik.net/baya"
// const contentUrl = "https://baya.chilik.net/assets/images/"
// const content = "https://baya.chilik.net/assets/"
// const mainDomain = "https://baya.chilik.net"


// const domain = "http://opia-home.com/numberOne"
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    // 'Authorization': cookies.get('token') ? cookies.get('token') : ''
    // 'Access-Control-Allow-Origin': '*'
}

const colors = { primary: '#2f70f6', primaryText: '#fff', inactive: '#eee', sharpRed: '#ed2939' }

// const settings = { siteName: 'Lomanab', direction: 'rtl' }
// const allNames = { siteName: 'Lomanab', persianName: 'لماناب', englishName: 'lomanab.ir', mainDomain: 'http://lomanab.ir' }

// const settings = { siteName: 'Opia', direction: 'rtl' }
// const allNames = { siteName: 'Opia', logo: 'opiaLogo.png', persianName: 'Opia', englishName: 'opia-home.com', mainDomain: 'http://opia-home.com' }

// const settings = { siteName: 'گاماس ۲۰', direction: 'rtl' }
// const allNames = { siteName: 'گاماس ۲۰', persianName: 'گاماس ۲۰', englishName: 'gamas20.ir', mainDomain: 'http://gamas20.ir' }

// const settings = { siteName: 'گاماس ۲۰', direction: 'rtl' }
// const allNames = { siteName: 'گاماس ۲۰', persianName: 'گاماس ۲۰', englishName: 'gamas20.ir', mainDomain: 'http://gamas20.ir' }

// const settings = { siteName: 'الوند', direction: 'rtl' }
// const allNames = { siteName: 'الوند', logo: 'alvandLogo.png', persianName: 'الوند', englishName: 'alvandclinic.com', mainDomain: 'https://alvandclinic.com/' }


// const settings = { siteName: 'رئیس ملک', direction: 'rtl' }
// const allNames = { siteName: 'رئیس ملک', logo: 'raeesMelk.png', persianName: 'رئیس ملک', englishName: 'raeesmelk.com', mainDomain: 'http://raeesmelk.com' }

const settings = { siteName: 'انبار مازاد', direction: 'rtl' }
const allNames = { siteName: 'انبار مازاد', logo: 'logo-a.png', persianName: 'انبار مازاد', englishName: 'anbarmazad.com', mainDomain: 'http://anbarmazad.com' }


// const settings = { siteName: 'Dr. Adib', direction: 'rtl' }
// const allNames = { siteName: 'Dr. Adib', logo: 'drAdib.png', persianName: 'Dr. Adib', englishName: 'doctoradib.com', mainDomain: 'http://doctoradib.com' }

// const settings = { siteName: 'Chilik', direction: 'rtl' }
// const allNames = { siteName: 'Chilik', logo: 'chilikLogo.png', persianName: 'چیلیک', englishName: 'Chilik.net', mainDomain: 'https://Chilik.net' }


// const settings = { siteName: 'SarvDiet', direction: 'rtl' }
// const allNames = { siteName: 'SarvDiet', logo: 'sarvLogo.png', persianName: 'سرو دایت', englishName: 'sarvdiet.com', mainDomain: 'http://sarvdiet.com' }


// const settings = { siteName: 'معین کالا', direction: 'rtl' }
// const allNames = { siteName: 'معین کالا', logo: 'moeinLogo.png', persianName: 'معین کالا', englishName: 'altonhomekaraj.com', mainDomain: 'http://altonhomekaraj.com' }

// const settings = { siteName: 'Configure', direction: 'rtl' }
// const allNames = { siteName: 'Configure', logo: 'configure.png', persianName: '', englishName: 'configure.com', mainDomain: 'http://configure.com' }

// const settings = { siteName: 'Rashac', direction: 'rtl' }
// const allNames = { siteName: 'Rashac', logo: 'configure.png', persianName: 'راشاک', englishName: 'Rashac.com', mainDomain: 'http://Rashac.com' }

// const settings = { siteName: 'Ozhen', direction: 'rtl' }
// const allNames = { siteName: 'Ozhen', logo: 'ozhen.png', persianName: 'اوژن', englishName: 'Ozhen.com', mainDomain: 'http://Rashac.com' }

// const settings = { siteName: 'baya marketing', direction: 'rtl' }
// const allNames = { siteName: 'baya marketing', logo: 'bayaLogo.png', persianName: 'بایا مارکتینگ', englishName: 'baya marketing.net', mainDomain: 'https://bayamarketing.ir' }


export { domain, headers, colors, settings, mainDomain, allNames, contentUrl, content };