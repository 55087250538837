import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import auth from '../Auth'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { domain, headers, allNames } from '../Variables'


class Login extends React.Component {
    state = {
        wrongInfo: false
    }

    componentDidMount(){
        // console.log("SIGN IN")
        document.addEventListener("keydown", this._handleKeyDown);

    }

  

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);

    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.signinClicked()
        }
    }

    usernameChanged = (event) => {
        if (event.target.value.length > 0) {
            this.refs.username.className = "input100 has-val"
        } else if (this.refs.username.placeholder == '') {
            this.refs.username.className = "input100"
        }
    }

    passwordChanged = (event) => {
        if (event.target.value.length > 0) {
            this.refs.password.className = "input100 has-val"
        } else if (this.refs.password.placeholder == '') {

            this.refs.password.className = "input100"
        }
    }




    signinClicked = () => {
        var canLogin = true
        if (this.refs.username.value.length == 0) {
            this.refs.username.className = "input100 has-val"
            this.refs.username.placeholder = "ضروری است"
            canLogin = false
        }

        if (this.refs.password.value.length == 0) {
            this.refs.password.className = "input100 has-val"
            this.refs.password.placeholder = "ضروری است"
            canLogin = false
        }

        if (canLogin) {
            this.fetchLogin()
        }
    }


    fetchLogin() {

        fetch(domain + `/employee/signin`, {
            method: 'POST',
            body: JSON.stringify({ "username": this.refs.username.value, "password": this.refs.password.value }),
            headers: headers
        })

            .then(response => response.json()).then(data => {

                // console.log(data)
                if (data.status == 200) {

                    auth.login(() => {
                        const cookies = new Cookies();
                        cookies.set('adminToken', data.token, { path: '/' });
                        cookies.set('username', this.refs.username.value, { path: '/' });
                        cookies.set('role', data.user.role, { path: '/' });
                        cookies.set('id', data.user._id, { path: '/' });

                        this.props.history.push('/dashboard')
                    }
                    )
                } else if (data.message) {
                    this.setState({ wrongInfo: data.message })
                }

            }).catch(error => this.setState({ error, isLoading: false }));
    }



    render() {

        if (auth.isAuthenticated()) {
            return <Redirect to={{ pathname: 'dashboard', state: { from: this.props.location } }} />
        } else {
            return (


                <div class="limiter" style={{ direction: 'ltr' }}>
                    <div class="container-login100">

                        {/* <img src="../wlogo.png" width="100px" style="position: fixed; top:10px; right: 10px" /> */}
                        <div class="wrap-login100 " style={{ paddingBottom: 160, paddingTop: 50 }}>
                            <form class="login100-form validate-form">
                                <span class="login100-form-title">
                                    <p className="mediumiransansfont" style={{ fontSize: 22, color: '#fff' }}>ورود کارکنان {allNames.persianName}</p>
                                </span>

                                <div class="wrap-input100 rs1 validate-input" data-validate="Username is required">

                                    <input placeholder={''} className="input100" ref={'username'} onChange={e => this.usernameChanged(e)} type="text" name="username" />
                                    <span class="label-input100 "><p className="mediumiransansfont">نام کاربری</p></span>
                                </div>


                                <div class="wrap-input100 rs2 validate-input" data-validate="Password is required">
                                    <input placeholder={''} className="input100" ref={'password'} style={{ border: 'none' }} onChange={e => this.passwordChanged(e)} type="password" name="pass" />
                                    <span class="label-input100"><p className="mediumiransansfont">کلمه عبور</p></span>
                                </div>

                                <div class="container-login100-form-btn">
                                    <a onClick={() => this.signinClicked()} style={{ cursor: 'pointer' }} class="login100-form-btn">
                                        <p className="mediumiransansfont" style={{ color: '#fff' }}>ورود</p>
                                    </a>
                                </div>

                                {this.state.wrongInfo && (<p className="mediumiransansfont" style={{ width: '100%', marginTop: 10, textAlign: 'center', color: '#ff0000', opacity: 1, fontSize: 15 }}>نام کاربری یا کلمه عبور اشتباه است</p>)}
                                {/* {!this.state.wrongInfo &&(<p className="lightiransansfont" style={{width: '100%',marginTop:10, textAlign: 'center', color: '#ff0000', opacity: 1, fontSize: 15 }}></p>)} */}


                                <div style={{ width: '100%', paddingTop: 25, alignSelf: 'stretch', textAlign: 'center' }}>
                                    {/* <p className="mediumiransansfont" style={{ textAlign: 'center', color: '#fff', opacity: 0.6, fontSize: 12 }}>RASAN GROUP</p> */}
                                    <p className="lightiransansfont" style={{ textAlign: 'center', color: '#fff', opacity: 0.6, fontSize: 10 }}>DESIGNED BY PEZHMAN.TECH</p>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            );
        }
    }
}

export default Login;
