import React from 'react'

import mapboxgl, { setRTLTextPlugin } from "mapbox-gl";
import { type } from 'jquery';
// import ReactMapboxGl, { Marker } from "react-mapbox-gl";


setRTLTextPlugin(
    "https://www.parsimap.com/scripts/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js"
);

// const Map = ReactMapboxGl({ accessToken: "pk.eyJ1IjoicG91eWFwZXpobWFuIiwiYSI6ImNrZHZwd2JiYTBzeHgyeWtqa2xodmNrdnQifQ.W9n1rw2PX1V1PjrcBDitrA" });


class MapInput extends React.Component {
    state = {
        selectedCountry: {},
        zoom: [12]
    }

    componentDidMount() {
        console.log(this.props.title)

        let title = [-122.8479, 49.245773]
        if (this.props.title) {
            title = this.props.title
            if (typeof title == 'object' && !Array.isArray(title)) {
                title = title.coordinates
            }
        }


        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/light-v10',
            center: title ,
            zoom: this.state.zoom,
            accessToken: "pk.eyJ1IjoicG91eWFwZXpobWFuIiwiYSI6ImNrZHZwd2JiYTBzeHgyeWtqa2xodmNrdnQifQ.W9n1rw2PX1V1PjrcBDitrA"

        });


        if (Array.isArray(title)) {
            var el = document.createElement('div');
            el.className = 'marker';

            new mapboxgl.Marker(el)
                .setLngLat([title[0], title[1]])
                .addTo(map);
        }

        map.on('move', () => {

            // console.log(
            //     {
            //         lng: map.getCenter().lng,
            //         lat: map.getCenter().lat,
            //     }
            // )

            this.setState({
                lng: map.getCenter().lng,//.toFixed(4),
                lat: map.getCenter().lat,//.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
            this.props.onChange(this.props.headerkey, [map.getCenter().lng, map.getCenter().lat])

        });
    }

    componentDidUpdate(prevprops) {
        if (this.props.title != prevprops && (prevprops == null || prevprops == '')) {
            this.map.center = this.props.title
        }
    }

    // moveMap(e) {
    //     if (e.transform._center.lat != this.props.title?.lat && e.transform._center.lng != this.props.title?.lng)
    //         // console.log(e.transform._center)
    //         this.props.changeValue(this.props.headerKey, Object(e.transform._center))

    // }

    // zoomEnd = (e) => {
    //     console.log(this.state.zoom)
    //     if (this.map.getZoom) {
    //         let zoom = this.map.getZoom()
    //         this.setState({ zoom: [zoom] })
    //         console.log(zoom)

    //         // if (this.map.getZoom) {

    //         // }
    //         if (e.transform._center.lat != this.props.title?.lat && e.transform._center.lng != this.props.title?.lng) {

    //         }
    //     }
    //     // console.log(e.transform._center)
    //     // this.props.changeValue(this.props.headerKey, Object(e.transform._center))

    // }


    render() {
        return (
            <div className="w-100" style={{ position: 'relative' }}>

                <div ref={el => this.mapContainer = el} style={{ overflow: 'hidden', position: 'relative' }} />
                {/* <Map
                    style="https://www.parsimap.com/styles/street.json"
                    containerStyle={{ height: "200px", width: "100%" }}
                    center={this.props.title ? this.props.data : [51.41, 35.7575]}
                    zoom={this.state.zoom}
                    onMoveEnd={e => this.moveMap(e)}
                    onZoomEnd={e => this.zoomEnd(e)}
                    onStyleLoad={el => this.map = el}
                    ref={el => this.map = el}
                >
                </Map> */}

                {/* {!this.props.header && ( */}
                <div className="center-marker"></div>
                {/* )} */}
            </div>
        )
    }
}

export default (MapInput);