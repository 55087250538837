import React from "react";
// import Modal from "../components/Modal.jsx";
// import { Link, Route } from 'react-router-dom'
import { domain, headers, settings } from '../Variables'
import { modules, pagesData } from '../PagesData'
import Container from '../components/Container'
import ModuleBox from '../components/Boxes/Module'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// var distanceInWordsToNow = require('date-fns/distance_in_words_to_now')


class Dashboard extends React.Component {

  state = {
    showModal: false,

    modules: [],

    basicSelectedIndex: null,
    senarioSelectedIndex: 0

  }

  componentWillMount() {

    let role = cookies.get('role')

    if (role == 'editor') {
      this.props.history.goBack()
    }else{
      this.setState({ modules,showPage:true })
      document.title = settings.siteName + ' تنظیمات'
    }
   
    // this.fetch()
  }



  render() {
    if(this.state.showPage){
    return (



      <div className=" mediumiransansfont" style={{}}>

        <Container modules={this.props.modules} isOpen={true} modalIsOpen={false} location={this.props.location} >

          <div style={{ paddingRight: '6%', paddingLeft: '6%', }}>

            <div className="row" style={{ marginBottom: 20 }}>

              <div className={'col-12 d-flex align-items-center p-0'} style={{ marginBottom: 20 }}>
                <div style={{ width: '100%', backgroundColor: '#fff', padding: 20, marginLeft: 0, display: 'flex', borderRadius: 20, alignItems: 'center' }}>

                  <div style={{ backgroundColor: '#fff', borderRadius: 50, height: 75, width: 75, padding: 10, marginLeft: 20, display: 'flex' }}>
                    <img style={{ flex: 1 }} src="/images/settings.png" />
                  </div>

                  <div>
                    <p style={{ fontSize: 25, marginBottom: 0, marginBottom: 0, marginTop: 0, color: '#000', marginRight: 0 }}>تنظیمات سیستم</p>
                    <p style={{ fontSize: 12, letterSpacing: 0, marginBottom: 0, marginTop: 0, color: '#000', marginRight: 0 }}>ماژول های تنظیمات سیستم</p>
                  </div>

                </div>
              </div>

              <ModuleBox data={{ customPage: '/modules', title: 'ماژول ها', description: 'System Modules' }} />
              {/* <ModuleBox data={{ customPage: '/formbuilder', title: 'عمومی', description: 'Group Settings' }} /> */}
              <ModuleBox data={{ customPage: '/users', title: 'کارکنان', description: 'Users Settings' }} />
              <ModuleBox data={{ customPage: '/config', title: 'کانفیگ', description: 'Configuration' }} />

              {/* <ModuleBox data={{ customPage: '/groups', title: 'گروه ها', description: 'Group Settings' }} /> */}


            </div>
          </div>
        </Container>

      </div>
    );
    }else{
      return(
        <div></div>
      )
    }
  }
}

export default Dashboard;
