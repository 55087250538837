import React from "react";
// used for making the prop types of this component
// import PropTypes from "prop-types";

class Header extends React.Component {

    state = {
        value: ''
    }

    componentWillMount() {
        if (this.props.title)
            this.setState({ value: this.props.title })
    }


    componentWillReceiveProps(newProps) {

        // console.log("recieved")
        // console.log(newProps.title)
        if (this.props.title != newProps.title) {
            // console.log("MATCH")
            this.setState({ value: newProps.title })
        }
    }

    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }

    handleChange(evt) {

        this.setState({ value: evt.target.value });
        this.props.onChange(this.props.headerkey, evt.target.value, this.props.extra)
    }



    render() {
        let info = this.props.info
        return (
            <div className='mt-0'>
                {/* <label className={'mb-2 mr-1 ml-1 ' + (info.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{info.label}</label> */}
                <input disabled={info.disabled} onChange={e => { this.handleChange(e) }} className='form-control mediumiransansfont' value={this.state.value} placeholder={info.placeHolder} style={{ backgroundColor: '#f7f7f7', borderColor: '#ddd', color: '#222', height: 35 }} />
            </div>
        );
    }
}

export default Header;
