import React from "react";
import { Card, CardHeader, CardBody, CardTitle, CardFooter, Row, Col } from "reactstrap";

import Button from "../components/CustomButton.jsx";
// import noVideo from "./assets/img/no-video.png";
import { domain, headers } from '../Variables'
import Loader from 'react-loader-spinner'
import 'cropperjs/dist/cropper.css';

import PersonCell from '../components/Inputs/Text'
import TextCell from '../components/Inputs/Text'
import IntegerCell from '../components/Inputs/Integer'
import StatusCell from '../components/Inputs/Text'
import SelectCell from '../components/Inputs/Select'
import FileCell from '../components/Inputs/File'
import MultiSelectCell from '../components/Inputs/MultiSelect'


class AddCategory extends React.Component {

    state = {
        headers: [
            { title: 'نوع معامله', key: 'transaction', type: 'select', options: { type: 'local', items: [{ title: "رهن و اجاره", value: 'rent' }, { title: 'خرید و فروش', value: 'sale' },{ title: 'پیش فروش', value: 'presale' }] }, settings: { required: true }, showMain: true },
            { title: 'نوع ملک', key: 'info.type', type: 'select', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'estateType' } }, settings: { required: true }, showMain: true },
            { title: 'محله', key: 'info.region', type: 'select', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'regions' } }, settings: { required: true }, showMain: true },
            { title: 'آدرس', key: 'address', type: 'text', settings: { required: true }, showMain: true },
            { title: 'متراژ', key: 'info.area', type: 'integer', settings: { required: true, input: 'number' }, showMain: true },
            { title: 'سن بنا', key: 'info.age', type: 'select', options: { type: 'local', items: [{ title: "نو ساز", value: '0' }, { title: '۱ تا ۳ سال ساخت', value: '1-3' }, { title: '۳ تا ۶ سال ساخت', value: '3-6' }, { title: '۶ تا ۱۰ سال ساخت', value: '6-10' }, { title: '۱۰ تا ۱۵ سال ساخت', value: '10-15' }, { title: '۱۵ تا ۲۰ سال ساخت', value: '15-20' }, { title: '۲۰ تا ۲۵ سال ساخت', value: '20-25' }, { title: '۲۵ تا ۳۰ سال ساخت', value: '25-30' }, { title: 'بیش از ۳۰ سال ساخت', value: '30+' }] }, settings: { required: true }, showMain: true },
            { title: 'تعداد پارکینگ', key: 'info.parking', type: 'select', options: { type: 'local', items: [{ title: "بدون پارکینگ", value: '0' }, { title: '۱ پارکینگ', value: '1' }, { title: '۲ پارکینگ', value: '2' }, { title: '۳ پارکینگ', value: '3' }, { title: 'بیش از سه پارکینگ', value: '4' }] }, settings: { required: false }, showMain: true },
            { title: 'جهت ملک', key: 'info.direction', type: 'select', options: { type: 'local', items: [{ title: "شمالی شمالی", value: 'north' }, { title: "شمالی جنوبی", value: 'north-south' }, { title: "جنوبی جنوبی", value: 'south' }, { title: "جنوبی شمالی", value: 'south-north' }] }, showMain: true },
            { title: 'تعداد اتاق', key: 'info.rooms', type: 'select', options: { type: 'local', items: [{ title: "بدون اتاق", value: '0' }, { title: "یک", value: '1' }, { title: "دو", value: '2' }, { title: "سه", value: '3' }, { title: "چهار", value: '4' }, { title: "بیشتر از ۴", value: '+4' }] }, showMain: true },
            { title: 'قیمت کل / رهن', key: 'price', type: 'integer', showMain: false },
            { title: 'قیمت اجاره', key: 'rentprice', type: 'integer', showMain: false },
            { title: 'نام مالک', key: 'owner', type: 'text', settings: { required: true }, showMain: true },
            { title: 'شماره مالک', key: 'phone', type: 'text', settings: { required: true }, showMain: true },
            { title: 'توضیحات', key: 'description', type: 'text', showMain: false },
            // { title: 'امکانات', key: 'info.equip', type: 'multiselect', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'estateType' } }, settings: { required: true }, showMain: true },
            { title: 'تصاویر', key: 'images', type: 'file', showMain: false, fullCol: true },
        ],

        errors: {}



        // headers: [
        //     {files: [],}
        //     type: '',
        //     description: '',
        //     category: '',
        //     business: '',
        //     coverTime: '00:00:00',
        //     isEditing: false,
        //     isLoading: false,
        //     businesses: [],
        //     categories: [],
        //     showVideo: false,
        //     videoType: ''
        // ]
    }


    componentWillMount() {

        // this.fetchCategories()
        // this.fetchBusinesses()
        let item = {}
        item = this.makeNewItem()
        this.setState({ currentItem: item })
        console.log(item)
    }


    makeNewItem = () => {
        let item = {}
        item['values'] = {}
        this.state.headers.forEach(header => {
            if (header.type != 'button') {
                item.values[header.key] = ''
            }
        });
        return item
    }



    fetchAdd() {

        if (this.validator()) {

            let keysToUplaod = []
            this.state.headers.forEach(element => {
                if (element.type == 'file' && this.state["files" + element.key] && this.state["files" + element.key].length > 0) {
                    keysToUplaod.push(element.key)
                }
            })

            if (keysToUplaod.length > 0) {

                let count = 0
                this.fileFetchLoop(count, keysToUplaod, () => {
                    this.fetchParams()
                })

            } else {
                this.fetchParams()
            }


        }
    }

    fileFetchLoop = (count, keys, cb) => {

        this.fetchUpload(this.state["files" + keys[count]], keys[count], (destinations) => {
            console.log(destinations)
            this.state.currentItem.values[keys[count]] = destinations
            if (count < keys.length - 1) {
                this.fileFetchLoop(count++, keys, cb)
            } else {
                cb()
            }
        })

    }


    fetchParams() {
        let params = this.state.currentItem.values
        this.state.headers.forEach(header => {
            if (header.type == 'integer') {
                if (params[header.key])
                    params[header.key] = Number(params[header.key])
            }
        })
        // console.log(params)
        this.setState({ isLoading: true })
        fetch(domain + '/values/add', {
            method: 'POST',
            body: JSON.stringify({ "page": 'advertisements', 'values': params, _id: this.state.currentItem._id }),
            headers: headers

        }).then(response => response.json()).then(data => {
            if (data.status == 200) {
                this.props.history.goBack()
            }

        }).catch(error => this.setState({ error, isLoading: false }));
    }



    fetchUpload(files, field, cb) {
        let destinations = []
        files.forEach((file, i) => {

            var formData = new FormData();

            if (file) {
                formData.append('images', file);
            }

            formData.append('field', field);

            this.setState({ isLoading: true })

            fetch(domain + '/values/uploader', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                },
            }).then(response => {
                return response.json()
            }).then(data => {

                this.setState({ isLoading: false })
                destinations.push(data.info)
                if (i == files.length - 1) {
                    // console.log(destinations)

                    cb(destinations)
                }
            });

        });
    }




    validator() {
        var isValid = true
        var errors = {}
        this.setState({ errors: {} })
        this.state.headers.forEach(header => {
            if (header.settings && header.settings.required) {
                if (this.state.currentItem.values[header.key] == "" || this.state.currentItem.values[header.key] == null) {
                    errors[header.key] = "این فیلد اجباری است"
                    isValid = false
                }
            }
        });
        this.setState({ errors: errors })
        return isValid
    }






    updateInputValue(evt) {
        this.setState({
            inputValue: evt.target.value
        });
    }


    fetchChange = (value, id, u) => {
        this.setState({ isAvailable: value })
    }

    changeValue = (key, value) => {
        this.state.currentItem.values[key] = value
    }


    changeFiles = (files, key) => {
        var newKey = "files" + key
        this.setState({ [newKey]: files })
    }




    render() {

        return (
            <div className="content mediumiransansfont" style={{ textAlign: 'right' }} >
                <Row>
                    <Col md={12} xs={12}>
                        {/* <Card style={{ overflow: 'hidden' }} className="card-user"> */}



                        {/* <Dropzone
                                ref={dropzoneRef}
                                multiple={false}
                                accept='video/mp4'
                                onDrop={this.onDrop.bind(this)}
                                style={{ flex: 1 }}
                            >
                                {this.state.image == noVideo && (<div className="" style={{ flex: 1, alignSelf: 'center' }}>
                                    <img id="myImage" src={this.state.image} style={{ width: '100%' }} alt="..." />
                                </div>
                                )}

                            </Dropzone>
                            {this.state.image != noVideo && this.state.showVideo && (
                                <div className="" style={{ flex: 1, width: '100%', alignSelf: 'center' }}>
                                    <video id="myImage" width={'100'} style={{ width: '100%' }} src={this.state.image} key={this.state.image} controls>
                                        <source id="mySource" src={this.state.image} type="video/mp4"></source>
                                    </video>
                                </div>
                            )} */}







                        {/* </Card> */}

                        <canvas style={{ display: 'none' }} id="cc"></canvas>

                    </Col>
                    <Col md={12} xs={12}>
                        <Card className="card-user" style={{ padding: 20 }}>
                            <CardHeader>
                                <CardTitle>افزودن ملک</CardTitle>
                            </CardHeader>
                            <CardBody>

                                <Row style={{ direction: 'rtl' }}>
                                    {this.state.headers.map((prop, index) => {
                                        if (!prop.fullCol) {
                                            return (

                                                <div key={index} className={'col-4 mt-2'} style={{ paddingLeft: 20 }}>
                                                    {prop.type == 'person' && (
                                                        <PersonCell label={prop.title} headerkey={prop.key} onChange={this.changeValue} />
                                                    )}
                                                    {prop.type == 'text' && (
                                                        <TextCell label={prop.title} headerkey={prop.key} settings={prop.settings} onChange={this.changeValue} />
                                                    )}

                                                    {prop.type == 'integer' && (
                                                        <IntegerCell label={prop.title} headerkey={prop.key} settings={prop.settings} onChange={this.changeValue} />
                                                    )}

                                                    {prop.type == 'status' && (
                                                        <StatusCell label={prop.title} headerkey={prop.key} onChange={this.changeValue} />
                                                    )}

                                                    {prop.type == 'select' && (
                                                        <SelectCell label={prop.title} headerkey={prop.key} options={prop.options} settings={prop.settings} onChange={this.changeValue} />
                                                    )}

                                                    {prop.type == 'multiselect' && (
                                                        <MultiSelectCell label={prop.title} headerkey={prop.key} options={prop.options} settings={prop.settings} onChange={this.changeValue} isSearchable={true}/>
                                                    )}

                                                    {this.state.errors[prop.key] && (
                                                        <small className="mt-1 mr-1" style={{ color: '#ee5050' }}>{this.state.errors[prop.key]}</small>
                                                    )}

                                                </div>
                                            )
                                        }
                                    })}


                                    {this.state.headers.map((prop, index) => {
                                        if (prop.fullCol) {
                                            return (
                                                <div key={index} className={'col-12 mt-2'}>
                                                    <FileCell label={"تصاویر"} changeFiles={this.changeFiles} headerkey={prop.key} />
                                                </div>
                                            )
                                        }
                                    })}

                                </Row>

                                <Row>
                                    <div className="update ml-auto mr-auto">
                                        {!this.state.isLoading && (
                                            <Button className={'mediumiransansfont'} onClick={() => this.fetchAdd()} style={{ backgroundColor: '#007aff' }} round>ارسال</Button>
                                        )}
                                        {this.state.isLoading && (
                                            <div className="mt-2">
                                                <Loader
                                                    type="Oval"
                                                    color="rgba(0,122,255,1)"
                                                    height="40"
                                                    width="40"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Row>

                            </CardBody>


                        </Card>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default AddCategory;
