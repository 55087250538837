import React from "react";
import { domain, headers, colors, settings } from '../../Variables'
import Loader from 'react-loader-spinner'

class Header extends React.Component {

    state = {
        title: '',
        isLoading: false
    }

    componentWillMount() {
        // console.log(this.props.information)
        this.checkType()
    }

    componentWillReceiveProps(newProps) {
        if (this.props.title != newProps.title) {
            this.checkType()
        }
    }


    checkType() {
        // console.log(this.props.title)
        if (this.props.information) {
            setTimeout(
                function () {
                    if (this.props.type == "local") {
                        this.localOption()
                    } else if (this.props.type == "api") {
                        // this.fetch()
                        this.fetchWithData()

                    }

                }.bind(this), 10
            );
        }
    }

    

    localOption() {
        // console.log(this.props.title)

        var item = this.props.information.options.filter(e => e.value == this.props.title)
        // console.log("HERE")
        if (item[0])
            // return item[0].title
            this.setState({ title: item[0].title })
    }


    fetch = () => {
        this.setState({ isLoading: true })
        fetch(domain + '/values/getOne', {
            method: 'POST',
            body: JSON.stringify({ page: this.props.information.filter.page, value: this.props.title, titleField: this.props.information.fields.title, valueField: this.props.information.fields.value }),
            headers: headers
        }).then(response => response.json()).then(data => {
            if (data.status == 200) {
                this.setState({ title: data.info, isLoading: false })
            }

        }).catch(error => { this.setState({ error: error, isLoading: false }) });

    }


    fetchWithData() {
        console.log(this.props.title)
    }


    render() {

        return (
            <div>
                {!this.state.isLoading && (
                    <p className='mediumiransansfont' style={{ margin: 7, letterSpacing: 0, fontSize: 12, color: '#3b3a40', textAlign: 'center' }}>{this.state.title}</p>
                )}
                {this.state.isLoading && (
                    <div style={{ textAlign: 'center' }}>
                        <Loader
                            type="ThreeDots"
                            color="rgba(0,122,255,1)"
                            height="25"
                            width="25"
                        />
                    </div>
                )}

            </div>
        );

    }
}



export default Header;
