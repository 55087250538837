import React from "react";

import PagesTable from './FormApplet'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class Users extends React.Component {

  constructor(prop) {
    super(prop)
    this.state = {
      data: [],
      removeData: null,
      page: null,
      modalIsOpen: false,
      page: {
        title: 'کارکنان',
        description: 'تنظیمات بخش کارکنان',
        fetchUrl: '/employee/getAll',
        addUrl: '/employee/add',
        editUrl: '/employee/add',
        removeUrl: '/employee/remove',
        helperKey: 'values',
        pageType: 'normalTable',
        headers: [
          { information: { label: 'نام و نام خانوادگی', showMain: true }, type: 'textCell', key: 'name', showMain: false },
          { information: { label: 'نام کاربری', showMain: true }, type: 'textCell', key: 'username', showMain: false },
          { information: { label: 'کلمه عبور' }, type: 'textCell', key: 'password', showMain: false },
          { information: { label: 'نوع کاربری', options: [{ value: 'admin', title: 'admin' }, { value: 'editor', title: 'editor' }] }, type: 'selectCell', key: 'role', showMain: false },

          { information: { label: 'شماره تماس', showMain: true }, type: 'textCell', key: 'phone', showMain: true },
          // { information: { label: 'توضیحات' }, type: 'text', key: 'description', showMain: false },
          // { information: { label: 'ایمیل' }, type: 'text', key: 'email', showMain: false },
          // { information: { label: 'شهر' }, type: 'select', key: 'city', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
          // { information: { label: 'تصویر' }, key: 'image', type: 'file', options: { single: true }, showMain: false, fullCol: true },
          // { information: { label: 'وضعیت' }, key: 'status', type: 'select', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: "2" }] }, settings: { required: true }, showMain: true }
          // { information:{label: 'اصلاح'}, type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
          // { information:{label: 'حذف'}, type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }
        ],
      },
    }
  }

  componentWillMount() {

    let role = cookies.get('role')

    if (role == 'editor') {
      this.props.history.goBack()
    } else {

      this.unlisten = this.props.history.listen((location, action) => {
        this.loadPageData()
      });
      this.loadPageData()
    }
  }

  componentWillUnmount() {
    if (this.unlisten)
      this.unlisten();
  }


  loadPageData = () => {
    this.setState({ data: [], removeData: [], isLoadingData: true }, () => {
      // this.setState({ page: pagesData[this.props.match.params.name] }, () => {
      if (this.state.page) {
        // this.fetchPage()
        this.fetchPage()
        this.forceUpdate()
      } else {
        this.props.history.push('/dashboard')
      }
      // })
    })
  }





  fetchPage() {

    // let body = { "headers": this.state.itemsList , title:'test',description:'this is test desc'}

    //   fetch(domain + '/forms/getOne', {
    //   method: 'POST',
    //   body: JSON.stringify({ id: this.props.match.params.name }),
    //   headers: headers
    // })

    //   .then(response => response.json()).then(data => {
    //     if (data.status == 200) {

    //       // console.log(data)
    //       document.title = settings.siteName + ' ' + data.info.title
    // let data = {removeUrl:'employees/remove',addUrl:'employees/add',editUrl:'employees/add',fetchUrl:'employees/getall'}
    // data.headers = this.state.headers
    // this.setState({ page: data })//, () => {
    //       this.fetch()
    //       this.forceUpdate()
    //     })
    //   }

    // }).catch(error => this.setState({ error, isLoading: false }));
  }



  // fetch() {
  //   // console.log(this.props.match.params.name)
  //   let body = { filter: { page: this.props.match.params.name } }
  //   if (this.state.page.settings && this.state.page.settings.fetchFilter) {
  //     body.filter = this.state.page.settings.fetchFilter
  //   }
  //   // console.log(this.state.page)
  //   if (this.state.page && this.state.page.page) {
  //     body.filter.page = this.state.page.page
  //   }

  //   // console.log(body)
  //   let api = this.state.page.fetchUrl ? this.state.page.fetchUrl : '/values/getAllWithData'
  //   fetch(domain + api, {
  //     method: 'POST',
  //     body: JSON.stringify(body),
  //     headers: headers
  //   })

  //     .then(response => response.json()).then(data => {

  //       this.setState({ isLoadingData: false })

  //       if (data.status == 200) {
  //         // console.log(data)
  //         this.setState({
  //           data: data.info,
  //         })
  //       }

  //     }).catch(error => this.setState({ error, isLoading: false }));
  // }


  render() {
    if (this.state.page) {
      // console.log(this.state.page.headers)
      return (
        <div>
          <PagesTable urls={{ remove: this.state.page.removeUrl, add: this.state.page.addFetch }} history={this.props.history} page={this.state.page} modules={this.props.modules} data={this.state.data} loadPageData={this.loadPageData} isLoadingData={this.state.isLoadingData} />
        </div>

      );
    } else {
      return (<div></div>)
    }
  }

}

export default Users;
