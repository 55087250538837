import React from "react";
import Modal from "../Modal.jsx";
import SubmitButton from '../Common/SubmitButton'
import Loader from 'react-loader-spinner'
import { settings } from '../../Variables'

import TextCell from '../Inputs/Text'
import SelectCell from '../Inputs/Select'
import MultiLevelCell from '../Inputs/MultiLevelSelect'

import NumberCell from '../Inputs/Integer'
// import EditorInput from '../Inputs/Editor'
// import FileCell from '../Inputs/File'
import DateCell from '../Inputs/Date'

import SwitchCell from '../Inputs/Switch'
// import VideoCell from '../Inputs/Video'
import { FilterFields, FilterOperators } from '../../Jsons/FilterData'

class ItemModal extends React.Component {
  state = {
    isLoadingRemove: false,
    id: null,
    index: null
  }


  components = {
    textCell: TextCell,
    dateCell: DateCell,
    // imageCell: ImageCell,
    switchCell: SwitchCell,
    // editorCell: EditorCell,
    selectCell: SelectCell,
    selectApiCell: SelectCell,
    multiSelectApiCell: SelectCell,
    multiLevelCell: MultiLevelCell,
    switchCell: SwitchCell,


  };

  showModal = () => {
    this.refs.removeModal.showModal()
  }

  hideModal = () => {
    this.refs.removeModal.hideModal()
    // if(this.props.cancelModal)
    // this.props.cancelModal()
  }


  checkKey(values, key) {
    if (key && values) {
      let value = null
      if (key.includes('.')) {
        let split = key.split('.')
        if (values[split[0]])
          value = values[split[0]][split[1]]

      } else if (key.includes('*')) {

        let split = key.split('*')

        if (values[split[0]]) {

          values[[split[0]]].forEach(element => {
            for (const [key_, value_] of Object.entries(element)) {
              if (key_ == split[1]) {
                value = value_
              }
            }
          });
        }
      } else {
        value = values[key]
      }
      if (value == null)
        value = ''

      return value
    }
  }


  renderBody = () => {

    return (
      this.props.headers.map((headerProp, index) => {
        let values = this.props.item
        // console.log(headerProp.key)

        let value = this.checkKey(values, headerProp.key)
        // console.log(value)
        if (FilterFields[headerProp.type]) {
          const MyComponent = this.components[headerProp.type]
          if (MyComponent)
            return (
              <div className={'col-12 col-md-6 text-right mediumiransansfont mt-2'} style={{ color: '#000' }} key={index}>

                <label className={'mb-2 mr-1 ml-1 '} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#789', }}>{headerProp.information.label}</label>
                <MyComponent type={headerProp.type == 'selectApiCell' ? 'api' : null} onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} updateAfterSelect={true} />

              </div>
            )
        }
      })
    )

  }


  render() {
    // console.log(this.props.item)
    return (
      <Modal cancelModal={this.props.cancelModal} backgroundColor={'transparent'} className="lightiransansfont" ref={'removeModal'} maxWidth={600} style={{}}>

        <div>
          <div className='pt-2 pb-2 mb-3' style={{ borderRadius: 4 }}>
            <p className="text-center mt-0 mediumiransansfont" style={{ color: '#fff', fontSize: 15, fontWeight: 'bold' }}>جست و جوی پیشرفته</p>
            <p className="text-center mt-0 mediumiransansfont" style={{ color: '#fff', fontSize: 10, fontWeight: 'bold', opacity: 0.4 }}>پس از تکمیل اطلاعات دکمه جست و جو را فشار دهید</p>
          </div>
          <div style={{ backgroundColor: '#fff', padding: 20, borderRadius: 15, boxShadow: '0px 0px 20px rgba(0,0,0,0.1)' }}>

            {(this.props.item && Object.keys(this.props.item).length != 0) && (
              <div className="flexcc">
                <button onClick={() => this.props.clearFilter()}>
                  <span className="mediumiransansfont" style={{ color: '#007aff' }}>پاک کردن فیلتر</span>
                </button>
              </div>
            )}

            <div className="row">

              <div className={'col-12 col-md-6 text-right mediumiransansfont mt-2'} style={{ color: '#000' }}>

                <label className={'mb-2 mr-1 ml-1 '} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#789', }}>ID</label>
                <TextCell  onChange={this.props.changeValue} headerkey={"_id"} info={{}} title={""} updateAfterSelect={true} />

              </div>

              {this.renderBody()}

              <div className="col-12 d-flex mt-3">

                <SubmitButton onClick={() => this.hideModal()} title={'انصراف'} color={'#323232'} background={'#e3e4e5'} class={'mt-3 ml-1'} />
                <SubmitButton onClick={this.props.fetchFilter} title={'جست و جو'} color={'#fff'} background={'#007aff'} class={'mt-3 mr-1'} />

              </div>
            </div>
          </div>

          <p className="text-center mt-2" style={{ color: '#fff', fontSize: 10, fontWeight: 'bold', opacity: 0.2 }}>ALL RIGHTS RESERVED BY RHINO <i className="fa fa-copyright"></i></p>

        </div>

      </Modal>
    );
  }
}



export default ItemModal;
