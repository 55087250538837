import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input
} from "reactstrap";

import dashboardRoutes from "../routers.jsx";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent"
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent"
      });
    } else {
      this.setState({
        color: "dark"
      });
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }
  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.refs.sidebarToggle.classList.toggle("toggled");
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark"
      });
    } else {
      this.setState({
        color: "transparent"
      });
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.refs.sidebarToggle.classList.toggle("toggled");
    }
  }
  render() {
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <div style={{ borderStyle: 'none', paddingTop: 0, zIndex: 100000000000000, backgroundColor: '#fffffff7', height: 60, boxShadow: '0px 0px 30px rgba(0,0,0,0.1)', position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1000000000, borderBottomStyle: 'solid', borderBottomColor: '#f9f9f9', borderBottomWidth: 1 }}>
        <Navbar
          color={
            this.props.location.pathname.indexOf("full-screen-maps") !== -1
              ? "dark"
              : this.state.color
          }
          expand="lg"
          className={
            this.props.location.pathname.indexOf("full-screen-maps") !== -1
              ? "navbar-absolute fixed-top"
              : "navbar-absolute fixed-top " +
              (this.state.color === "transparent" ? "navbar-transparent " : "")
          }
          style={{ borderStyle: 'none', paddingTop: 10 }}

        >
          <Container fluid style={{ flex: 1, display: 'flex' }}>


            <div className="navbar-wrapper" style={{ flex: 1 }}>
              <div className="navbar-toggle" >
                <button
                  type="button"
                  ref="sidebarToggle"
                  className="navbar-toggler"
                  onClick={() => this.openSidebar()}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', textAlign: 'left' }}>
                <img src="/images/male.png" style={{ width: 35, height: 35, borderRadius: 40 }}></img>
                <div style={{ flexDirection: 'column', display: 'flex', marginRight: 10, textAlign: 'right' }}>
                  <p className="mediumiransansfont" style={{ fontSize: 12, marginBottom: 0, paddingBottom: 0 }}>نام کاربر</p>
                  <p className="mediumiransansfont" style={{ fontSize: 9, marginTop: 0 }}>خروج</p>
                </div>
              </div>

              {/* <NavbarBrand className={'mediumiransansfont'} href="/" style={{color:'#000',textAlign:'right',flex:1}}>{this.getBrand()}</NavbarBrand> */}
            </div>
            <NavbarToggler onClick={this.toggle}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </NavbarToggler>

            <Collapse
              isOpen={this.state.isOpen}
              navbar
              className="justify-content-end"
            >

              <Nav navbar>
                <NavItem>
                  <Link to="#pablo" className="nav-link btn-magnify">
                    <i className="nc-icon nc-layout-11" />
                    <p>
                      <span className="d-lg-none d-md-block">Stats</span>
                    </p>
                  </Link>
                </NavItem>
                <Dropdown
                  nav
                  isOpen={this.state.dropdownOpen}
                  toggle={e => this.dropdownToggle(e)}
                >
                  <DropdownToggle caret nav>
                    <i className="nc-icon nc-bell-55" />
                    <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a">Action</DropdownItem>
                    <DropdownItem tag="a">Another Action</DropdownItem>
                    <DropdownItem tag="a">Something else here</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <NavItem>
                  <Link to="#pablo" className="nav-link btn-rotate">
                    <i className="nc-icon nc-settings-gear-65" />
                    <p>
                      <span className="d-lg-none d-md-block">Account</span>
                    </p>
                  </Link>
                </NavItem>
              </Nav>
              <form >
                <InputGroup className="no-border" style={{ backgroundColor: '#f7f7f7', overflow: 'hidden', borderRadius: 30 }}>
                  <Input className="mediumiransansfont" placeholder="جست و جو ..." style={{ backgroundColor: '#f7f7f7', direction: 'rtl', fontSize: 11 }} />
                  <InputGroupAddon addonType="append" >
                    <InputGroupText style={{ backgroundColor: '#f7f7f7' }} >
                      <i className="nc-icon nc-zoom-split" style={{ marginLeft: 15 }} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </form>
            </Collapse>

          </Container>
        </Navbar>
      </div>
    );
  }
}

export default Header;
