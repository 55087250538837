import React from "react";
// import drag from '../../assets/Images/drag.png'


class Header extends React.Component {
    render() {
        return (
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <img src={''} style={{ width: 15, height: 15, marginBottom: 0, opacity: 0.6 }} />
                <img src={'/images/male.png'} style={{ width: 30, height: 30, borderRadius: 15, marginRight: 10 }} />
                <div style={{ marginRight: 10, justifyContent: 'center', flex: 1, marginRight: 5 }}>
                    <p className='mediumiransansfont' style={{ margin: 0, textAlign: 'right', flex: 1, fontSize: 12, opacity: 1, color: '#3b3a40', marginTop: 2 }}>{this.props.title}</p>
                </div>
            </div>
        );
    }
}



const translate = {
    transition: 'all 0.5s',

}


export default Header;
