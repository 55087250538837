import React from "react";
// import Modal from "../components/Modal.jsx";
// import { Link, Route } from 'react-router-dom'
import { domain, headers, settings } from '../Variables'
import { modules, pagesData } from '../PagesData'
import Container from '../components/Container'
import ModuleBox from '../components/Boxes/Module'
import { allNames } from '../Variables'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// var distanceInWordsToNow = require('date-fns/distance_in_words_to_now')


class Dashboard extends React.Component {

  state = {
    showModal: false,

    modules: [],

    basicSelectedIndex: null,
    senarioSelectedIndex: 0

  }

  componentWillMount() {
    // this.setState({ modules })

    let role = cookies.get('role')

    if (role == 'editor') {
      this.props.history.goBack()
    } else {
      document.title = settings.siteName + ' داشبورد'
      this.fetch()
    }


  }


  fetch() {

    // let body = { "headers": this.state.itemsList , title:'test',description:'this is test desc'}

    fetch(domain + '/modules/getAll', {
      method: 'POST',
      body: JSON.stringify({}),
      headers: headers
    })

      .then(response => response.json()).then(data => {
        if (data.status == 200) {

          // console.log(data)
          this.setState({ modules: data.info })
        }

      }).catch(error => this.setState({ error, isLoading: false }));
  }



  render() {
    return (



      <div className=" mediumiransansfont" style={{}}>

        <Container modules={this.props.modules} isOpen={true} modalIsOpen={false} location={this.props.location} >

          <div style={{ paddingRight: '6%', paddingLeft: '6%', }}>

            <div className="row" style={{ marginBottom: 20 }}>

              <div className={'col-12 d-flex align-items-center p-0'} style={{ marginBottom: 20 }}>
                <div style={{ width: '100%', backgroundColor: '#fff', padding: 20, paddingRight: 30, marginLeft: 0, display: 'flex', borderRadius: 20, boxShadow: '0px 0px 30px rgba(50,60,70,0.1)' }}>

                  {/* <div style={{ backgroundColor: '#fff', borderRadius: 50, height: 65, width: 65, padding: 10, marginLeft: 20, display: 'flex' }}>
                    <img style={{ flex: 1 }} src="/images/logo.png" />
                  </div> */}
                  <div>
                    <p style={{ fontSize: 25, marginBottom: 0, marginBottom: 0, marginTop: 0, color: '#000', marginRight: 0, fontWeight: 'bold' }}>سامانه {allNames.persianName}</p>
                    <p style={{ fontSize: 12, letterSpacing: 0, marginBottom: 0, marginTop: 0, color: '#000', marginRight: 0 }}>مــاژول هـای سیســتم</p>
                  </div>
                </div>
              </div>
              {/* onClick={() => this.props.history.push('/page/'+prop.page)} */}
              {this.state.modules.map((prop, index) => {
                return (
                  <ModuleBox data={prop} edit={true} />
                )
              })}


            </div>
          </div>
        </Container>

      </div>
    );
  }
}

export default Dashboard;
