import React from "react";
// import TablePage from '../components/TablePage'
// import TablePageHeader from '../components/TablePageHeader'
// import RemoveModal from '../components/Modals/RemoveModal'
// import ItemModal from '../components/Modals/ItemModal'
// import { pagesData } from '../PagesData'
// import { Route, Redirect } from 'react-router-dom'
// import Loader from 'react-loader-spinner'
import { domain, headers, settings } from '../Variables'
// Matelli
// import Container from '../components/Container'
import FormApplet from './FormApplet'
import GuaranteeApplet from "./GuaranteeApplet";
import OffCodesApplet from "./OffCodesApplet";
import TreeApplet from "./TreeApplet";

class Page extends React.Component {

  constructor(prop) {
    super(prop)
    this.state = {
      data: [],
      removeData: null,
      page: null,
      modalIsOpen: false
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.loadPageData()
    });
    // this.loadPageData()

    this.setState({ page: null, data: [], removeData: [], isLoadingData: true }, () => {
      // this.setState({ page: pagesData[this.props.match.params.name] }, () => {
      // if (this.state.page) {
      this.fetchPage()
      // this.forceUpdate()
      // } else {
      //   this.props.history.push('/dashboard')
      // }
      // })
    })

  }

  componentWillUnmount() {
    this.unlisten();
  }


  loadPageData = () => {
    this.setState({ page: null, data: [], removeData: [], isLoadingData: true }, () => {
      this.fetchPage()
    })
  }


  fetchPage() {

    // let body = { "headers": this.state.itemsList , title:'test',description:'this is test desc'}

    fetch(domain + '/modules/getOne', {
      method: 'POST',
      body: JSON.stringify({ id: this.props.match.params.name }),
      headers: headers
    })

      .then(response => response.json()).then(data => {
        if (data.status == 200) {

          // console.log(data)
          document.title = settings.siteName + ' ' + data.info.title

          this.setState({ page: data.info }, () => {
            // this.fetch()
            this.forceUpdate()
          })
        }

      }).catch(error => this.setState({ error, isLoading: false }));
  }


  changeData = (data) => {
    this.setState({ data: data })
  }

  render() {
    if (this.state.page) {
      return (
        <div>
          {this.state.page.appletType == 'Form' && (
            <FormApplet urls={{ remove: this.state.page.removeUrl ? this.state.page.removeUrl : '/values/remove', add: this.state.page.addUrl ? this.state.page.addUrl : '/values/submitform' }} history={this.props.history} page={this.state.page} modules={this.props.modules} data={this.state.data} loadPageData={this.loadPageData} isLoadingData={this.state.isLoadingData} changeData={this.changeData} />
          )}

          {this.state.page.appletType == 'Guarantee' && (
            <GuaranteeApplet urls={{ remove: this.state.page.removeUrl ? this.state.page.removeUrl : '/values/remove', add: this.state.page.addUrl ? this.state.page.addUrl : '/values/submitform' }} history={this.props.history} page={this.state.page} modules={this.props.modules} data={this.state.data} loadPageData={this.loadPageData} isLoadingData={this.state.isLoadingData} changeData={this.changeData} />
          )}

          {this.state.page.appletType == 'offCodes' && (
            <OffCodesApplet urls={{ remove: this.state.page.removeUrl ? this.state.page.removeUrl : '/values/remove', add: this.state.page.addUrl ? this.state.page.addUrl : '/values/submitform' }} history={this.props.history} page={this.state.page} modules={this.props.modules} data={this.state.data} loadPageData={this.loadPageData} isLoadingData={this.state.isLoadingData} changeData={this.changeData} />
          )}

          {this.state.page.appletType == 'Tree' && (
            <TreeApplet urls={{ remove: this.state.page.removeUrl ? this.state.page.removeUrl : '/values/remove', add: this.state.page.addUrl ? this.state.page.addUrl : '/values/submitform' }} history={this.props.history} page={this.state.page} modules={this.props.modules} data={this.state.data} loadPageData={this.loadPageData} isLoadingData={this.state.isLoadingData} changeData={this.changeData} />
          )}

        </div>

      );
    } else {
      return (<div></div>)
    }
  }

}

export default Page;
