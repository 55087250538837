import React from "react";
import Modal from "../Modal.jsx";
import { colors, settings } from '../../Variables'



class FormEditModal extends React.Component {
  state = {
    actions: [
      { title: 'ویرایش فرم', description: 'توضیحات در اینجا آورده میشود', image: '/images/icons/editform.svg' },
      { title: 'ویرایش کاربران', description: 'توضیحات در اینجا آورده میشود', image: '/images/icons/editusers.svg' },
      { title: 'اتوماسیون', description: 'توضیحات در اینجا آورده میشود', image: '/images/icons/automationEdit.svg' }
    ]
  }

  showModal = (id, index) => {
    this.setState({ id, index })
    this.refs.removeModal.showModal()
  }

  hideModal = () => {
    this.refs.removeModal.hideModal()
  }


  render() {
    return (
      <Modal className="iransans" ref={'removeModal'} maxWidth={500} style={{ alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>

        <div className="row">
          <div className={"col-12"}>
            <h4 className="mt-0 text-center mb-3" style={{ fontWeight: 'bold' }}>ویرایش ماژول</h4>
          </div>
          {this.state.actions.map((prop, index) => {
            return (
              <div key={index} className={"col-4"} style={{ padding: 5, }}>
                <div style={{ cursor: 'pointer', flexDirection: 'column', display: 'flex', direction: settings.direction, justifyContent: 'center', alignItems: 'center' }}>
                  <img src={prop.image} style={{ width: 60, height: 60 }} />
                  <p style={{ fontWeight: 'bold', whiteSpace: 'nowrap', textAlign: 'center', fontSize: 15, marginTop: 15 }}>{prop.title}</p>
                  <p style={{ fontWeight: 'normal', textAlign: 'center', fontSize: 10, marginTop: 5, opacity: 0.4 }}>{prop.description}</p>
                </div>
              </div>
            )
          })}


        </div>



      </Modal>
    );
  }
}



export default FormEditModal;
