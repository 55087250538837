import React from "react";
// import {  Draggable } from 'react-smooth-dnd';
// import { applyDrag, generateItems } from '../utils';
// import TextCell from '../components/Inputs/Text'
import { FormBuilderItems, FormBuilderObjects } from '../Jsons/FormBuilderData'
import Loader from 'react-loader-spinner'
import Container from '../components/Container'
import { Collapse } from 'react-collapse'
import Modal from '../components/Modal'
import MultiLevelSelect from '../components/Inputs/MultiLevelSelect'
import MultiSelect from '../components/Inputs/MultiSelect'


class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      groups: [{ _id: '1', name: 'مالی', subgroups: [{ _id: '2', name: 'مدیر مالی', subgroups: [{ _id: '3', name: 'معاونت مالی' }, { _id: '4', name: 'مدیر مالی' }] }, { _id: '5', name: 'مدیر مالی' }, { _id: '6', name: 'مدیر مالی' }] }, { _id: '7', name: 'تایید مدیریت', subgroups: [{ _id: '8', name: 'مدیر مالی' }, { _id: '9', name: 'مدیر مالی' }, { _id: '10', name: 'مدیر مالی' }, { _id: '11', name: 'مدیر مالی' }, { _id: '12', name: 'مدیر مالی' }] }, { _id: '13', name: 'بسته بندی', subgroups: [] },],
      // stages: [{ name: 'تایید مالی', access: [] }],
      users: [1, 1, 1, , 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      selectedIndex: null,
      itemsList: [],
      isLoading: false,
      collapses: {},
      addStage: 0,
      isSearching: false
    }
  }


  componentWillMount() {
    this.setState({ innerWidth: window.innerWidth })
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ innerWidth: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount(){
    this.refs.usersListModal.showModal()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }


  openAddGroupModal() {
    this.refs.addModal.showModal()
    this.setState({ modalIsOpen: true })
  }

  addStage() {
    let stages = this.state.stages
    stages.push({ title: 'عنوان را وارد کنید', access: [] })
    this.setState({ stages })
  }

  changeCollapse(id) {

    let collapses = this.state.collapses

    collapses[id] = (collapses[id] != null) ? !collapses[id] : false
    this.setState({ collapses })
  }




  groupRow(group, padding) {
    // console.log(window.innerWidth)
    return (
      <div style={{ paddingRight: padding, paddingLeft: this.state.innerWidth > 600 ? padding / 1.5 : 0 }}>
        <div style={{ width: '100%', marginTop: 5, backgroundColor: 'rgba(239,247,255,' + (padding == 0 ? 1 : 1) + ')', padding: 0, borderRadius: 10, overflow: 'hidden', marginBottom: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between', padding: '10px 10px 10px 25px' }}>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: 0, marginBottom: 0, borderRadius: 0, }}>
              <img src="/images/male.png" style={{ width: 50, height: 50, borderRadius: 30, marginBottom: 0, marginTop: 0 }} />
              <div className={'text-right mr-2'}>
                <p style={{ color: '#456', margin: 0, fontSize: 14, fontWeight: 'bold' }}>{group.name}</p>
                <p style={{ color: '#abc', margin: 0, fontSize: 11, marginTop: 2 }}>No description</p>
              </div>
            </div>
            <div className={'d-flex'}>

              {group.subgroups && group.subgroups.length > 0 && (
                <div onClick={() => this.changeCollapse(group._id)} style={{ backgroundColor: '#abc', borderRadius: 30, width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <i className={"fa " + (((this.state.collapses[group._id] != null) ? this.state.collapses[group._id] : true) ? 'fa-minus' : 'fa-plus')} style={{ color: '#f2f4f6' }}></i>
                </div>
              )}

            </div>
          </div>
        </div>

        {group.subgroups && group.subgroups.length > 0 && (

          <Collapse isOpened={(this.state.collapses[group._id] != null) ? this.state.collapses[group._id] : true} className="mb-2">
            {group.subgroups.map((subgroup) => {
              return (
                this.groupRow(subgroup, padding + this.state.innerWidth > 600 ? 20 : 15)
              )
            })}
          </Collapse>
        )}

      </div>
    )
  }




  render() {
    return (
      <div>
        <Container  modules={this.props.modules} isOpen={true} modalIsOpen={this.state.modalIsOpen} location={this.props.location} >

          <div style={{ paddingRight: '6%', paddingLeft: '6%', }}>

            <div className={"w-100 mediumiransansfont"} >
              <div className="row">
                <div className="col-12">
                  <div className="box p-0 " style={{ backgroundColor: '#fcfcfc', position: 'relative' }}>
                    <div className="p-2 text-center mb-1" style={{ borderBottom: '#f7f7f7 0px solid' }}>
                      <div>
                        <p className="mt-4 font-weight-bold" style={{ fontSize: 16, color: '#345' }}>GROUPS MANAGER</p>
                      </div>
                      <p className="mb-0 mt-1" style={{ fontSize: 11, color: '#abc', letterSpacing: 0 }}>This name would be shown to other people</p>
                      <p className="mb-3" style={{ fontSize: 11, color: '#abc', letterSpacing: 0 }}>but it can be customized for reasones</p>

                      <div onClick={() => this.openAddGroupModal()} className="d-inline-flex" style={{ position: 'absolute', top: 20, left: 20 }}>
                        <p className={'mb-4'} style={{ color: '#007aff', fontSize: 12, cursor: 'pointer', marginTop: 3, marginRight: 0, backgroundColor: '#007aff20', padding: '7px 20px 7px 20px', borderRadius: 20, fontWeight: 'bold' }}>ADD GROUP</p>
                      </div>

                      <div className="w-100 p-2 pb-3 no-scrollbar" style={{ display: 'flex', flexWrap: 'wrap', flex: 1, }}>


                        {this.state.groups.map((group, index) => {
                          return (
                            <div key={index} className="p-2" style={{ width: '100%' }}>

                              {this.groupRow(group, 0)}



                            </div>




                          )
                        })}


                      </div>

                      {/* <p className="mb-3" style={{ fontSize: 10, color: '#abc', letterSpacing: 0 }}>You can learn more about naming strategies <span style={{ color: '#007aff' }}>hear</span></p> */}

                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </Container>

        <Modal maxWidth={400} cancelModal={() => this.setState({ modalIsOpen: false })} removeFunction={this.fetchRemove} ref={'addModal'} >
          <div className="text-center">
            <p className="mt-1 font-weight-bold" style={{ fontSize: 16, color: '#345' }}>ADD NEW GROUP</p>
            <p className="mb-0 mt-1" style={{ fontSize: 12, color: '#abc', letterSpacing: 0 }}>Groups can be used for many porposes</p>
            {/* <p className="mb-3" style={{ fontSize: 11, color: '#abc', letterSpacing: 0 }}>but it can be customized for reasones</p> */}

            {this.state.addStage == 0 && (
              <div className="d-block text-center p-4 mt-2 mb-2" style={{ backgroundColor: '#fff', borderRadius: 4 }}>
                <div className="mb-3">
                  {/* <img src="/images/male.png" style={{ width: 60, height: 60, borderRadius: 40, marginBottom: 0, marginTop: 0, marginLeft: 5, marginRight: 5 }} /> */}

                  <img src="/images/male.png" style={{ width: 80, height: 80, borderRadius: 40, marginBottom: 0, marginTop: 0, marginLeft: 5, marginRight: 5 }} />
                  {/* <img src="/images/male.png" style={{ width: 60, height: 60, borderRadius: 40, marginBottom: 0, marginTop: 0, marginLeft: 5, marginRight: 5 }} /> */}

                </div>

                {/* <p className="font-weight-bold mb-2" style={{ color: '#007aff', fontSize: 11, }}>Custom Image</p> */}

                <label className="font-weight-bold mb-3" style={{ color: '#345' }}>Group Name</label>
                <input placeholder="Enter Group Name ..." style={{ backgroundColor: '#f7f7f7', color: '#202020', direction: 'ltr', height: 40, borderRadius: 8 }} className='form-control' />

              </div>
            )}



            {this.state.addStage == 1 && (
              <div className="d-block text-center p-4 mt-2 mb-2" style={{ backgroundColor: '#fff', borderRadius: 4 }}>

                <p className="font-weight-bold mb-4" style={{ color: '#345' }}>Group Has Parent Group?</p>

                <div className="mb-3 d-flex" style={{ justifyContent: 'center' }}>
                  {/* <img src="/images/male.png" style={{ width: 60, height: 60, borderRadius: 40, marginBottom: 0, marginTop: 0, marginLeft: 5, marginRight: 5 }} /> */}
                  <div style={{ backgroundColor: '#f2f4f6', borderRadius: 8, padding: '15px 25px 15px 25px', cursor: 'pointer', marginLeft: 10, marginRight: 10 }}>
                    <img src="/images/male.png" style={{ width: 80, height: 80, borderRadius: 40, marginBottom: 0, marginTop: 0 }} />
                    <p className="font-weight-bold mt-2" style={{ color: '#345' }}>YES</p>
                  </div>

                  <div style={{ backgroundColor: '#007aff20', borderRadius: 8, padding: '15px 25px 15px 25px', cursor: 'pointer', marginLeft: 10, marginRight: 10 }}>
                    <img src="/images/male.png" style={{ width: 80, height: 80, borderRadius: 40, marginBottom: 0, marginTop: 0 }} />
                    <p className="font-weight-bold mt-2" style={{ color: '#007aff' }}>NO</p>

                  </div>
                </div>

              </div>
            )}


            {this.state.addStage == 2 && (
              <div className="d-block text-center p-4 mt-2 mb-2" style={{ backgroundColor: '#fff', borderRadius: 4 }}>

                <p className="font-weight-bold mb-4" style={{ color: '#345' }}>Select Group's Parent</p>

                <div className="mb-3 d-flex" style={{ justifyContent: 'center' }}>
                  <MultiLevelSelect />
                </div>

              </div>
            )}


            {this.state.addStage == 3 && (
              <div className="d-block text-center p-4 mt-2 mb-2" style={{ backgroundColor: '#fff', borderRadius: 4 }}>


                <div className=" d-flex mb-4" style={{ justifyContent: 'center' }}>

                  <img src="/images/done1.svg" style={{ width: 100, height: 100, borderRadius: 0, marginBottom: 0, marginTop: 0 }} />

                </div>

                <p className="font-weight-bold mb-2" style={{ color: '#345' }}>Group Has Been Created</p>


              </div>
            )}


            <div className="text-center mb-3">
              <button style={{ backgroundColor: '#007aff', padding: 7, paddingLeft: 20, paddingRight: 20, borderRadius: 30 }}>
                <p style={{ color: '#fff', fontWeight: 'bold' }}>NEXT</p>
              </button>
            </div>
          </div>
        </Modal>


        <Modal maxWidth={400} padding={"0"} cancelModal={() => this.setState({ modalIsOpen: false })} removeFunction={this.fetchRemove} ref={'usersListModal'} >
          <div className="text-center">
            <div style={{ height: 20 }}>

            </div>


            {this.state.isSearching && (
              <div style={{ backgroundColor: '#fff', position: 'sticky', top: 0, padding: '10px 20px 10px 20px', zIndex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                <p className="mt-0 font-weight-bold mb-2" style={{ fontSize: 14, color: '#345' }}>SEARCH USERS</p>

                <div className={'mb-1'} style={{ flex: 3, position: 'relative', width: '100%' }}>
                  <input className="form-control" style={{ padding: '2px 65px 2px 35px', height: 35, backgroundColor: '#f2f4f6', direction: 'ltr', borderRadius: 8, borderWidth: 0 }} />
                  <div style={{ position: 'absolute', top: 7, left: 12 }}>
                    <i className="fa fa-search" style={{ color: '#789', direction: 'ltr', fontSize: 18, fontWeight: 'bold' }}></i>
                  </div>

                  <div onClick={() => this.setState({ isSearching: false })} style={{ position: 'absolute', top: 9, right: 10, cursor: 'pointer' }}>
                    <p style={{ color: '#007aff', direction: 'ltr', fontSize: 12, fontWeight: 'bold' }}>CANCEL</p>
                  </div>

                </div>

                {/* <div onClick={() => this.setState({ isSearching: false })} style={{ flex: 1, alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <i className="fa fa-times" style={{ color: '#789', direction: 'ltr', fontSize: 16, fontWeight: 'bold' }}></i>
                </div> */}
              </div>
            )}

            {!this.state.isSearching && (

              <div style={{ backgroundColor: '#fff', position: 'sticky', top: 0, padding: 5, zIndex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                </div>
                <div style={{ flex: 3 }}>
                  <p className="mt-1 font-weight-bold" style={{ fontSize: 16, color: '#345' }}>GROUP USERS</p>
                  <p onClick={() => this.refs.addUsersModal.showModal()} style={{ color: '#007aff', direction: 'ltr', fontSize: 13, fontWeight: 'bold', cursor: 'pointer' }}><i className="fa fa-plus-circle"></i> ADD USER</p>
                </div>

                <div onClick={() => this.setState({ isSearching: true })} style={{ cursor: 'pointer', flex: 1, alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div style={{ backgroundColor: '#f2f4f6', borderRadius: 40, padding: 0, width: 35, height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <i className="fa fa-search m-0" style={{ color: '#789', direction: 'ltr', fontSize: 20, fontWeight: 'bold' }}></i>
                  </div>
                  {/* <p style={{ color: '#abc', direction: 'ltr', fontSize: 9, fontWeight: 'bold',marginTop:5,lineHeight:1,marginBottom:0 }}>SEARCH</p> */}
                </div>
              </div>
            )}



            {/* <p className="mb-0 mt-0 mb-3" style={{ fontSize: 12, color: '#abc', letterSpacing: 0 }}>List of users in this group</p> */}
            {/* <p className="mb-3" style={{ fontSize: 11, color: '#abc', letterSpacing: 0 }}>but it can be customized for reasones</p> */}
            <div style={{ padding: '0px 20px 30px 20px', marginTop: 10 }}>
              {this.state.users.map((prop, index) => {
                return (
                  <div style={{ width: '100%', marginTop: 8, backgroundColor: 'rgba(239,247,255,1)', padding: 0, borderRadius: 10, overflow: 'hidden', marginBottom: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '7px 10px 7px 15px' }}>

                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 0, marginBottom: 0, borderRadius: 0, }}>
                        <img src="/images/male.png" style={{ width: 40, height: 40, borderRadius: 30, marginBottom: 0, marginTop: 0 }} />
                        <div className={'text-right mr-2'}>
                          <p style={{ color: '#345', margin: 0, fontSize: 14, fontWeight: 'bold' }}>Name</p>
                          <p style={{ color: '#abc', margin: 0, fontSize: 12, marginTop: 0, fontWeight: 'bold' }}>Nikename</p>
                        </div>
                      </div>

                      <div>
                        <div style={{ backgroundColor: '#abc', width: 27, height: 27, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, cursor: 'pointer', }}>
                          <i className="fa fa-trash" style={{ color: '#f2f4f6', fontSize: 16 }}></i>
                        </div>
                      </div>

                    </div>
                  </div>
                )
              })}

            </div>


          </div>
        </Modal>




        <Modal maxWidth={400} padding={"0"} removeFunction={this.fetchRemove} ref={'addUsersModal'} >
          <div className="text-center">
            <div style={{ height: 20 }}>

            </div>


            {this.state.isSearching && (
              <div style={{ backgroundColor: '#fff', position: 'sticky', top: 0, padding: '10px 20px 10px 20px', zIndex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                <p className="mt-0 font-weight-bold mb-2" style={{ fontSize: 14, color: '#345' }}>SEARCH USERS</p>

                <div className={'mb-1'} style={{ flex: 3, position: 'relative', width: '100%' }}>
                  <input className="form-control" style={{ padding: '2px 65px 2px 35px', height: 35, backgroundColor: '#f2f4f6', direction: 'ltr', borderRadius: 8, borderWidth: 0 }} />
                  <div style={{ position: 'absolute', top: 7, left: 12 }}>
                    <i className="fa fa-search" style={{ color: '#789', direction: 'ltr', fontSize: 18, fontWeight: 'bold' }}></i>
                  </div>

                  <div onClick={() => this.setState({ isSearching: false })} style={{ position: 'absolute', top: 9, right: 10, cursor: 'pointer' }}>
                    <p style={{ color: '#007aff', direction: 'ltr', fontSize: 12, fontWeight: 'bold' }}>CANCEL</p>
                  </div>

                </div>


              </div>
            )}

            {!this.state.isSearching && (

              <div style={{ backgroundColor: '#fff', position: 'sticky', top: 0, padding: 5, zIndex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                </div>
                <div style={{ flex: 3 }}>
                  <p className="mt-1 font-weight-bold" style={{ fontSize: 16, color: '#345' }}>ADD USERS</p>
                  <div className="d-inline-flex mt-1 mb-2">
                    <p style={{ color: '#fff', backgroundColor: '#007aff', borderRadius: 20, direction: 'ltr', fontSize: 13, fontWeight: 'bold', cursor: 'pointer',padding:'7px 15px 7px 15px' }}><i className="fa fa-plus-circle"></i> ADD 32 USER</p>
                  </div>
                </div>

                <div onClick={() => this.setState({ isSearching: true })} style={{ cursor: 'pointer', flex: 1, alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div style={{ backgroundColor: '#f2f4f6', borderRadius: 40, padding: 0, width: 35, height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <i className="fa fa-search m-0" style={{ color: '#789', direction: 'ltr', fontSize: 20, fontWeight: 'bold' }}></i>
                  </div>
                </div>
              </div>
            )}


            <div style={{ padding: '0px 20px 30px 20px', marginTop: 10 }}>
              {this.state.users.map((prop, index) => {
                return (
                  <div style={{ width: '100%', cursor:'pointer', marginTop: 8, backgroundColor: 'rgba(239,247,255,1)', padding: 0, borderRadius: 10, overflow: 'hidden', marginBottom: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '7px 10px 7px 15px' }}>

                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 0, marginBottom: 0, borderRadius: 0, }}>
                        
                        <div style={{ backgroundColor: '#def', width: 24, height: 24,marginLeft:10, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, cursor: 'pointer', }}>
                        {/* <div style={{ backgroundColor: '#007aff', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, cursor: 'pointer', }}>
                        </div> */}
                        </div>


                        <img src="/images/male.png" style={{ width: 40, height: 40, borderRadius: 30, marginBottom: 0, marginTop: 0 }} />
                        <div className={'text-right mr-2'}>
                          <p style={{ color: '#345', margin: 0, fontSize: 14, fontWeight: 'bold' }}>Name</p>
                          <p style={{ color: '#abc', margin: 0, fontSize: 12, marginTop: 0, fontWeight: 'bold' }}>Nikename</p>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                )
              })}

            </div>


          </div>
        </Modal>




      </div >

    );
  }



}



export default Dashboard

