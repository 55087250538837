import MainPage from "./MainPage.jsx";
// import auth from 'Auth'
// import UserPage from "views/UserPage/UserPage.jsx";

// var indexRoutes = [{ path: "/", name: "Main", component: MainPage }];
var indexRoutes = []
// if(auth.isAuthenticated){
var indexRoutes = [{ path: "/", name: "Main", component: MainPage }];

// { path: "/addbanner", name: "addbanner", component: UserPage },
export default indexRoutes;
