import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import auth from '../Auth'
import { modules } from '../PagesData'
import { connect } from 'react-redux'
import { allNames, content } from '../Variables'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      showOverlay: false
    }
  }
  activeRoute(routeName) {
    // console.log(this.props.location.pathname)
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }


  // componentDidMount() {
  //   this.updateSize()
  //   window.addEventListener('resize', this.updateSize);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.updateSize);
  // }

  // updateSize = () => {
  //   if (window.innerWidth < 720) {
  //     this.setState({ size: 'mobile' })
  //   } else {
  //     this.setState({ isOpen: false })
  //     this.setState({ size: 'desktop' })

  //   }
  // }

  // componentDidMount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.sidebar, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //   }
  // }
  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  // }

  checkPermission(applet) {

    let role = cookies.get('role')

    if (allNames.englishName == 'gamas20.ir') {
      if (role != 'editor' || applet == 'questions') {
        return true
      }
    }



    if (role == 'editor' && applet == 'settings') {
      return false
    } else {
      return true
    }

  }

  render() {
    if (this.props.isOpen) {
      return (

        <div className=" mediumiransansfont" data-color={"white"} style={{ overflow: 'scroll', borderStyle: 'none', direction: 'rtl', width: 240, position: 'fixed', right: '2%', paddingTop: '3%', height: '100%', top: 0, paddingBottom: '2%' }} data-active-color={'blue'}>

          <div style={{ backgroundColor: '#fff', borderRadius: 15, fontSize: 12, marginBottom: 10, paddingBottom: 15 }}>
            <div style={{ width: '%100', zIndex: 100, display: 'flex', position: 'relative', alignItems: 'center', paddingLeft: 0, paddingTop: 30, margin: 0, flexDirection: 'column', justifyContent: 'center', marginBottom: 0 }} >
              <a href={allNames.mainDomain} className="simple-text logo-mini">
                <div className="logo-img text-center" style={{ padding: 0, borderStyle: 'solid', borderColor: '#eee', borderWidth: 0, borderRadius: 0 }}>
                  <img src={"/images/" + (allNames.logo ? allNames.logo : 'logo.png')} style={{ borderRadius: 0, width: '60%', fontWeight: 'bold' }} alt="pezhman.tech" />
                </div>
              </a>
              <a href={allNames.mainDomain} className="simple-text logo-normal mediumiransansfont mt-2" style={{ textDecoration: 'none' }} >
                <p className="boldiransansfont" style={{ color: '#000', marginLeft: 0, textAlign: 'center', marginTop: 5, fontSize: 18, fontWeight: 'bold' }}>{allNames.persianName}</p>
                <p style={{ color: '#000', marginLeft: 0, textAlign: 'center', marginTop: 7, fontSize: 9, opacity: 0.3, letterSpacing: 3 }}>{allNames.englishName}</p>
              </a>
              {/* <hr style={{ marginBottom: 0, marginTop: 15, width: '70%' }} /> */}

            </div>
          </div>


          {/* <div style={{ backgroundColor: '#fff', borderRadius: 15, fontSize: 12, marginBottom: 10 }}>
          <div style={{ width: '%100', zIndex: 100, display: 'flex', position: 'relative', alignItems: 'center', paddingLeft: 0, padding: 15,paddingRight:30, margin: 0,  justifyContent: 'right', marginBottom: 0 }} >
            <a href={allNames.mainDomain} className="simple-text logo-mini">
              <div className="logo-img" style={{ padding: 0, borderStyle: 'solid', borderColor: '#eee', borderWidth: 0, borderRadius: 0 }}>
                <img src={'https://cdn11.bigcommerce.com/s-sq9zkarfah/images/stencil/1280x1280/products/113126/201227/Classic-Adidas-Logo-Sticker__99764.1511162634.jpg?c=2?imbypass=on'} style={{ borderRadius: 0, width: 40, fontWeight: 'bold' }} alt="pezhman.tech" />
              </div>
            </a>
            <a href={allNames.mainDomain} className="simple-text logo-normal mediumiransansfont text-right mr-2" style={{ textDecoration: 'none' }} >
              <p className="boldiransansfont" style={{ color: '#000', marginLeft: 0, marginTop: 5, fontSize: 14, fontWeight: 'bold' }}>Company name</p>
              <p  className="boldiransansfont"  style={{ color: '#007aff', marginLeft: 0,  marginTop: 0, fontSize: 9, fontWeight:'bold' }}>FOLLOW</p>
            </a>
          </div>
        </div> */}

          <div style={{ backgroundColor: '#fff', borderRadius: 15, fontSize: 12, paddingTop: 15, paddingBottom: 15 }}>

            <div className="sidebar-wrapper" ref="sidebar" style={{ paddingLeft: 0, marginTop: 0, direction: 'rtl' }} >
              <Nav style={{ display: 'flex', flexDirection: 'column' }}>


                <div style={{ transition: 'all 0.5s', backgroundColor: this.activeRoute("dashboard") ? 'rgba(0,122,255,0.1)' : 'transparent', marginLeft: 20, borderRadius: 30, paddingRight: 0, padding: 0 }}>
                  <a onClick={() => this.props.history.push('/dashboard')} className="nav-link" style={{ cursor: 'pointer', paddingLeft: 0, marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 7, paddingRight: 10 }}>
                    <img src={'/images/mainIcons/tasks.png'} style={{ marginLeft: 0, marginLeft: 5, width: 22, height: 22, filter: this.activeRoute("dashboard") ? "grayscale(0%)" : 'grayscale(90%)', opacity: this.activeRoute("dashboard") ? 1 : 0.5 }} />
                    <p style={{ color: '#000', opacity: 0.8, fontSize: 12 }}>داشبورد</p>
                  </a>
                </div>

                {this.props.modules.map((prop, key) => {
                  if (prop.redirect) return null;
                  if (prop.extra) { return null };
                  if (this.checkPermission(prop.applet)) {
                    return (



                      <div key={key} style={{ transition: 'all 0.5s', backgroundColor: this.activeRoute("page/" + prop.page) ? 'rgba(0,122,255,0.1)' : 'transparent', marginLeft: 20, borderRadius: 30, marginTop: 5, padding: 3 }}>
                        <li  >

                          <NavLink
                            to={'/page/' + prop._id}
                            className="nav-link"
                            activeClassName="active"
                            style={{ padding: 5, display: 'flex', alignItems: 'center' }}
                          >

                            {/* <img src={prop.image} style={{ marginLeft: 6, marginRight: 0, width: 22, height: 22, filter: this.activeRoute("page/" + prop.page) ? "grayscale(0%)" : 'grayscale(90%)', opacity: this.activeRoute("page/" + prop.page) ? 1 : 0.5 }} /> */}

                            <img src={'/images/settings.png'} style={{ marginLeft: 6, marginRight: 0, width: 22, height: 22, filter: this.activeRoute("page/" + prop.page) ? "grayscale(0%)" : 'grayscale(90%)', opacity: this.activeRoute("page/" + prop.page) ? 1 : 0.5 }} />
                            <p style={{ fontWeight: 'normal', color: '#000', opacity: 1 }}>{prop.title}</p>
                          </NavLink>
                        </li>
                      </div>
                    );
                  }
                })}


                <a onClick={() => auth.logout(() => this.props.history.push('/login'))} className="nav-link" style={{ cursor: 'pointer', paddingLeft: 20, marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 0, paddingRight: 10 }}>
                  <i className={'nc-icon nc-button-power'} style={{ color: '#000', opacity: 0.2, fontSize: 20, marginLeft: 5 }} />
                  <p style={{ color: '#000', opacity: 0.8, fontSize: 12 }}>خروج</p>
                </a>
              </Nav>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-100">
          {this.state.showOverlay && (
            <div style={{ overflow: 'auto', display: 'flex', padding: "0%", position: 'fixed', width: '100%', height: '100vh', backgroundColor: '#00000060', top: 0, left: 0, backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(10px)' }}>

              <div className={'row w-100 p-0 m-0'}>

                <div className={'col-12 text-left'}>
                  <p className="mb-2 mt-2" style={{ color: '#fff', fontSize: 20, fontWeight: 'lighter' }}>This Company <span style={{ fontWeight: 'bold' }}>Modules</span></p>
                  <div style={{ backgroundColor: '#fffffff7', borderRadius: 15, width: '100%', padding: 20 }}>

                    <div className="row">

                      {this.props.modules.map((prop, key) => {
                        return (
                          <div className="col-4 col-lg-2 mb-4">
                            <NavLink
                              to={'/page/' + prop._id}
                              className="nav-link"
                              activeClassName="active"
                              style={{ padding: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                              <img src={content + 'images/' + prop.image} style={{ marginLeft: 0, marginRight: 0, width: 50, height: 50, marginBottom: 5, filter: this.activeRoute("page/" + prop._id) ? "grayscale(10%)" : 'grayscale(0%)', opacity: this.activeRoute("page/" + prop._id) ? 1 : 0.9, borderRadius: 40 }} />
                              <p style={{ fontWeight: 'normal', color: '#000', opacity: 1 }}>{prop.title}</p>
                            </NavLink>

                          </div>
                        )
                      })}
                    </div>

                  </div>
                </div>

              </div>

            </div>
          )}

          <div onClick={() => this.setState({ showOverlay: !this.state.showOverlay })} style={{ position: 'fixed', bottom: 30, right: 30, textAlign: 'center' }}>
            <div style={{ backgroundColor: '#fffffff7', overflow: 'hidden', borderRadius: 40, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 0px 15px rgba(0,0,0,0.05)', cursor: 'pointer' }}>
              <img src={"/images/" + (allNames.logo ? allNames.logo : 'logo.png')} style={{ width: null, height: null, flex: 1 }} />
            </div>
            {/* <p style={{ fontSize: 11, marginTop: 5, fontWeight: 'bold', color: '#323232', opacity: 0.8 }}>Rhino</p> */}
            <p style={{ fontSize: 10, marginTop: 5, fontWeight: 'bold', color: '#000', opacity: 0.9 }}>MENU</p>
          </div>
        </div>
      )
    }
  }
}


const mapStateToProps = (state) => {
  return {
    senario: state.senario
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSenario: (name, id) => dispatch({ type: 'SET_SENARIO', name, id })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))

