import React from "react";
import { domain, headers } from '../../Variables'
import Loader from 'react-loader-spinner'


class MultiSelect extends React.Component {

    state = {
        isOpen: false,
        data: [],
        title: '',
        tempData: [],
        options: [],
        isLoading: false,
        choosen: [],
        lastNewId: 0

    }

    toggleOpen = () => {

        if (!this.state.isOpen) {
            document.addEventListener('mousedown', this.closeBox);
        } else {
            document.removeEventListener('mousedown', this.closeBox);
        }
        // this.refs.icon.classList.toggle("rotate-180")

        this.setState({ isOpen: !this.state.isOpen, data: this.props.data })

    }

    openBox = () => {

        if (!this.state.isOpen) {
            document.addEventListener('mousedown', this.closeBox);
        }
        // this.refs.icon.classList.toggle("rotate-180")

        this.setState({ isOpen: true, data: this.props.data })

    }

    closeBox = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpen) {
            this.setState({ isOpen: false, data: this.props.data })
            document.removeEventListener('mousedown', this.closeBox);
            // this.refs.icon.classList.toggle("rotate-180")

        }
    }


    changeItem = (prop, selected, dontChangeValue) => {

        var choosen = this.state.choosen
        this.setState({ title: '' }, () => {
            if (this.refs.input)
                this.refs.input.focus()
        })


        if (selected) {
            for (let i = 0; i < choosen.length; i++) {

                if (choosen[i].value == prop.value) {
                    choosen.splice(i, 1)
                }
            }
        } else {
            choosen.push(prop)
        }

        this.setState({ choosen }, () => {
            if (!dontChangeValue) this.changeValue()
        })


        setTimeout(
            function () {
                this.toggleOpen()
            }
                .bind(this),
            1
        );


        if (this.state.tempData.length > 0)
            this.setState({ options: this.state.tempData })

        // this.changeFilter(prop.value)

    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }


    componentDidMount() {
        this.mounted = true
        this.init()

    }

    componentDidUpdate(prevProps) {
        if (prevProps.title != this.props.title && this.mounted) {
            // this.init()
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }


    init() {
        // console.log(this.props.title)
        if (this.props.info) {
            if (this.props.type == "local") {

                let options = []
                let choosen = []

                if (this.props.info.items && this.props.info.items.length > 0) {
                    options = this.props.info.items
                }

                if (this.props.title && Array.isArray(this.props.title)) {
                    this.props.title.forEach(element => {
                        choosen.push({ title: element, value: element })
                    });
                }
                console.log(this.props.title)
                this.setState({ options, choosen })
            } else if (this.props.type == "api") {
                // console.log(this.props.title)
                this.fetch()
            }
        }
    }


    fetch() {
        this.setState({ isLoading: true })
        let address = domain + this.props.info.address
        let filter = typeof this.props.info.filter == "string" ? JSON.parse(this.props.info.filter) : this.props.info.filter
        console.log(address)


        fetch(address, {
            method: 'POST',
            body: JSON.stringify(filter),
            headers: headers
        }).then(response => response.json()).then(data => {
            // console.log(data)
            if (data.status == 200 && this.mounted) {
                var newData = []
                data.info.forEach(element => {

                    var newTitle = this.spliter(element, this.props.info.titleField)
                    var newValue = this.spliter(element, '_id')

                    newData.push({ value: newValue, title: newTitle })
                    // var newTitle = this.spliter(element, this.props.info.fields.title)
                    // var newValue = this.spliter(element, this.props.info.fields.value)

                    // newData.push({ value: newValue, title: newTitle })
                });

                // console.log(this.props.title)


                if (Array.isArray(this.props.title)) {

                    let choosen = []
                    this.props.title.forEach(oneTitle => {
                        newData.forEach(oneNewData => {
                            if (oneNewData.value == (typeof oneTitle == 'object' ? oneTitle._id : oneTitle)) {
                                choosen.push({ title: oneNewData.title, value: (typeof oneTitle == 'object' ? oneTitle._id : oneTitle) })
                            }
                        });

                    })

                    this.setState({ choosen })
                    // console.log(choosen)


                }

                this.setState({
                    options: newData,
                    isLoading: false
                })
            }

        }).catch(error => this.setState({ error, isLoading: false }));
    }


    spliter(source, string) {
        var stringArray = string.split('.')
        var finalString = source
        stringArray.forEach(element => {
            finalString = finalString[element]
        });
        return finalString
    }


    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }


    search = async (value) => {

        // console.log(value)
        this.setState({ title: value })
        if (!this.state.isOpen) {
            this.toggleOpen()
        }

        if (value == "") {
            await this.setState({ options: this.state.tempData })
            await this.setState({ tempData: [] })
        } else {
            if (this.state.tempData.length == 0) {
                await this.setState({ tempData: this.state.options })
            }
            var values = []
            // var values = this.props.data.filter((e) => e.title === value)
            // console.log(this.state.tempData)
            this.state.tempData.forEach(element => {
                if (String(element.title).toLowerCase().includes(String(value).toLowerCase())) {
                    values.push(element)
                }
            })
            // console.log(values)

            this.setState({ options: values })

            // }
        }
    }


    removeItem(index) {
        let choosen = [...this.state.choosen]
        choosen.splice(index, 1)
        this.setState({ choosen }, () => {
            this.changeValue()
        })
    }


    addTag() {
        let tempData = [...this.state.tempData]
        let choosen = [...this.state.choosen]

        let item = { title: this.state.title, value: this.state.title }
        tempData.push(item)
        // choosen.push(item)
        this.setState({ tempData }, () => {
            this.changeItem(item, false)
        })
        this.refs.input.focus()
    }

    changeValue() {
        let choosen = []
        this.state.choosen.forEach(element => {
            // choosen.push(element.value)
            choosen.push((typeof element.value == 'object' ? element.value._id : element.value))

        });
        // console.log(choosen)

        this.props.onChange(this.props.headerkey, choosen)
    }


    render() {

        return (
            <div className='mt-0' style={{ width: '100%', position: 'relative' }} >

                {this.props.label && (<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stratch', height: '100%', padding: 0, }}>
                    <label className={'mb-2 mr-1 ml-1 boldiransansfont ' + (this.conditionalSettings('required', true) ? 'required' : '')} style={{ marginBottom: 3, fontSize: 13, marginTop: 5, color: '#456', fontWeight: 'bold' }}>{this.props.label}</label>
                    {/* <i ref={'icon'} className="nc-icon nc-minimal-down all-transition " style={{ color: '#000', fontSize: 12, fontWeight: 'bold', marginLeft: 0, opacity: 0.4 }} /> */}
                </div>
                )}

                <div ref={ref => this.setWrapperRef(ref)} >
                    <div style={{ flexWrap: 'wrap', borderRadius: 4, border: '1px #DDDDDD solid', cursor: 'pointer', padding: 5, minHeight: 40, display: 'flex', backgroundColor: '#f7f7f7', color: '#3b3a40', alignItems: 'center' }}>
                        {this.state.choosen.map((prop, index) => {
                            return (
                                <div key={index} className="" style={{ display: 'inline-flex', alignItems: 'center', paddingTop: 2, paddingBottom: 2 }}>
                                    <div key={index} style={{ padding: 5, backgroundColor: '#78b2d020', borderRadius: 4, marginRight: 2, marginLeft: 2, display: 'flex', flexWrap: 'nowrap', paddingLeft: 7, paddingRight: 7 }}>
                                        <p style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: 2, marginLeft: 2, fontSize: 12, marginTop: 0, color: '#202020' }}>{prop.title}</p>
                                        <i className="fa fa-times" onClick={() => this.removeItem(index)} style={{ padding: 2, paddingLeft: 0, color: '#78b2d0' }}></i>
                                    </div>
                                    {/* {index != this.state.choosen.length - 1 && (
                                        <i className="fa fa-arrow-right" style={{ color: '#78b2d0' }}></i>

                                    )} */}
                                </div>
                            )
                        })}
                        <div className={"position-relative mt-0 ml-1 "}>
                            {!this.state.isFinal && (<input ref={'input'} value={this.state.title} onFocus={() => { this.toggleOpen() }} onInput={event => this.search(event.target.value)} onChange={e => { this.props.onChange(this.props.headerkey, e.target.value) }} className='nofocus mediumiransansfont' style={{ backgroundColor: 'transparent', minWidth: 100, width: this.state.title.length * 9, border: 'none' }} />)}
                            {this.state.isLoading && (
                                <div style={{ position: 'absolute', top: 1, left: 5 }}>
                                    <Loader
                                        type="Oval"
                                        color="rgba(0,122,255,1)"
                                        height="20"
                                        width="20"
                                    />
                                </div>
                            )}
                        </div>
                        {this.state.isFinal && (<i className="fa fa-check-circle ml-1" style={{ color: '#78b2d0', fontSize: 20 }}></i>)}

                    </div>

                    {this.state.isOpen && (
                        <div style={{ display: 'flex', position: 'absolute', width: '100%', zIndex: 40 }}>
                            <div className={'search-drop-down'} style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#fff', borderColor: '#eee', borderRadius: 4, maxHeight: 250, overflow: 'auto', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                                {/* {this.props.isSearchable && (<input onChange={event => this.search(event.target.value)} placeholder={"جست و جو ..."} style={{ fontSize: 13, textAlign: 'center', direction: 'rtl', alignSelf: 'stratch', padding: 5, margin: 5, height: 35, backgroundColor: '#f7f7f7', borderRadius: 4, borderWidth: 1, borderStyle: 'solid', borderColor: '#eee', marginTop: 5 }} className={'mediumiransansfont'} />)} */}

                                {this.state.options.map((prop, index) => {
                                    let selected = false
                                    for (var i = 0; i < this.state.choosen.length; i++) {

                                        if (prop.value == this.state.choosen[i].value)
                                            selected = true
                                    }
                                    // console.log(prop)

                                    // console.log(selected)
                                    return (
                                        <div key={index} onClick={() => this.changeItem(prop, selected)} style={{ cursor: 'pointer', padding: 5, paddingTop: 10, paddingBottom: 10, borderBottomColor: '#eee', borderBottomStyle: 'solid', borderBottomWidth: 1, backgroundColor: selected ? '#f7f7f7' : '#fff' }}>
                                            <p style={{ textAlign: 'center', margin: 0, color: '#000', opacity: 0.5, fontSize: 13 }}>{prop.title}</p>
                                        </div>
                                    )
                                })}

                                {this.state.options.length == 0 && (
                                    <div style={{ cursor: 'pointer', padding: 5, paddingTop: 10, paddingBottom: 10, borderBottomColor: '#eee', borderBottomStyle: 'solid', borderBottomWidth: 1 }}>
                                        <p style={{ textAlign: 'center', margin: 0, color: '#000', opacity: 0.5, fontSize: 13 }}>Found Nothing</p>
                                        {this.props.info.tag && this.state.title != '' && (<p onClick={() => this.addTag()} style={{ textAlign: 'center', margin: 0, color: '#007aff', fontSize: 13, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}>+ ADD THIS</p>)}

                                    </div>
                                )}

                            </div>
                        </div>
                    )}

                </div>



            </div>
        );
    }
}


export default MultiSelect;
