import React from "react";
import TablePage from '../components/TablePage'
import TablePageHeader from '../components/TablePageHeader'
import RemoveModal from '../components/Modals/RemoveModal'
import ItemModal from '../components/Modals/ItemModal'
import FilterModal from '../components/Modals/FilterModal'

// import { pagesData } from '../PagesData'
// import { Route, Redirect } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { domain, headers, colors, settings } from '../Variables'
// import Sidebar from "../components/SidebarR.jsx";
// import dashboardRoutes from "../routers.jsx";
// import Footer from "../components/Footer.jsx";
import Container from '../components/Container'
import Modal from '../components/Modal'
// import ButtonCell from '../components/Table/Button'
import { FilterFields, FilterOperators } from '../Jsons/FilterData'
// import { objectOf } from "prop-types";
// import objectAssign from "object-assign";
// import Cookies from "universal-cookie";
import HandleAdd from '../handle.add'
import { setObject } from "../useful";
import Cookies from 'universal-cookie';
const cookies = new Cookies();


class FormApplet extends React.Component {

  constructor(prop) {
    super(prop)
    this.state = {
      data: [],
      removeData: null,
      page: null,
      modalIsOpen: false,
      actions: [{ title: 'ویرایش', action: 'edit', color: '#78b2d0' }, { title: 'حذف', action: 'remove', color: '#dd8aa4' }],
      actionIndex: null,
      filterItem: {},
      filterItemMock: {},
      currentPage: 0,
      limit: 10
    }
  }


  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      // this.loadPageData()
      this.setState({ lockSend: false, lockedItems: {}, filterItem: {}, filterItemMock: {} })
    });

    // if (this.props.page.applet == 'users') {
    //   let actions = this.state.actions
    //   actions.push({ title: 'مسدود سازی کاربر', action: 'block', color: '#dd8aa4' })
    //   this.setState({ actions })
    // }
    // this.loadPageData()
  }


  componentWillUnmount() {
    this.unlisten();
  }



  componentDidMount() {
    this.fetch()
  }


  fetch(getCount) {
    // console.log("&!&!&!&!")

    this.setState({ isLoadingData: true, data: [] })

    if (getCount) {
      this.setState({ currentPage: 0 })
    }
    let body = { filter: {} }



    if (this.props.page.settings && this.props.page.settings.fetchFilter) {
      body.filter = this.props.page.settings.fetchFilter
    }
    if (this.props.page && this.props.page.applet) {
      body.filter.applet = this.props.page.applet
    }


    for (const [key, value] of Object.entries(this.state.filterItem)) {
      body.filter[key] = value
    }

    // console.log(body.filter)


    body.limit = this.state.limit
    body.skip = this.state.currentPage
    if (this.state.totalCount == null || getCount) {
      body.getCount = true
    }

    if (this.props.page.applet == 'questions' && cookies.get('role') == 'editor') {
      // console.log("QU")
      body.filter.creator = cookies.get('id')
    }

    let api = this.props.page.fetchUrl ? this.props.page.fetchUrl : '/values/getAllWithData'
    // console.log(api)
    fetch(domain + api, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers
    })

      .then(response => response.json()).then(data => {

        console.log(data)
        this.setState({ isLoadingData: false })

        if (data.status == 200) {

          // console.log(data.count)
          // console.log("------")
          this.setState({ data: data.info })
          if (data.count != null) {
            this.setState({ totalCount: data.count })
          }
        }

      }).catch(error => this.setState({ error, isLoading: false }));
  }




  fetchAdd = () => {
    this.setState({ errors: {} })

    let handleAdd = new HandleAdd()

    this.setState({ isLoadingSend: true })
    console.log(this.state.currentItem)
    handleAdd.fetchAdd(this.props.page, this.state.currentItem, (data, errors) => {

      this.setState({ isLoadingSend: false })

      if (errors) {
        if (typeof errors == "object") {
          this.setState({ errors: errors })
        }
        this.setState({ isLoading: false })
        return
      }

      if (data.status == 200) {

        if (this.state.lockSend && this.state.selectedIndex == -1) {
          // console.log("HERE")
          this.setState({ data: [] }, () => {
            this.fetch()
          })
          this.addAction()


        } else {
          this.setState({ modalIsOpen: false })
          this.refs.itemModal.hideModal()
        }

        let newData = this.state.data

        if (this.state.selectedIndex == -1) {
          this.setState({ data: [] }, () => {
            this.fetch()
          })
          // this.props.loadPageData()
        } else {
          delete this.state.currentItem['**files']
          newData[this.state.selectedIndex] = this.state.currentItem
          this.setState({ data: newData })

        }
      }


    })
  }






  fetchRemove = (id, index, modal) => {

    fetch(domain + this.props.urls.remove, {
      method: 'POST',
      body: JSON.stringify({ "id": id }),
      headers: headers
    }).then(response => response.json()).then(data => {
      if (data.status == 200) {

        this.state.data.splice(index, 1)
        modal.setState({ isLoadingRemove: false })
        this.refs.removeModal.hideModal()
      }

    }).catch(error => this.setState({ error, isLoading: false }));
  }





  fetchBlock = (id, index, modal) => {

    fetch(domain + '/users/blockUser', {
      method: 'POST',
      body: JSON.stringify({ "id": id }),
      headers: headers
    }).then(response => response.json()).then(data => {
      if (data.status == 200) {

        this.state.data.splice(index, 1)
        modal.setState({ isLoadingRemove: false })
        this.refs.removeModal.hideModal()
      }

    }).catch(error => this.setState({ error, isLoading: false }));
  }



  editAction = (item, index) => {

    let newItem = JSON.stringify(item)
    newItem = JSON.parse(newItem)
    // console.log("newItem")
    // console.log(newItem)
    this.setState({ currentItem: {} }, () => {
      this.setState({ currentItem: newItem })
      this.setState({ selectedIndex: index }, () => {
        // console.log("EDIT")
        // console.log(this.state.currentItem)
        this.refs.itemModal.showModal()
        this.setState({ modalIsOpen: true })
      })
    })
  }

  setModalIsOpen = (action) => {
    this.setState({ modalIsOpen: action })
  }

  removeAction = (info, index) => {
    this.refs.removeModal.showModal(this.state.data[index]._id, index)
    this.setModalIsOpen(true)
  }

  addAction = () => {

    let item = {}
    // console.log("ADD")
    item = this.makeNewItem()
    // console.log(item)
    this.setState({ currentItem: {} }, () => {
      this.setState({ currentItem: item })
      this.setState({ selectedIndex: -1 }, () => {
        this.refs.itemModal.showModal()
        this.setState({ modalIsOpen: true })
      })
    })

  }


  makeNewItem = () => {
    let helper = null
    let item = {}
    let temp = item

    // console.log(this.props.page.helperKey)

    if (this.props.page.helperKey && this.props.page.helperKey != '') {
      helper = this.props.page.helperKey
      //console.log(helper)
      item[helper] = {}
      temp = item[helper]
    }
    // console.log("TEMP")
    // console.log(temp)

    this.props.page.headers.forEach(header => {

      if (this.state.lockedItems && this.state.lockedItems[header.key]) {
        // console.log("HEHEE")
        // console.log(this.helperCheck(temp, header.key, helper))
        if (helper) {
          temp[header.key] = this.state.currentItem[helper][header.key]
        } else {
          temp[header.key] = this.state.currentItem[header.key]//this.helperCheck(temp, header.key, helper)
        }
      } else {
        if (header.type != 'button') {

          if (header.information.defaultValue) {
            temp[header.key] = header.information.defaultValue
          } else {
            temp[header.key] = ''
          }
        }
      }

    });
    // console.log(item)

    return item

  }

  helperCheck(ref, key, helper) {
    if (helper) {
      return ref[helper][key]
    } else {
      return ref[key]
    }
  }


  changeValue = (key, value) => {

    let helper
    if (this.props.page.helperKey && this.props.page.helperKey != '') {
      helper = this.props.page.helperKey
    }

    // let currentItem = this.state.currentItem

    let newKey = key

    if (helper) {
      newKey = helper + '.' + key
    }



    // console.log(value)
    let currentItem = { ...this.state.currentItem }
    // console.log("HEREE")

    if (key == '**files') {
      currentItem = setObject(currentItem, '**files', value, true)
    } else {
      currentItem = setObject(currentItem, newKey, value)
    }

    this.setState({ currentItem })
    console.log(currentItem)

  }

  changeFiles = (files, key, type) => {

    if (!this.state.currentItem["**files"]) {
      this.state.currentItem["**files"] = {}
    }

    this.state.currentItem["**files"][key] = files
    // console.log(this.state.currentItem)
  }


  cancelModal = () => {
    this.setState({ modalIsOpen: false })
  }


  openActionsModal = (index) => {
    // console.log("HERE")
    this.setModalIsOpen(true)
    this.setState({ actionIndex: index }, () => {
      this.refs.actionsModal.showModal()

    })
  }


  butAction = (index, action) => {

    this.refs.actionsModal.hideModal()
    switch (action) {
      case 'remove':
        this.removeAction(this.state.data[index], index)
        break
      case 'edit':
        this.editAction(this.state.data[index], index)

      case 'block':
        this.fetchBlock(this.state.data[index], index)

        break
    }
  }



  openFilter = () => {

    this.setState({ modalIsOpen: true })
    let item = {}
    item['values'] = {}

    this.props.page.headers.forEach(header => {
      if (FilterFields[header.type]) {
        item.values[header.key] = {}
        // FilterFields[header.type].options.forEach(option => {
        //   if (option.default)
        //     item.values[header.key].operator = option.key
        // });
      }
    });

    // let filter

    // this.setState({ filterItem:  }, () => {
    this.refs.filterModal.showModal()
    // })
  }


  // openFilter = () => {

  //   this.setState({ modalIsOpen: true })
  //   let item = {}
  //   item['values'] = {}

  //   this.props.page.headers.forEach(header => {
  //     if (FilterFields[header.type]) {
  //       item.values[header.key] = {}
  //       FilterFields[header.type].options.forEach(option => {
  //         if (option.default)
  //           item.values[header.key].operator = option.key
  //       });
  //     }
  //   });

  //   this.setState({ filterItem: item }, () => {
  //     this.refs.filterModal.showModal()
  //   })
  // }

  changeFilter = (key, value, operator) => {
    // if (operator) {
    //   this.state.filterItem.values[key].operator = value
    // } else {
    //   this.state.filterItem.values[key].value = value
    // }
    // console.log(key)
    // console.log(value)


    let filterItem = this.state.filterItem
    let filterItemMock = this.state.filterItemMock


    let newKey = key

    if (key != '_id') {
      if (this.props.page.helperKey && this.props.page.helperKey != '') {
        newKey = this.props.page.helperKey + '.' + key
      }
    }

    // if(this)

    let newValue = value

    if (newValue && newValue != '') {

      this.props.page.headers.forEach(header => {
        if (header.key == key) {
          if (header.type == 'textCell') {
            newValue = { $regex: value, $options: 'i' }
          }

          if (header.type == 'multiLevelCell') {
            newValue = { $all: value }
          }

        }
      });

      filterItem[newKey] = newValue
      filterItemMock[key] = value

    } else {
      delete filterItem[newKey]
      delete filterItemMock[newKey]
    }

    this.setState({ filterItem })
    this.setState({ filterItemMock })

    // console.log(filterItem)


  }

  fetchFilter = () => {
    this.fetch(true)
    this.refs.filterModal.hideModal()
  }

  clearFilter = () => {
    this.setState({ filterItem: {}, filterItemMock: {} }, () => {
      this.fetch(true)
      this.refs.filterModal.hideModal()

    })
  }

  lockSend = () => {
    if (this.state.lockSend) {
      this.setState({ lockedItems: {} })
    }

    this.setState({ lockSend: !this.state.lockSend })
  }

  changeLockItems = (key) => {
    let lockedItems = this.state.lockedItems ? this.state.lockedItems : {}
    lockedItems[key] = lockedItems[key] ? !lockedItems[key] : true
    this.setState({ lockedItems })
  }


  showPage = () => {
    if (this.state.totalCount) {
      let pages = Math.ceil(this.state.totalCount / this.state.limit)
      // console.log(pages)
      if (pages > 1) {
        let array = []
        for (let i = 0; i < pages; i++) {
          array.push(i + 1)
        }

        let dotDone = false
        return (
          <div>
            <div className="flexcc w-100" style={{ flexDirection: 'revert', overflow: 'auto' }}>{array.map((prop, index) => {
              if (index == 0 || index == array.length - 1 || (index > this.state.currentPage - 2 && index < this.state.currentPage + 3)) {
                dotDone = false

                return (
                  <p onClick={() => this.changePage(index)} style={{ margin: 2, backgroundColor: this.state.currentPage == index ? "#007aff" : '#d2d8e299', color: this.state.currentPage == index ? "#fff" : '#789', borderRadius: 2, padding: '2px 10px', cursor: 'pointer' }}>{prop}</p>
                )

              } else {
                if (!dotDone) {
                  dotDone = true
                  return (
                    <p style={{ margin: 2, borderRadius: 2, padding: '2px 6px', }}>...</p>
                  )
                }
              }
            })}

            </div>
            <div className="flexcc mt-3">
              <input className="mediumiransansfont" onChange={(e) => this.setState({ selectedPage: e.target.value })} style={{ height: 35, border: '1px solid #eee', borderRadius: 4, width: 80, padding: '4px 6px' }} placeholder={"صفحه"} />
              <button onClick={() => this.changePage(this.state.selectedPage - 1)} className="mediumiransansfont" style={{ backgroundColor: '#007aff', borderRadius: 4, padding: '5px 15px', color: '#fff' }}>برو</button>
            </div>
          </div>
        )
      }
    }
  }



  changePage = (index) => {
    // console.log(index)
    this.setState({ currentPage: index }, () => {
      this.fetch()
    })
  }


  openQuestions = (questionsList) => {
    let ids = ''
    let questions = questionsList ? questionsList : this.state.data
    // console.log(questions)
    if (!questionsList) {
      questions.forEach(element => {
        ids = ids + element._id + '-'
      });
    } else {
      questions.forEach(element => {
        // console.log(element)
        if (element) {
          ids = ids + element + '-'
        }
      })
    }


    // console.log(ids)
    window.open("http://quiz.gamas20.ir/testquestions?ids=" + ids, "_blank")
  }

  render() {
    if (true) {
      return (
        <div className="w-100">

          <Container modules={this.props.modules} isOpen={true} modalIsOpen={this.state.modalIsOpen} location={this.props.location} >

            <div style={{ paddingRight: '6%', paddingLeft: '6%', }}>

              {!this.props.page && (
                <div className="text-center mt-4">
                  <Loader
                    type="ThreeDots"
                    color="#789"
                    height="60"
                    width="60"
                  />
                </div>
              )}

              {this.props.page && (
                <div className="w-100">

                  <TablePageHeader openFilter={this.openFilter} addAction={this.addAction} title={this.props.page.title} description={this.props.page.description} />
                  <TablePage openQuestions={this.openQuestions} openFilter={this.openFilter} page={this.props.page} actions={this.state.actions} openActionsModal={this.openActionsModal} data={this.state.data} headers={this.props.page.headers} addAction={this.addAction} removeAction={this.removeAction} editAction={this.editAction} options={this.props.page.options} isLoadingData={this.state.isLoadingData} />

                  {/* {this.state.totalCount && ( */}
                  <div className="w-100" style={{ overflow: 'hidden' }}>
                    {this.showPage()}
                  </div>
                  {/* <p onClick={() => this.changePage(0)} style={{ margin: 2, backgroundColor: this.state.currentPage == 0 ? "#007aff" : '#d2d8e299', color: this.state.currentPage == 0 ? "#fff" : '#789', borderRadius: 2, padding: '2px 10px', cursor: 'pointer' }}>{0}</p> */}

                  {/* )} */}
                </div>
              )}
            </div>

          </Container>

          {this.props.page && (
            <div>
              <RemoveModal cancelModal={this.cancelModal} removeFunction={this.fetchRemove} ref={'removeModal'} />

              {((this.state.selectedIndex != null && this.state.selectedIndex < this.state.data.length) || (this.state.selectedIndex == -1)) && (
                <ItemModal openQuestions={this.openQuestions} isLoading={this.state.isLoadingSend} page={this.props.page} cancelModal={this.cancelModal} fetchAdd={() => this.fetchAdd()} changeValue={this.changeValue} changeFiles={this.changeFiles} removeFunction={this.fetchRemove} ref={'itemModal'} headers={this.props.page.headers} item={this.state.currentItem} lockSend={this.lockSend} isLocked={this.state.lockSend} lockedItems={this.state.lockedItems} changeLockItems={this.changeLockItems} maxWidth={600} />
              )}


              <FilterModal cancelModal={this.cancelModal} clearFilter={this.clearFilter} fetchFilter={() => this.fetchFilter()} changeValue={this.changeFilter} ref={'filterModal'} headers={this.props.page.headers} item={this.state.filterItemMock} />


              <Modal cancelModal={this.cancelModal} className="lightiransansfont" ref={'actionsModal'} maxWidth={250} style={{ alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center' }} zIndex={1}>

                <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <p style={{ textAlign: 'center', fontSize: 20, marginTop: 0, fontWeight: 'bold' }}>عملیات ها</p>
                </div>


                <div className="mt-3" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

                  {this.state.actions.map((prop, index) => {
                    return (
                      <div className="mb-2">
                        <div style={{ flex: 1, flexDirection: 'column', position: 'relative' }} >
                          <div onClick={() => this.butAction(this.state.actionIndex, prop.action)} style={{ cursor: 'pointer', padding: 10, paddingLeft: 15, paddingRight: 15, borderRadius: 20, backgroundColor: prop.color + '25', justifyContent: 'center', alignItems: 'center' }}>
                            <p className={'mediumiransansfont'} style={{ margin: 7, color: '#fff', textAlign: 'center', fontSize: 13, margin: 0, color: prop.color }}>{prop.title}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </div>

              </Modal>

            </div>

          )}


        </div>

      );
    } else {
      return (<div></div>)
    }
  }

}

export default FormApplet;
