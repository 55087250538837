const FormBuilderObjects = {
    textCell: {
        name: 'Single Line',
        type: 'textCell',
        icon: 'https://image.flaticon.com/icons/svg/1647/1647300.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Single Line' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: '' } },
            { type: 'textCell', key: 'defaultValue', information: { label: 'Default Value', defaultValue: '' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    textAreaCell: {
        name: 'Text Area',
        type: 'textAreaCell',
        icon: 'https://image.flaticon.com/icons/svg/1647/1647300.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Text Area' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: '' } },
            { type: 'textCell', key: 'defaultValue', information: { label: 'Default Value', defaultValue: '' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    numberCell: {
        name: 'Number',
        type: 'numberCell',
        icon: 'https://image.flaticon.com/icons/svg/1647/1647300.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Number' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: '' } },
            { type: 'numberCell', key: 'defaultValue', information: { label: 'Default Value', defaultValue: '' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },



    phoneCell: {
        name: 'Phone',
        type: 'phoneCell',
        icon: 'https://image.flaticon.com/icons/svg/1647/1647300.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Phone' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: '' } },
            { type: 'phoneCell', key: 'defaultValue', information: { label: 'Default Value', defaultValue: '' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    imageCell: {
        name: 'Image',
        type: 'imageCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057196.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Image' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'تصویر مورد نظر خود را انتخاب کنید' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'single', information: { label: 'Single Image', defaultValue: false } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'copper', information: { label: 'Use Cropper', defaultValue: true } },
            { type: 'textCell', key: 'ratio', information: { label: 'Cropper Ratio', defaultValue: '1:1' } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },



    videoCell: {
        name: 'Video',
        type: 'videoCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057196.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Video' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'ویدیو مورد نظر خود را انتخاب کنید' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            // { type: 'booleanCell', key: 'single', information: { label: 'Single Image', defaultValue: false } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            // { type: 'booleanCell', key: 'copper', information: { label: 'Use Cropper', defaultValue: true } },
            // { type: 'textCell', key: 'ratio', information: { label: 'Cropper Ratio', defaultValue: '1:1' } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    mapCell: {
        name: 'Map',
        type: 'mapCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057196.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Map' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'Choose ...' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            // { type: 'booleanCell', key: 'single', information: { label: 'Single Image', defaultValue: false } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            // { type: 'booleanCell', key: 'copper', information: { label: 'Use Cropper', defaultValue: true } },
            // { type: 'textCell', key: 'ratio', information: { label: 'Cropper Ratio', defaultValue: '1:1' } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },



    fileCell: {
        name: 'File',
        type: 'fileCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057196.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'File' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'فایل مورد نظر خود را انتخاب کنید' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'single', information: { label: 'Single Image', defaultValue: false } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            // { type: 'booleanCell', key: 'copper', information: { label: 'Use Cropper', defaultValue: true } },
            // { type: 'textCell', key: 'ratio', information: { label: 'Cropper Ratio', defaultValue: '1:1' } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },



    switchCell: {
        name: 'Switch',
        type: 'switchCell',
        icon: 'https://image.flaticon.com/icons/svg/1647/1647158.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Switch' } },
            { type: 'booleanCell', key: 'defaultValue', information: { label: 'Default Value', defaultValue: true } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    editorCell: {
        name: 'Html Editor',
        type: 'editorCell',
        icon: 'https://image.flaticon.com/icons/svg/1647/1647284.svg',
        notInSubform: true,
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Html Editor' } },
            { type: 'textCell', key: 'defaultValue', information: { label: 'Default Value', defaultValue: '' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    selectCell: {
        name: 'Dropdown',
        type: 'selectCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057094.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Dropdown' } },
            { type: 'optionMakerCell', key: 'options', information: { label: 'Options', options: [], type: 'radio' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'انتخاب کنید' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'searchable', information: { label: 'Searchable', defaultValue: true } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    selectApiCell: {
        name: 'Lookup List',
        type: 'selectApiCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057666.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Lookup List' } },
            { type: 'textCell', key: 'address', information: { label: 'Lookup From', defaultValue: '' } },
            { type: 'textCell', key: 'titleField', information: { label: 'Title Field', defaultValue: '' } },
            // { type: 'textCell', key: 'valueField', information: { label: 'Value Field', defaultValue: '' } },
            { type: 'textCell', key: 'filter', information: { label: 'Filter', defaultValue: '' } },

            // { type: 'optionMakerCell', key: 'options', information: { label: 'Options', options: [], type: 'radio' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'انتخاب کنید' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'searchable', information: { label: 'Searchable', defaultValue: true } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    multiSelectApiCell: {
        name: 'Multi Select',
        type: 'multiSelectApiCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057666.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Multi Select' } },
            { type: 'textCell', key: 'address', information: { label: 'Lookup From', defaultValue: '' } },
            { type: 'textCell', key: 'titleField', information: { label: 'Title Field', defaultValue: '' } },
            // { type: 'textCell', key: 'valueField', information: { label: 'Value Field', defaultValue: '' } },
            { type: 'textCell', key: 'filter', information: { label: 'Filter', defaultValue: '' } },

            // { type: 'optionMakerCell', key: 'options', information: { label: 'Options', options: [], type: 'radio' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'انتخاب کنید' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'searchable', information: { label: 'Searchable', defaultValue: true } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },



    textRecommenderCell: {
        name: 'Text Recom.',
        type: 'textRecommenderCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057666.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Text Recommender' } },
            { type: 'textCell', key: 'address', information: { label: 'Lookup From', defaultValue: '' } },
            { type: 'textCell', key: 'titleField', information: { label: 'Title Field', defaultValue: '' } },
            // { type: 'textCell', key: 'valueField', information: { label: 'Value Field', defaultValue: '' } },
            { type: 'textCell', key: 'filter', information: { label: 'Filter', defaultValue: '' } },

            // { type: 'optionMakerCell', key: 'options', information: { label: 'Options', options: [], type: 'radio' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'انتخاب کنید' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'searchable', information: { label: 'Searchable', defaultValue: true } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    multiLevelCell: {
        name: 'MultiLevel',
        type: 'multiLevelCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057094.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Multi Level' } },
            { type: 'textCell', key: 'address', information: { label: 'Lookup From', defaultValue: '' } },
            { type: 'textCell', key: 'titleField', information: { label: 'Title Field', defaultValue: '' } },
            { type: 'textCell', key: 'valueField', information: { label: 'Value Field', defaultValue: '' } },

            { type: 'textCell', key: 'reference', information: { label: 'Reference Field', defaultValue: '' } },
            { type: 'textCell', key: 'startPoint', information: { label: 'Start Point', defaultValue: '' } },
            { type: 'textCell', key: 'root', information: { label: 'Root', defaultValue: '' } },

            // { type: 'textCell', key: 'valueField', information: { label: 'Value Field', defaultValue: '' } },
            { type: 'textCell', key: 'filter', information: { label: 'Filter', defaultValue: '' } },

            // { type: 'optionMakerCell', key: 'options', information: { label: 'Options', options: [], type: 'radio' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'انتخاب کنید' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'searchable', information: { label: 'Searchable', defaultValue: true } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    dateCell: {
        name: 'Date Picker',
        type: 'dateCell',
        icon: 'https://image.flaticon.com/icons/svg/1645/1645684.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Date Picker' } },
            { type: 'dateCell', key: 'defaultValue', information: { label: 'Default Value', defaultValue: '' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },


    subformCell: {
        name: 'Subform',
        type: 'subformCell',
        icon: 'https://image.flaticon.com/icons/svg/1178/1178076.svg',
        notInSubform: true,
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Subform' } },
            // { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: '' } },
            // { type: 'textCell', key: 'defaultValue', information: { label: 'Default Value', defaultValue: [] } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },



    productCell: {
        name: 'Products',
        type: 'productCell',
        icon: 'https://image.flaticon.com/icons/svg/1057/1057666.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Field Label', defaultValue: 'Lookup List' } },
            { type: 'textCell', key: 'address', information: { label: 'Lookup From', defaultValue: '' } },
            { type: 'textCell', key: 'titleField', information: { label: 'Title Field', defaultValue: '' } },
            // { type: 'textCell', key: 'valueField', information: { label: 'Value Field', defaultValue: '' } },
            { type: 'textCell', key: 'filter', information: { label: 'Filter', defaultValue: '' } },

            // { type: 'optionMakerCell', key: 'options', information: { label: 'Options', options: [], type: 'radio' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: 'انتخاب کنید' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'searchable', information: { label: 'Searchable', defaultValue: true } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },



    priceCell: {
        name: 'Price',
        type: 'priceCell',
        icon: 'https://image.flaticon.com/icons/svg/1647/1647300.svg',
        headers: [
            { type: 'textCell', key: 'label', information: { label: 'Price', defaultValue: 'Price' } },
            { type: 'textCell', key: 'placeHolder', information: { label: 'Place Holder', defaultValue: '' } },
            { type: 'numberCell', key: 'defaultValue', information: { label: 'Default Value', defaultValue: '' } },
            { type: 'textCell', key: 'Instruction', information: { label: 'Instruction', defaultValue: '' } },
            { type: 'booleanCell', key: 'required', information: { label: 'Required', defaultValue: false } },
            { type: 'booleanCell', key: 'showMain', information: { label: 'Show in table', defaultValue: true } },

        ]
    },

    // textCell2: { name: 'Multi Line', type: 'textCell', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Number', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Decimal', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Name', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Address', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Phone', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Email', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Date', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Time', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Drop Down', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Radio', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Multiple Choice', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Check box', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Decision Box', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // textCell1: { name: 'Date - Time', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // TextCell1: { name: 'Website', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // TextCell1: { name: 'Currency', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // TextCell1: { name: 'File Uplaod', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // ImageCell: { name: 'Image Upload', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },

    // { name: 'Section', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Page Break', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Payment', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Matrix Choice', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Lookup', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Terms and conditions', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Editor', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Formula', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Uniqe Id', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Slider', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Rating', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'File Manager', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },
    // { name: 'Signature', type: 'text', icon: 'https://image.flaticon.com/icons/svg/1102/1102457.svg' },

}

const FormBuilderItems = Object.values(FormBuilderObjects)


export { FormBuilderItems, FormBuilderObjects }