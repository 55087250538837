import React from "react";
import Modal from "../Modal.jsx";
import SubmitButton from '../Common/SubmitButton'
import Loader from 'react-loader-spinner'
import { settings } from '../../Variables'

import TextInput from '../Inputs/Text'
import SelectInput from '../Inputs/Select'
import IntegerInput from '../Inputs/Integer'
import EditorInput from '../Inputs/Editor'
import FileCell from '../Inputs/File'
import ImageCell from '../Inputs/Image'
import MapCell from '../Inputs/MapInput'

import DateCell from '../Inputs/Date'
import ListCell from '../Inputs/List'

import PriceCell from '../Inputs/Price'
import NumberCell from '../Inputs/Integer'
import PhoneCell from '../Inputs/PhoneInput'

import CheckInput from '../Inputs/Switch'
import VideoCell from '../Inputs/Video'
import SubFormTable from '../Inputs/SubformTable'
import MultiLevelSelect from '../Inputs/MultiLevelSelect'
import MultiSelect from "../Inputs/MultiSelect";
import TextAreaInput from "../Inputs/TextAreaInput.jsx";

// import TextCell from '../Table/Text'


class ItemModal extends React.Component {
  state = {
    isLoadingRemove: false,
    id: null,
    index: null
  }


  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
  }


  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // this.props.fetchAdd()
    }
  }

  showModal = () => {
    this.refs.removeModal.showModal()
  }

  hideModal = () => {
    this.refs.removeModal.hideModal()
    // if(this.props.cancelModal)
    // this.props.cancelModal()
  }


  checkKey(values, key) {
    if (key && values) {
      let value = null
      if (key.includes('.')) {
        let split = key.split('.')
        if (values[split[0]])
          value = values[split[0]][split[1]]

        // } else if (key.includes('*')) {

        //   let split = key.split('*')

        //   if (values[split[0]]) {

        //     values[[split[0]]].forEach(element => {
        //       for (const [key_, value_] of Object.entries(element)) {
        //         if (key_ == split[1]) {
        //           value = value_
        //         }
        //       }
        //     });
        //   }
      } else {
        value = values[key]
      }
      if (value == null)
        value = ''

      return value
    }
  }

  getPageHelper() {
    let helper = null
    if (this.props.page) {
      if (this.props.page.helperKey && this.props.page.helperKey != '') {
        helper = this.props.page.helperKey
      }
    }

    return helper
  }


  renderBody = () => {

    return (
      this.props.headers.map((headerProp, index) => {

        let values = {}

        if (!this.getPageHelper()) {
          values = this.props.item
        } else {
          values = this.props.item[this.getPageHelper()]
        }

        let value
        if (values) {
          value = this.checkKey(values, headerProp.key)
        } else {
          value = ' '
        }

        if (headerProp.type == "selectApiCell") {
          // console.log("HERE")
          // console.log(value)
          // console.log(values)
        }

        // if ((!value || value == "") && headerProp.information.defaultValue)
        //   value = headerProp.information.defaultValue


        // console.log(headerProp.information)
        return (
          <div className={'col-12  text-right mediumiransansfont mt-2'} style={{ color: '#000', position: 'relative' }} key={index}>



            <label className={'mb-2 mr-1 ml-0 ' + (headerProp.information.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 13, marginTop: 5, color: '#789' }}>{headerProp.information.label}
            </label>

            <span>
              {!this.props.item._id && this.props.isLocked && (
                <div className="d-inline-flex">
                  <div onClick={() => this.props.changeLockItems(headerProp.key)} style={{ transition: 'all 0.5s', backgroundColor: this.props.isLocked ? '#fff' : '#fff', width: 20, height: 20, borderRadius: 20, fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <i className="fa fa-lock" style={{ color: (this.props.lockedItems && this.props.lockedItems[headerProp.key]) ? '#007aff' : '#20202040' }}></i>
                  </div>
                </div>
              )}
            </span>

            {headerProp.type == 'person' && (
              <TextInput onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'textCell' && (
              <TextInput onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'textAreaCell' && (
              <TextAreaInput onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'mapCell' && (
              <MapCell onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'listCell' && (
              <ListCell onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'numberCell' && (
              <NumberCell onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'priceCell' && (
              <PriceCell onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'phoneCell' && (
              <PhoneCell onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'dateCell' && (
              <DateCell onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'status' && (
              <TextInput onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'integer' && (
              <IntegerInput onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'selectCell' && (
              <SelectInput type={'local'} onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'selectApiCell' && (
              <SelectInput type={'api'} onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'multiLevelCell' && (
              <MultiLevelSelect type={'api'} onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'multiSelectApiCell' && (
              <MultiSelect type={'api'} onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'editorCell' && (
              <EditorInput onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'switchCell' && (
              <CheckInput onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'imageCell' && (
              <ImageCell changeFiles={this.props.changeFiles} onChange={this.props.changeValue} info={headerProp.information} headerkey={headerProp.key} options={headerProp.options} title={value} />
            )}

            {/* {headerProp.type == 'videoCell' && (
              <VideoCell changeFiles={this.props.changeFiles} onChange={this.props.changeValue} info={headerProp.information} headerkey={headerProp.key} options={headerProp.options} title={value} />
            )} */}

            {headerProp.type == 'fileCell' && (
              <FileCell changeFiles={this.props.changeFiles} onChange={this.props.changeValue} info={headerProp.information} headerkey={headerProp.key} options={headerProp.options} title={value} />
            )}

            {headerProp.type == 'videoCell' && (
              <VideoCell changeFiles={this.props.changeFiles} onChange={this.props.changeValue} info={headerProp.information} headerkey={headerProp.key} options={headerProp.options} title={value} />
            )}


            {headerProp.type == 'subformCell' && (
              <SubFormTable onChange={this.props.changeValue} headerkey={headerProp.key} info={headerProp.information} setFull={this.props.setFull} title={value} fullValues={this.props.fullValues} page={this.props.page} />
            )}


          </div>
        )
      })
    )

  }


  render() {
    // console.log(this.props.item)
    return (
      <Modal cancelModal={this.props.cancelModal} backgroundColor={'transparent'} className="lightiransansfont" ref={'removeModal'} maxWidth={this.props.maxWidth ? this.props.maxWidth : 600} style={{}}>
        {this.props.item && (
          <div>

            {this.props.item._id ? (
              <div className='pt-2 pb-2 mb-3 text-center' style={{ borderRadius: 4 }}>
                <p className=" mt-0 mediumiransansfont" style={{ color: '#fff', fontSize: 15, fontWeight: 'bold' }}>ویرایش اطلاعات</p>
                <p className=" mt-0 mediumiransansfont" style={{ color: '#fff', fontSize: 10, fontWeight: 'bold', opacity: 0.4 }}>پس از تکمیل اطلاعات دکمه ثبت را فشار دهید</p>
                <p className=" mt-0 mediumiransansfont" style={{ color: '#fff', fontSize: 12, fontWeight: 'bold', opacity: 0.4 }}>{this.props.item._id}</p>

                {/* {this.props.item && this.props.item.creator && (
                  <p className=" mt-0 mediumiransansfont" style={{ color: '#fff', fontSize: 14, fontWeight: 'bold', opacity:1 }}>توسط: {this.props.item.creator.name}</p>
                )} */}
              </div>
            ) : (
                <div className='pt-2 pb-2 mb-3' style={{ borderRadius: 4, display: 'flex' }}>
                  <div className="d-inline-flex">
                    <div onClick={() => this.props.lockSend()} style={{ transition: 'all 0.5s', backgroundColor: this.props.isLocked ? '#007aff' : '#ffffff40', width: 40, height: 40, borderRadius: 20, fontSize: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <i className="fa fa-lock" style={{ color: this.props.isLocked ? '#ffffff' : '#ffffff80' }}></i>
                    </div>
                  </div>
                  <div className="mr-3">
                    <p className="text-right mt-0 mediumiransansfont" style={{ color: '#fff', fontSize: 15, fontWeight: 'bold' }}>افزودن اطلاعات جدید</p>
                    <p className="text-right mt-0 mediumiransansfont" style={{ color: '#fff', fontSize: 10, fontWeight: 'bold', opacity: 0.4 }}>پس از تکمیل اطلاعات دکمه ثبت را فشار دهید</p>
                  </div>
                </div>
              )}

            <div style={{ backgroundColor: '#fff', padding: 20, borderRadius: 15, boxShadow: '0px 0px 20px rgba(0,0,0,0.1)' }}>
              <div className="row">
                {this.renderBody()}

                <div className="col-12 d-flex mt-3">
                  <div style={{ flex: 1 }}>
                    <SubmitButton onClick={() => this.hideModal()} title={'انصراف'} color={'#323232'} background={'#e3e4e5'} class={'mt-3 ml-1'} />
                  </div>

                  {this.props.page.applet == "quizes" && (

                    <div style={{ flex: 1 }} className="mx-1">
                      <SubmitButton onClick={() => this.props.openQuestions(this.props.item.questions)} title={'مشاهده سوالات'} color={'#fff'} background={'#6602ef'} class={'mt-3 '} />
                    </div>

                  )}

                  {this.props.isLoading ? (
                    <div className="text-center h-100 d-flex pt-2" style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                      <Loader
                        type="ThreeDots"
                        color="#789"
                        height="40"
                        width="40"
                      />
                    </div>) : (
                      <div style={{ flex: 1 }}>
                        <SubmitButton onClick={this.props.fetchAdd} title={'ارسال'} color={'#fff'} background={'#007aff'} class={'mt-3 mr-1'} />
                      </div>
                    )}
                </div>
              </div>
            </div>

            <p className="text-center mt-2" style={{ color: '#fff', fontSize: 10, fontWeight: 'bold', opacity: 0.2 }}>ALL RIGHTS RESERVED BY RHINO <i className="fa fa-copyright"></i></p>

          </div>
        )}

      </Modal>
    );
  }
}



export default ItemModal;
