import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Auth {
    constructor() {
        this.authenticated = false
    }

    login(cb) {
        this.authenticated = true
        cb()
    }

    logout(cb) {
        this.authenticated = false
        cookies.remove('username')
        cookies.remove('adminToken')
        cookies.remove('role')
        cookies.remove('id')

        cb()
    }


    isAuthenticated() {
        var username = cookies.get('adminToken')
            // console.log('check')
            // console.log(username)
        if (username != null) {
            this.authenticated = true
        }
        return this.authenticated
    }
}




export default new Auth()