import React from 'react'
import CountiesCodeModal from '../Modals/CountriesCodeList'
// import { checkTextTranslation } from '../../utils/useful'

class PhoneInput extends React.Component {
    state = {
        selectedCountry: {}
    }

    componentDidMount() {
        this.codeToCountry(this.props.title)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.title !== this.props.title) {
            let code = this.props.title.includes('-') ? this.props.title.substring(0, 6) : this.props.title.substring(0, 4)
            if (this.state.selectedCountry.dial_code !== code) {
                this.codeToCountry(this.props.title, true)
            }
        }

    }


    changeInputValue(target) {
        // console.log(target)
        if (target) {
            let value = (target.validity.valid) ? target.value : null
            //console.log(value)
            if (value !== null && this.state.selectedCountry.dial_code) {
                value = this.state.selectedCountry.dial_code + value
                this.props.onChange(this.props.headerkey, value, this.props.extra)
            }
        }
    }


    codeToCountry(value, lastAttempt) {
        let code = ""
        if (value) {
            code = value.includes('-') ? value.substring(0, 7) : value.substring(0, 4)
        }
        this.countiesCodeModal.selectByCode(code, lastAttempt)
    }


    openCountiesModal() {
        this.countiesCodeModal.showModal()
    }

    selectCountyCode = (selectedCountry) => {
        this.setState({ selectedCountry }, () => {
            setTimeout(() => {
                this.changeInputValue(this.refs.input)
            }, 100);
        })
    }

    valueToNumber(value) {
        let newValue = value
        if (value) {
            if (this.state.selectedCountry.dial_code && value !== '') {
                newValue = newValue.substring(newValue.includes('-') ? 7 : 4, newValue.length)
            }
        } else {
            newValue = ""
        }
        return newValue
    }

    valueToCode(value) {
        if (value && value !== "") {
            let newValue = value.includes('-') ? value.substring(3, value.length) : value
            newValue = String(Number(newValue))
            return newValue
        } else {
            return ""
        }
    }

    render() {
        return (
            <div className="w-100 d-flex px-2 " style={{direction:'ltr', backgroundColor: '#f7f7f7', borderColor: '#ddd',border:"1px solid #ddd", color: '#222', alignItems: 'center',height:45,borderRadius:4 }}>
                <div onClick={() => this.openCountiesModal()} className="flexcc cursor-pointer d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <p className="mt-2" style={{ fontSize: 30 }} >{this.state.selectedCountry.flag}</p>
                    </div>
                    <p style={{ fontSize: 15, margin: '5px 7px 5px 10px' }}>+{this.valueToCode(this.state.selectedCountry.dial_code)}</p>
                </div>
                <div className="h-100 w-100">
                    <input style={{ backgroundColor: 'transparent', border: 'none' }} className={'w-100 h-100'} ref={"input"} maxLength="10" pattern="[0-9]*" value={this.valueToNumber(this.props.title)} onChange={(e) => this.changeInputValue(e.target)} placeholder={this.props.info.placeholder} />
                </div>
                <CountiesCodeModal ref={el => this.countiesCodeModal = el} selectCountyCode={this.selectCountyCode} selectedCountry={this.state.selectedCountry} />
            </div>
        )
    }
}

export default (PhoneInput);