import React from "react";
import TextInput from '../components/Inputs/Text'
import SelectInput from '../components/Inputs/Select'
import IntegerInput from '../components/Inputs/Integer'
import EditorInput from '../components/Inputs/Editor'
import FileCell from '../components/Inputs/File'
import ImageCell from '../components/Inputs/Image'
import MapCell from '../components/Inputs/MapInput'

import DateCell from '../components/Inputs/Date'
import ListCell from '../components/Inputs/List'

import PriceCell from '../components/Inputs/Price'
import NumberCell from '../components/Inputs/Integer'
import PhoneCell from '../components/Inputs/PhoneInput'

import CheckInput from '../components/Inputs/Switch'
import VideoCell from '../components/Inputs/Video'
import SubFormTable from '../components/Inputs/SubformTable'
import MultiLevelSelect from '../components/Inputs/MultiLevelSelect'
import MultiSelect from "../components/Inputs/MultiSelect";
import TextAreaInput from "../components/Inputs/TextAreaInput.jsx";
import PagesTable from './FormApplet'
import Cookies from 'universal-cookie';
import ItemModal from "../components/Modals/ItemModal";
import Container from '../components/Container'
import Loader from 'react-loader-spinner'
import { setObject } from "../useful";
import { domain, headers, colors, settings } from '../Variables'

const cookies = new Cookies();
class Config extends React.Component {

  constructor(prop) {
    super(prop)
    this.state = {
      data: [],
      removeData: null,
      // page: null,
      modalIsOpen: false,
      currentItem: {},
      item: {},
      page: {
        title: 'کانفیگ سیستم',
        description: 'کانفیگ سیستم',
        fetchUrl: '/values/getConfig',
        addUrl: '/values/editConfig',
        editUrl: '/values/editConfig',
        removeUrl: '/values/removeConfig',
        helperKey: 'values',
        pageType: 'normalTable',


        headers: [
          { key: 'adsCount', information: { label: 'تعداد تبلیغات اولیه', showMain: true }, type: 'numberCell', showMain: false },
          // { information: { label: 'نام کاربری', showMain: true }, type: 'textCell', key: 'username', showMain: false },
          // { information: { label: 'کلمه عبور' }, type: 'textCell', key: 'password', showMain: false },
          // { information: { label: 'نوع کاربری', options: [{ value: 'admin', title: 'admin' }, { value: 'editor', title: 'editor' }] }, type: 'selectCell', key: 'role', showMain: false },

          // { information: { label: 'شماره تماس', showMain: true }, type: 'textCell', key: 'phone', showMain: true },
          // { information: { label: 'توضیحات' }, type: 'text', key: 'description', showMain: false },
          // { information: { label: 'ایمیل' }, type: 'text', key: 'email', showMain: false },
          // { information: { label: 'شهر' }, type: 'select', key: 'city', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
          // { information: { label: 'تصویر' }, key: 'image', type: 'file', options: { single: true }, showMain: false, fullCol: true },
          // { information: { label: 'وضعیت' }, key: 'status', type: 'select', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: "2" }] }, settings: { required: true }, showMain: true }
          // { information:{label: 'اصلاح'}, type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
          // { information:{label: 'حذف'}, type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }
        ],
      },
    }
  }

  componentDidMount() {

    let role = cookies.get('role')

    if (role == 'editor') {
      this.props.history.goBack()
    } else {
      if (this.refs.itemModal) {
        this.refs.itemModal.showModal()
      }

      this.unlisten = this.props.history.listen((location, action) => {
        this.loadPageData()
      });
      this.loadPageData()
    }
  }

  componentWillUnmount() {
    if (this.unlisten)
      this.unlisten();
  }


  loadPageData = () => {
    this.setState({ data: [], removeData: [], isLoadingData: true }, () => {
      // this.setState({ page: pagesData[this.props.match.params.name] }, () => {
      if (this.state.page) {
        this.fetch()
        // this.fetchPage()
        // this.forceUpdate()
      } else {
        this.props.history.push('/dashboard')
      }
      // })
    })
  }




  fetch() {

    this.setState({ isLoadingData: true, data: [] })
    let body = { filter: {} }
    let api = this.state.page.fetchUrl
    const cookies = new Cookies();
    let newHeaders = headers
    newHeaders.authorization = 'bearer ' + cookies.get('adminToken')

    fetch(domain + api, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: newHeaders
    }).then(response => response.json()).then(data => {

      console.log(data)
      this.setState({ isLoadingData: false })

      if (data.status == 200 && data.info) {
        this.setState({ currentItem: data.info })
      }

    }).catch(error => this.setState({ error, isLoading: false }));
  }


  fetchAdd() {

    this.setState({ isLoadingData: true, data: [] })
    let body = this.state.currentItem
    let api = this.state.page.editUrl

    const cookies = new Cookies();
    let newHeaders = headers
    newHeaders.authorization = 'bearer ' + cookies.get('adminToken')

    fetch(domain + api, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: newHeaders
    }).then(response => response.json()).then(data => {

      console.log(data)
      this.setState({ isLoadingData: false })

      if (data.status == 200) {
        // this.setState({ currentItem: data.info })
      }

    }).catch(error => this.setState({ error, isLoading: false }));
  }






  checkKey(values, key) {
    if (key && values) {
      let value = null
      if (key.includes('.')) {
        let split = key.split('.')
        if (values[split[0]])
          value = values[split[0]][split[1]]
      } else {
        value = values[key]
      }
      if (value == null)
        value = ''

      return value
    }
  }

  getPageHelper() {
    let helper = null
    if (this.state.page) {
      if (this.state.page.helperKey && this.state.page.helperKey != '') {
        helper = this.state.page.helperKey
      }
    }

    return helper
  }



  changeValue = (key, value) => {

    // console.log(key)
    let helper
    if (this.state.page.helperKey && this.state.page.helperKey != '') {
      helper = this.state.page.helperKey
    }

    // let currentItem = this.state.currentItem

    let newKey = key

    if (helper) {
      newKey = helper + '.' + key
    }

    let currentItem = { ...this.state.currentItem }

    if (key == '**files') {
      currentItem = setObject(currentItem, '**files', value, true)
    } else {
      currentItem = setObject(currentItem, newKey, value)
    }

    this.setState({ currentItem })
    console.log(currentItem)

  }

  changeFiles = (files, key, type) => {

    if (!this.state.currentItem["**files"]) {
      this.state.currentItem["**files"] = {}
    }

    this.state.currentItem["**files"][key] = files
    // console.log(this.state.currentItem)
  }



  renderBody = () => {

    return (
      this.state.page.headers.map((headerProp, index) => {

        let values = {}

        if (!this.getPageHelper()) {
          values = this.state.currentItem
        } else {
          values = this.state.currentItem[this.getPageHelper()]
        }

        let value
        if (values) {
          value = this.checkKey(values, headerProp.key)
        } else {
          value = ''
        }

        if (headerProp.type == "selectApiCell") {
          // console.log("HERE")
          // console.log(value)
          // console.log(values)
        }

        // if ((!value || value == "") && headerProp.information.defaultValue)
        //   value = headerProp.information.defaultValue


        // console.log(headerProp.information)
        return (
          <div className={'col-12 col-md-6 text-right mediumiransansfont mt-2'} style={{ color: '#000', position: 'relative' }} key={index}>



            <label className={'mb-2 mr-1 ml-0 ' + (headerProp.information.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 13, marginTop: 5, color: '#789' }}>{headerProp.information.label}
            </label>

            <span>
              {!this.state.currentItem._id && this.props.isLocked && (
                <div className="d-inline-flex">
                  <div onClick={() => this.props.changeLockItems(headerProp.key)} style={{ transition: 'all 0.5s', backgroundColor: this.props.isLocked ? '#fff' : '#fff', width: 20, height: 20, borderRadius: 20, fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <i className="fa fa-lock" style={{ color: (this.props.lockedItems && this.props.lockedItems[headerProp.key]) ? '#007aff' : '#20202040' }}></i>
                  </div>
                </div>
              )}
            </span>

            {headerProp.type == 'person' && (
              <TextInput onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'textCell' && (
              <TextInput onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'textAreaCell' && (
              <TextAreaInput onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'mapCell' && (
              <MapCell onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'listCell' && (
              <ListCell onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'numberCell' && (
              <NumberCell onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'priceCell' && (
              <PriceCell onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'phoneCell' && (
              <PhoneCell onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'dateCell' && (
              <DateCell onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'status' && (
              <TextInput onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'integer' && (
              <IntegerInput onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'selectCell' && (
              <SelectInput type={'local'} onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'selectApiCell' && (
              <SelectInput type={'api'} onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'multiLevelCell' && (
              <MultiLevelSelect type={'api'} onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'multiSelectApiCell' && (
              <MultiSelect type={'api'} onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'editorCell' && (
              <EditorInput onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} />
            )}

            {headerProp.type == 'switchCell' && (
              <CheckInput onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} title={value} />
            )}

            {headerProp.type == 'imageCell' && (
              <ImageCell changeFiles={this.props.changeFiles} onChange={this.changeValue} info={headerProp.information} headerkey={headerProp.key} options={headerProp.options} title={value} />
            )}

            {/* {headerProp.type == 'videoCell' && (
              <VideoCell changeFiles={this.props.changeFiles} onChange={this.changeValue} info={headerProp.information} headerkey={headerProp.key} options={headerProp.options} title={value} />
            )} */}

            {headerProp.type == 'fileCell' && (
              <FileCell changeFiles={this.props.changeFiles} onChange={this.changeValue} info={headerProp.information} headerkey={headerProp.key} options={headerProp.options} title={value} />
            )}

            {headerProp.type == 'videoCell' && (
              <VideoCell changeFiles={this.props.changeFiles} onChange={this.changeValue} info={headerProp.information} headerkey={headerProp.key} options={headerProp.options} title={value} />
            )}


            {headerProp.type == 'subformCell' && (
              <SubFormTable onChange={this.changeValue} headerkey={headerProp.key} info={headerProp.information} setFull={this.props.setFull} title={value} fullValues={this.props.fullValues} page={this.state.page} />
            )}


          </div>
        )
      })
    )

  }

  render() {

    return (
      <div>

        <Container modules={this.props.modules} isOpen={true} modalIsOpen={this.state.modalIsOpen} location={this.props.location} >

          <div style={{ paddingRight: '6%', paddingLeft: '6%', }}>

            {!this.state.page && (
              <div className="text-center mt-4">
                <Loader
                  type="ThreeDots"
                  color="#789"
                  height="60"
                  width="60"
                />
              </div>
            )}


            <div style={{ backgroundColor: '#fff', borderRadius: 8, padding: '20px 15px 20px 15px', marginBottom: 20 }}>
              <div className="row" style={{ paddingBottom: 0, alignItems: 'center' }}>

                <div className="col-6" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>

                  <img src="https://img.icons8.com/clouds/100/000000/form.png" style={{ height: 50 }} />
                  <div className="mr-3">
                    <p className={'mediumiransansfont'} style={{ marginRight: 0, marginTop: 0, fontSize: 11, opacity: 1, letterSpacing: 0, color: '#007aff', direction: 'ltr', cursor: 'pointer' }}>{this.state.page.description}</p>
                    <p className={'mediumiransansfont'} style={{ marginRight: 0, marginTop: 0, fontSize: 22, color: '#000', fontWeight: 'bold' }}>{this.state.page.title.toUpperCase()}</p>
                  </div>
                </div>

                {!this.state.isLoadingData && (
                  <div className="col-6 d-flex" style={{ justifyContent: 'flex-end' }}>
                    <button onClick={() => this.fetchAdd()} className={'mediumiransansfont'} style={{ backgroundColor: '#007aff', padding: '7px 15px', borderRadius: 8, color: '#fff' }}>
                      <p>ثبت اطلاعات</p>
                    </button>
                  </div>
                )}

                {this.state.isLoadingData && (
                  <div className="col-6 d-flex" style={{ justifyContent: 'flex-end' }}>
                    <Loader
                      type="Oval"
                      color="#789"
                      height="40"
                      width="40"
                    />
                  </div>
                )}



              </div>


            </div>


            <div className="px-4 pt-4 pb-5" style={{ backgroundColor: '#fff', borderRadius: 8 }}>
              <div className="row m-0" >
                {this.renderBody()}
              </div>
            </div>
          </div>

        </Container>
      </div>

    );

  }

}

export default Config;
