import React from "react";


class Message extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        if (this.props.myMessage) {

            return (
                <div className={"w-100 d-flex justify-content-end " + (this.props.hasTop ? 'mt-1' : 'mt-3')}>

                    <div className='ml-2 mr-2'>

                        <div style={{ backgroundColor: '#f2f4f6', padding: '8px 15px 8px 15px', borderRadius: (this.props.hasTop && this.props.hasBottom) ? '20px 5px 5px 20px' : (this.props.hasBottom ? '20px 20px 5px 20px' : (this.props.hasTop ? '20px 5px 20px 20px' : '20px 20px 20px 20px')) }}>
                            <span style={{ fontSize: 13, color: '#202020' }}>{this.props.data.message}</span>
                        </div>
                    </div>
                </div>
            )

        } else {

            return (
                <div className={"w-100 d-flex " + (this.props.hasTop ? 'mt-1' : 'mt-3')}>
                    <div style={{ marginRight: this.props.hasTop ? 30 : 0 }}>
                        {!this.props.hasTop && (<img src="/images/male.png" style={{ objectFit: 'cover', backgroundColor: '#eee', borderRadius: '50%', width: 30, height: 30, marginTop: 26 }} />)}
                    </div>
                    <div className='ml-2 mr-2'>
                        {!this.props.hasTop && (
                            <div className="d-flex justify-content-between ml-3 mb-1">
                                <span style={{ fontSize: 12, color: '#789' }}>Usersname</span>
                                {/* <span style={{ fontSize: 13, color: '#789' }}>14:12 PM</span> */}
                            </div>
                        )}
                        <div style={{maxWidth:'300px', backgroundColor: '#007aff', padding: '8px 15px 8px 15px', borderRadius: (this.props.hasTop && this.props.hasBottom) ? '5px 20px 20px 5px' : (this.props.hasBottom ? '20px 20px 20px 5px' : (this.props.hasTop ? '5px 20px 20px 20px' : '20px 20px 20px 15px')) }}>
                            <span style={{ fontSize: 13, color: '#fff' }}>{this.props.data.message}</span>
                            {/* <span style={{ fontSize: 13, color: '#fff' }}>7:42</span> */}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Message