import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.css";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
// import "assets/scss/login.css";
import "./assets/scss/login.css";
import "./assets/scss/custom.css";
import "./assets/scss/sidebar.css";

import Login from './views/Login.jsx'
import indexRoutes from "./index.jsx";
import { ProtectedRoute } from "./components/ProtectedRoute.jsx";
import { createStore } from 'redux'
import { Provider } from 'react-redux'



const initialState = {
    senario: { name: sessionStorage.getItem('senario'), id: '' }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_SENARIO':
            var newState = state
            newState.senario.name = action.name
            return newState

    }

    return state

}
const store = createStore(reducer)

const hist = createBrowserHistory();

ReactDOM.render(
    <Provider store={store} >
        <Router history={hist} >
            < Switch >

                <Route path={'/login'}
                    component={Login} />;
     <Route exact path={'/'}
                    component={Login}
                />;

    {
                    indexRoutes.map((prop, key) => {
                        // if(prop.redirect){
                        //   return <Redirect from={prop.path} to={prop.pathTo} key={key} />
                        // }
                        return <ProtectedRoute path={prop.path}
                            key={key}
                            component={prop.component}
                        />;
                    })
                }

                < Route path='*'
                    component={Login}
                />;




    </Switch>

            { /* <Route path="/addbanner" exact component={UserPage} /> */}

        </Router>
    </Provider>,
    document.getElementById("root")
);