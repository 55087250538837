import React from 'react'

class TextAreaInput extends React.Component {
    state = {
    }

    componentWillMount() {
        if (this.props.title)
            this.setState({ value: this.props.title })
    }


    changeInputValue(target) {
        let value = (target.validity.valid) ? target.value : null
        
        if (value !== null) {
            this.setState({ value: target.value });
            this.props.onChange(this.props.headerkey, value, this.props.extra)
            // this.props.changeValue(this.props.header.key, value)
        }
    }


    render() {
        let info = this.props.info
        return (
            <div className="w-100 h-100">
                <textarea className="w-100" ref={el => this.input = el} value={this.state.value} onChange={(e) => this.changeInputValue(e.target)} placeholder={info.placeHolder}  className="transpanet-input w-100 mediumiransansfont" style={{ backgroundColor: '#f7f7f7',outline:'none', borderColor: '#ddd', color: '#222',borderRadius:4 }}/>
            </div>
        )
    }
}

export default TextAreaInput