import React from "react";
import ChatListItem from '../components/Chat/ChatListItem'
import Message from '../components/Chat/Message'
import InputBox from '../components/Chat/InputBox'
import Modal from "../components/Modal.jsx";
// import { domain } from './../../Config';
// import AsyncSelect from 'react-select/async';
import { Label, FormGroup, Input, Button, Col, Row } from 'reactstrap';
// import makeAnimated from 'react-select/animated';
// import ChatServices from '../services/Chat.services';
import Dropzone from 'react-dropzone'
// import Auth from "./../../Auth";
// import { use } from "i18next/dist/commonjs";
// const animatedComponents = makeAnimated();

class Chat extends React.Component {
    constructor(props) {
        super(props)
        // this.ChatServices = new ChatServices();
        this.state = {

            page: 0,
            addConversationStage: 0,
            selectedIndex: null,
            groupName: '',
            chatList: [1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5],
            users: { 1: { username: 'Salli jam', avatar: 'https://images.unsplash.com/photo-1546538994-4f15d0aa966f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=80&q=80' } },
            messageList: [
                { sender: 1, message: 'This is a test' },
                { sender: 1, message: 'A message would be shown here' },
                { sender: 1, message: 'This is a test' },
                { sender: 'me', message: 'This is a test' },
                { sender: 1, message: 'This is a test This is a test This is a test This is a test This is a test' },
                { sender: 1, message: 'This is a test' },
                { sender: 1, message: 'This is a test' },
                { sender: 'me', message: 'This is a test' },
                { sender: 1, message: 'This is a test' },
                { sender: 1, message: 'This is a test' },
                { sender: 1, message: 'This is a test' },
                { sender: 'me', message: 'This is a test' },
                { sender: 1, message: 'This is a test' },
                { sender: 'me', message: 'This is a test' },
                { sender: 'me', message: 'This is a test' },
                { sender: 'me', message: 'This is a test' },
                { sender: 'me', message: 'This is a test' },
                { sender: 1, message: 'This is a test' },
                { sender: 1, message: 'This is a test' },
                { sender: 1, message: 'This is a test' },
            ],


            allUsersList: []
        }
    }

    componentDidMount() {
        this.scrollToBottom()

    }



    // async getAllUser() {
    //     fetch(`${domain}/api/Admin/GetAllAdmin_User`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'bearer ' + Auth.getToken()
    //         }
    //     }).then(res => {
    //         if (res.status == 401) {
    //             Auth.logout(() => {
    //                 // this.props.history.push('login');
    //                 return -1
    //             })
    //         } else if (res.ok) { return res.json() } else { return -1 }
    //     }).then(data => {
    //         console.log(data)
    //         if (data && data != -1) {
    //             const dropdown = [];
    //             data.getAllAdmin_UserResponseItemViewModels.map(body => dropdown.push({ value: body.id, label: body.firstName + ' ' + body.lastName, }))
    //             this.setState({ allUsersList: dropdown })
    //         }
    //     });
    // }


    promiseOptions = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.filterColors(inputValue));
            }, 700);
        });

    filterColors = (inputValue) => {
        return this.state.allUsersList.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };


    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    goTochatPage = (index) => {
        // console.log(index)
        this.setState({ page: 1, selectedIndex: index }, () => {
            this.scrollToBottom();
        })
        // More on change page
    }

    backToMainPage = () => {
        this.setState({ page: 0 })
        this.refs.inputBox.refresh()
    }


    openNewConversation() {
        // this.getAllUser()
        this.setState({ groupName: '', selectedUser: [], image: null })

        this.refs.addConversationModal.showModal()
    }


    // createConversation() {
    //     if ((this.state.selectedUser.length == 1 && this.state.addConversationStage == 0) || (this.state.addConversationStage == 1)) {
    //         let selectedUser = []
    //         this.state.selectedUser.forEach(user => {
    //             selectedUser.push({ id: user.value })
    //         });
    //         let body = { userParticipant: selectedUser, name: this.state.groupName, icon: this.state.image }
    //         console.log(body)
    //         console.log(Auth.getToken())

    //         this.ChatServices.CreateSYS_Conversation(body).then(res => {

    //             if (res.success) {
    //                 this.refs.addConversationModal.hideModal()
    //                 this.setState({ addConversationStage: 0 })
    //                 console.log(res)
    //             } else {
    //             }
    //         }).catch(err => console.log(err));
    //     } else {
    //         this.setState({ addConversationStage: 1 })
    //     }
    // }


    onDrop(files) {
        // const image = files[0]
        this.getBase64(files[0], res => {
            // console.log(res)
            this.setState({ image: res })

        })
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    render() {
        const dropzoneRef = React.createRef();

        return (
            <div className="chat pt-2" style={{ height: 'calc(100vh - 80px)', paddingLeft: '3%', paddingRight: '3%', direction: 'ltr' }}>
                <div className="row p-3 h-100">
                    <div className={"col-12 col-sm-6 col-lg-4 h-100 " + (this.state.page != 0 ? 'd-none d-sm-block' : 'd-block')}>
                        <div className="box h-100">

                            <div className="font-weight-bold text-center pt-1 pb-2 position-ralative " style={{ color: '#202020', borderBottom: '#f2f4f6 1px solid' }}>
                                <span>MESSENGER</span>
                                <div className="position-absolute" style={{ top: 12, left: 30 }}>
                                    <button className="flat-but" onClick={() => this.openNewConversation()}><i className="fa fa-plus "></i></button>
                                </div>
                            </div>

                            <div className="pb-3 pt-3" style={{ maxWidth: '100%', height: '100%', overflow: 'auto', }}>
                                {this.state.chatList.map((prop, index) => {
                                    return (
                                        <ChatListItem data={prop} goTochatPage={this.goTochatPage} selectedIndex={this.state.selectedIndex} index={index} />
                                    )
                                })}

                                <div style={{ float: "left", clear: "both", height: 20 }} />

                            </div>


                        </div>
                    </div>

                    <div className={"col-12 col-sm-6 col-lg-8 h-100 " + (this.state.page != 1 ? 'd-none d-sm-block' : 'd-block')}>
                        <div className="box h-100" style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>

                            <div className="font-weight-bold text-center pt-1 pb-2" style={{ color: '#202020', borderBottom: '#f2f4f6 1px solid' }}>
                                <span>Username</span>
                            </div>

                            <div onClick={() => this.backToMainPage()} className="d-block d-sm-none" style={{ position: 'absolute', left: 15, top: 15 }}>
                                <i className="fa fa-arrow-left"></i>
                            </div>

                            <div style={{ maxWidth: '100%', height: '100%', overflow: 'auto', flex: 1, padding: 20 }}>

                                {this.state.messageList.map((prop, index) => {
                                    let hasTop = false
                                    let hasBottom = false

                                    if (this.state.messageList[index - 1] && this.state.messageList[index - 1].sender == prop.sender) {
                                        hasTop = true
                                    }
                                    if (this.state.messageList[index + 1] && this.state.messageList[index + 1].sender == prop.sender) {
                                        hasBottom = true
                                    }
                                    return (
                                        <Message data={prop} users={this.state.users} hasBottom={hasBottom} hasTop={hasTop} myMessage={prop.sender == "me"} />
                                    )
                                })}

                                <div style={{ float: "left", clear: "both" }}
                                    ref={(el) => { this.messagesEnd = el; }}>
                                </div>


                            </div>
                            <InputBox ref="inputBox" />

                        </div>
                    </div>

                </div>


                <Modal className="lightiransansfont" ref={'addConversationModal'} maxWidth={350}>
                    {this.state.addConversationStage == 0 && (
                        <div className="p-2 pb-4 more-modal  w-100">
                            <div className="more-modal-header text-center">
                                <h2 className="title  mt-2 font-weight-bold mb-1">Start a Conversation</h2>
                                <h4 className="subtitle">Select people for this conversation</h4>
                            </div>

                            <FormGroup>
                                <Label htmlFor="selectedUser">
                                    Recipients
                        </Label>
                                {/* <AsyncSelect
                                    id="selectedUser"
                                    name="selectedUser"
                                    // isDisabled={setData && setData.type == 'reply'}
                                    // defaultValue={(setData && setData.type == 'reply') ? allUsersList.filter(item => item.value == setData.operatorId) : ''}
                                    components={animatedComponents}
                                    isMulti
                                    defaultOptions
                                    loadOptions={this.promiseOptions}
                                    onChange={e => this.setState({ selectedUser: e })}
                                /> */}
                            </FormGroup>

                            <div className="mt-3 d-flex justify-content-center w-100">
                                <button className="button ml-1 mr-1" onClick={() => this.refs.addConversationModal.hideModal()}>Cancel</button>
                                <button className="button" onClick={() => { this.createConversation() }} style={{ color: '#007aff' }}>START</button>
                            </div>
                        </div>
                    )}

                    {this.state.addConversationStage == 1 && (
                        <div className="p-2 pb-4 more-modal  w-100">
                            <div className="more-modal-header text-center">
                                <h2 className="title  mt-2 font-weight-bold mb-1">Start a Conversation</h2>
                                <h4 className="subtitle">Select group's name and image</h4>
                            </div>
                            <div className="d-inline-flex justify-content-center w-100 mt-4 mb-2">
                                <Dropzone
                                    ref={dropzoneRef}
                                    multiple={false}
                                    accept='image/jpeg'
                                    onDrop={this.onDrop.bind(this)}
                                    style={{ flex: 1, cursor: 'pointer' }}
                                >
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={this.state.image ? this.state.image : '/img/bg6.jpg'} style={{ width: 70, height: 70, borderRadius: '50%', objectFit: 'cover' }} />
                                        <p style={{ opacity: 1, fontSize: 13, marginTop: 6, color: '#007aff' }}>Change Image</p>
                                    </div>
                                </Dropzone>
                            </div>

                            <input className="w-100" placeholder="Group's name" onChange={e => this.setState({ groupName: e.target.value })} style={{ backgroundColor: '#f4f6f8', borderRadius: 4, height: 40, border: 'none', padding: '3px 15px 3px 15px' }} />


                            <div className="mt-3 d-flex justify-content-center w-100">
                                <button className="button ml-1 mr-1" onClick={() => this.setState({ addConversationStage: 0 })}>Back</button>
                                <button className="button" onClick={() => { this.createConversation(); }} style={{ color: '#007aff' }}>START</button>
                            </div>
                        </div>
                    )}
                </Modal>

            </div>
        )
    }
}

export default Chat