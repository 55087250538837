import { domain, headers } from './Variables'

const pagesData = {


    customers: {
        title: 'مشتریان',
        description: 'لیست مشتریان',
        fetchUrl: '/users/getAll',
        addFetch: '/users/add',
        removeUrl: '/users/remove',
        pageType: 'normalTable',
        settings: { fetchFilter: { status: 1 } },
        headers: [

            { title: 'نام و نام خانوادگی', type: 'person', key: 'name', showMain: false },
            { title: 'نام کاربری', type: 'text', key: 'username', showMain: false },
            { title: 'کلمه عبور', type: 'text', key: 'password', showMain: false },
            { title: 'شماره تماس', type: 'text', key: 'phone', showMain: true },
            { title: 'توضیحات', type: 'text', key: 'description', showMain: false },
            { title: 'ایمیل', type: 'text', key: 'email', showMain: false },
            { title: 'شهر', type: 'select', key: 'city', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
            { title: 'تصویر', key: 'image', type: 'file', options: { single: true }, showMain: false, fullCol: true },
            { title: 'وضعیت', key: 'status', type: 'select', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: 2 }] }, settings: { required: true }, showMain: true },
            { title: 'اشتراک', key: 'premium', type: 'select', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: 2 }] }, settings: { required: true }, showMain: true },
            { title: 'اسکان ها', key: 'settlements', type: 'datagrid', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: 2 }] }, settings: { required: true }, showMain: true },
            { title: 'موجودی', key: 'settlements', type: 'lookup', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: 2 }] }, settings: { required: true }, showMain: true },
            { title: 'کپن ها', key: 'coupons', type: 'datagrid', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: 2 }] }, settings: { required: true }, showMain: true },
            { title: 'وضعیت پرداخت', key: 'paymentStatus', type: 'lookup', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: 2 }] }, settings: { required: true }, showMain: true },
            { title: 'پرداخت ها', key: 'payments', type: 'datagrid', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: 2 }] }, settings: { required: true }, showMain: true },

            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }
        ],
    },


    invitations: {
        title: 'مدعوین',
        description: 'لیست مدعوین',
        fetchUrl: '/values/getAll',
        addFetch: '/values/add',
        removeUrl: '/values/remove',
        pageType: 'normalTable',
        // options: { customAdd: 'addestate' },
        headers: [
            // { title: 'نام', key: 'images', type: 'file', options: { single: true }, showMain: false, fullCol: true },
            { title: 'نام', key: 'name', type: 'text', settings: { required: true }, showMain: true },
            { title: 'نام خانوادگی', key: 'family', type: 'text', settings: { required: true }, showMain: true },
            { title: 'سمینار', type: 'select', key: 'seminar', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'photosCategories' } }, settings: { required: true }, showMain: false },
            { title: 'شماره تماس', key: 'phone', type: 'text', settings: { required: true }, showMain: true },
            { title: 'ایمیل', key: 'email', type: 'email', showMain: false },
            { title: 'بازاریاب', type: 'select', key: 'marketer', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'photosCategories' } }, settings: { required: true }, showMain: false },
            { title: 'وضعیت مشارکت', key: 'status', type: 'select', options: { type: 'local', items: [{ title: "منتشر شده", value: 1 }, { title: 'در انتظار تایید', value: 0 }] }, settings: { required: true }, showMain: true },
            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }
        ]
    },


    settlements: {
        title: 'اسکان ها',
        description: 'لیست اسکان ها',
        fetchUrl: '/values/getAll',
        addFetch: '/values/add',
        removeUrl: '/values/remove',
        pageType: 'normalTable',
        // options: { customAdd: 'addestate' },
        headers: [
            // { title: 'نام', key: 'images', type: 'file', options: { single: true }, showMain: false, fullCol: true },
            { title: 'مشتری', type: 'select', key: 'customer', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'photosCategories' } }, settings: { required: true }, showMain: false },
            { title: 'هتل', type: 'select', key: 'hotel', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'photosCategories' } }, settings: { required: true }, showMain: false },
            { title: 'تاریخ شروع', key: 'startDate', type: 'text', settings: { required: true }, showMain: true },
            { title: 'تاریخ پایان', key: 'endDate', type: 'text', settings: { required: true }, showMain: true },
            { title: 'نوع پرداخت', key: 'status', type: 'select', options: { type: 'local', items: [{ title: "تخفیف", value: "0" }, { title: 'اسکان رایگان', value: "1" }] }, settings: { required: true }, showMain: true },

            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }
        ]
    },






    hotels: {
        title: 'هتل ها',
        description: 'لیست هتل ها',
        fetchUrl: '/users/getAll',
        addFetch: '/users/add',
        removeUrl: '/users/remove',
        pageType: 'normalTable',
        settings: { fetchFilter: { status: 1 } },
        headers: [
            { title: 'تصویر', key: 'image', type: 'file', options: { single: true }, showMain: false, fullCol: true },
            { title: 'نام', type: 'text', key: 'name', showMain: false },
            { title: 'تخفیف', type: 'text', key: 'discount', showMain: false },
            { title: 'شماره تماس', type: 'text', key: 'phone', showMain: true },
            { title: 'آدرس', type: 'text', key: 'address', showMain: true },
            { title: 'توضیحات', type: 'text', key: 'description', showMain: false },
            { title: 'شهر', type: 'select', key: 'city', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
            { title: 'وضعیت', key: 'status', type: 'select', options: { type: 'local', items: [{ title: "فعال", value: "0" }, { title: 'غیر فعال', value: "1" }] }, settings: { required: true }, showMain: true },
            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }
        ],
    },

    coupons: {
        title: 'کپن ها',
        description: 'لیست کپن ها',
        fetchUrl: '/values/getAll',
        addFetch: '/values/add',
        removeUrl: '/values/remove',
        pageType: 'normalTable',
        headers: [
            { title: 'نوع', key: 'type', type: 'select', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
            { title: 'تاریخ شروع', key: 'title', type: 'text', settings: { required: true }, showMain: true },
            { title: 'تاریخ پایان', key: 'decription', type: 'text', settings: { required: true }, showMain: true },
            { title: 'مشتری', type: 'select', key: 'customer', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'tutosCategories' } }, settings: { required: true }, showMain: false },
            { title: 'وضیعیت', type: 'select', key: 'customer', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'tutosCategories' } }, settings: { required: true }, showMain: false },

            // { title: 'قیمت', key: 'price', type: 'integer', showMain: false },
            // { title: 'فایل', key: 'file', type: 'file', options: { single: true }, showMain: false, fullCol: true },
            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }],
    },



    pretypes: {
        title: 'انواع اشراک',
        description: 'لیست انواع اشتراک',
        fetchUrl: '/values/getAll',
        addFetch: '/values/add',
        removeUrl: '/values/remove',
        pageType: 'normalTable',
        headers: [
            { title: 'نام', type: 'text', key: 'name', showMain: true },
            { title: 'میزان تخفیف', type: 'integer', key: 'discount', showMain: true },
            { title: 'توضیحات', type: 'text', key: 'description', showMain: true },
            // { title: 'تصویر', key: 'image', type: 'file', options: { single: true }, showMain: false, fullCol: true },
            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }],
    },

    cities: {
        title: 'شهرها',
        description: 'لیست شهرها',
        fetchUrl: '/values/getAll',
        addFetch: '/values/add',
        removeUrl: '/values/remove',
        pageType: 'normalTable',
        headers: [
            { title: 'نام', type: 'text', key: 'name', showMain: true },
            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }],
    },



    marketers: {
        title: 'بازاریابان',
        description: 'لیست بازاریان',
        fetchUrl: '/values/getAll',
        addFetch: '/values/add',
        removeUrl: '/values/remove',
        pageType: 'normalTable',
        headers: [
            { title: 'نام و نام خانوادگی', type: 'person', key: 'name', showMain: false },
            { title: 'نام کاربری', type: 'text', key: 'username', showMain: false },
            { title: 'کلمه عبور', type: 'text', key: 'password', showMain: false },
            { title: 'شماره تماس', type: 'text', key: 'phone', showMain: true },
            { title: 'توضیحات', type: 'text', key: 'description', showMain: false },
            { title: 'ایمیل', type: 'text', key: 'email', showMain: false },
            { title: 'شهر', type: 'select', key: 'city', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
            { title: 'تصویر', key: 'image', type: 'file', options: { single: true }, showMain: false, fullCol: true },
            { title: 'وضعیت', key: 'status', type: 'select', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: "2" }] }, settings: { required: true }, showMain: true },
            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }],
    },



    seminars: {
        title: 'سمینارها',
        description: 'لیست سمینارها',
        fetchUrl: '/values/getAll',
        addFetch: '/values/add',
        removeUrl: '/values/remove',
        pageType: 'normalTable',
        headers: [
            { title: 'نام سمینار', type: 'text', key: 'name', showMain: false },
            { title: 'تاریخ برگزاری', type: 'date', key: 'name', showMain: false },

            // { title: 'نام کاربری', type: 'text', key: 'username', showMain: false },
            // { title: 'کلمه عبور', type: 'text', key: 'password', showMain: false },
            // { title: 'شماره تماس', type: 'text', key: 'phone', showMain: true },
            { title: 'توضیحات', type: 'text', key: 'description', showMain: false },
            // { title: 'ایمیل', type: 'text', key: 'email', showMain: false },
            { title: 'شهر', type: 'select', key: 'city', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
            // { title: 'تصویر', key: 'image', type: 'file', options: { single: true }, showMain: false, fullCol: true },
            { title: 'وضعیت', key: 'status', type: 'select', options: { type: 'local', items: [{ title: "فعال", value: "0" }, { title: 'غیر فعال', value: "1" }, { title: 'همکار', value: "2" }] }, settings: { required: true }, showMain: true },
            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }],
    },



    payments: {
        title: 'پرداخت ها',
        description: 'لیست پرداخت ها',
        fetchUrl: '/values/getAll',
        addFetch: '/values/add',
        removeUrl: '/values/remove',
        pageType: 'normalTable',
        // options: { customAdd: 'addestate' },
        headers: [
            { title: 'روش پرداخت', key: 'method', type: 'select', options: { type: 'local', items: [{ title: "آنلاین", value: '0' }, { title: "نقدی", value: '1' }, { title: 'قسطی', value: '2' }] }, settings: { required: true }, showMain: true },
            { title: 'وضعیت', key: 'status', type: 'select', options: { type: 'local', items: [{ title: "در انتظار", value: '0' }, { title: 'انجام شده', value: '1' }, { title: 'لغو شده', value: '1' }] }, settings: { required: true }, showMain: true },
            { title: 'تاریخ سر رسید', type: 'text', key: 'date', showMain: true },
            { title: 'توضیحات', key: 'description', type: 'text', showMain: false, },
            { title: 'مشتری', type: 'text', key: 'customer', showMain: false },
        ]
    },

    users: {
        title: 'کاربران',
        description: 'تنظیمات بخش کاربران',
        fetchUrl: '/users/getAll',
        addFetch: '/users/add',
        removeUrl: '/users/remove',
        pageType: 'normalTable',
        headers: [
            { title: 'نام و نام خانوادگی', type: 'person', key: 'name', showMain: false },
            { title: 'نام کاربری', type: 'text', key: 'username', showMain: false },
            { title: 'کلمه عبور', type: 'text', key: 'password', showMain: false },
            { title: 'شماره تماس', type: 'text', key: 'phone', showMain: true },
            { title: 'توضیحات', type: 'text', key: 'description', showMain: false },
            { title: 'ایمیل', type: 'text', key: 'email', showMain: false },
            { title: 'شهر', type: 'select', key: 'city', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
            { title: 'تصویر', key: 'image', type: 'file', options: { single: true }, showMain: false, fullCol: true },
            { title: 'وضعیت', key: 'status', type: 'select', options: { type: 'local', items: [{ title: "کاربر عادی", value: "0" }, { title: 'در انتظار تایید همکاری', value: "1" }, { title: 'همکار', value: "2" }] }, settings: { required: true }, showMain: true },
            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }],
    },

    employees: {
        title: 'کارمندان',
        description: 'تنظیمات بخش کارمندان',
        fetchUrl: '/employee/getAll',
        addFetch: '/employee/add',
        removeUrl: '/employee/remove',
        pageType: 'normalTable',
        headers: [
            { title: 'نام و نام خانوادگی', type: 'person', key: 'name', showMain: true },
            { title: 'نام کاربری', type: 'text', key: 'username', showMain: true },
            { title: 'کلمه عبور', type: 'text', key: 'password', showMain: false },
            { title: 'شماره تماس', type: 'text', key: 'phone', showMain: false },
            { title: 'ایمیل', type: 'text', key: 'email', showMain: false },
            { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
            { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }],
    },

    // blogs: {
    //     title: 'بلاگ ها',
    //     description: 'بلاگ های سایت',
    //     fetchUrl: '/values/getAll',
    //     addFetch: '/values/add',
    //     removeUrl: '/values/remove',
    //     pageType: 'normalTable',

    //     headers: [
    //     { title: 'عنوان', type: 'text', key: 'title', showMain: true },
    //     { title: 'تصاویر', key: 'images', type: 'file', showMain: false, fullCol: false },
    //     { title: 'متن', type: 'editor', key: 'body', showMain: false },
    //     { title: 'اصلاح', type: 'button', function: 'edit', options: { color: "#78b2d0" }, showMain: true },
    //     { title: 'حذف', type: 'button', function: 'remove', options: { color: "#dd8aa4" }, showMain: true }

    //     ]
    // },

}



const modules = [

    { type: 'basic', page: 'invitations', title: 'مدعوین', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'customers', title: 'مشتریان', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'hotels', title: 'هتل ها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'marketers', title: 'بازاریاب ها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'premiums', title: 'اشتراک ها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'settlements', title: 'اسکان ها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'wallets', title: 'کیف پول ها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'coupons', title: 'کپن ها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'pretypes', title: 'انواع اشتراک', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'seminars', title: 'سمینارها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },

    // { type: 'basic', page: 'users', title: 'کاربران', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    { type: 'basic', page: 'payments', title: 'پرداخت ها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },
    // { type: 'basic', page: 'counterparts', title: 'درخواست همکاری', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: '/images/food.png' },
    // { type: 'basic', page: 'phtosCategories', title: 'دسته بندی تصاویر', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: '/images/food.png' },
    { type: 'basic', page: 'covers', title: 'تصاویر کارور', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },


    // { type: 'basic', page: 'payments', title: 'پرداخت ها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: '/images/mainIcons/payment.png' },
    // { type: 'basic', page: 'transactions', title: 'نقل و انتقالات', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: '/images/mainIcons/payment.png' },
    // { type: 'basic', page: 'covers', title: 'کاورها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: '/images/mainIcons/tasks.png' },

    // { type: 'basic', page: 'cities', title: 'شهرها', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: '/images/mainIcons/settings.png' },
    { type: 'basic', page: 'employees', title: 'کاربران پنل', description: 'توضیحات این ماژول در اینجا آورده میشود که میتواند دو الی سه خط باشد', image: 'https://image.flaticon.com/icons/svg/861/861687.svg' },

]


export { pagesData, modules };
