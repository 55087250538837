import AddEstate from "./views/AddEstate";
import Dashboard from "./views/Dashboard.jsx";
import Page from "./views/Page";
import FormBuilder from "./views/FormBuilder";
import Settings from "./views/Settings";
import Groups from "./views/Groups";
import Users from "./views/Users";
import Chat from "./views/Chat";
import Modules from "./views/Modules";
import Config from "./views/Config";


var dashRoutes = [

  {
    path: "/page/:name",
    name: "صفحه",
    icon: "nc-icon nc-bank",
    component: Page,
    public: true,
    extra: true,

  },

  {
    path: "/dashboard",
    name: "داشبورد",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    public: true
  },


  {
    path: "/settings",
    name: "تنظیمات",
    icon: "nc-icon nc-bank",
    component: Settings,
    public: true
  },


  {
    path: "/modules",
    name: "ماژول ها",
    icon: "nc-icon nc-bank",
    component: Modules,
    public: true
  },



  {
    path: "/groups",
    name: "گروه ها",
    icon: "nc-icon nc-bank",
    component: Groups,
    public: true
  },

  {
    path: "/users",
    name: "گروه ها",
    icon: "nc-icon nc-bank",
    component: Users,
    public: true
  },


  {
    path: "/chat",
    name: "چت",
    icon: "nc-icon nc-bank",
    component: Chat,
    public: true
  },



  {
    path: "/addestate",
    name: "افزودن آگهی",
    icon: "nc-icon nc-bank",
    component: AddEstate,
    public: true
  },


  {
    path: "/formbuilder/:id",
    name: "فرم ساز",
    icon: "nc-icon nc-bank",
    component: FormBuilder,
    public: true
  },



  {
    path: "/config",
    name: "فرم ساز",
    icon: "nc-icon nc-bank",
    component: Config,
    public: true
  },


  { redirect: true, path: "/", pathTo: "/dashboard", name: "dashboard" },
];
export default dashRoutes;
