import React from "react";
// import PersonCell from '../Table/Person'
import TextCell from '../Inputs/Text'
import PriceCell from '../Inputs/Price'

import NumberCell from '../Inputs/Integer'
import DateCell from '../Inputs/Date'
// import ProductCell from './Product'

// import ButtonCell from '../Table/Button'
// import StatusCell from '../Table/Status'
import SelectCell from '../Table/Select'
import SelectInput from '../Inputs/Select'
import ImageRowCell from '../Inputs/ImageRow'
import CheckInput from '../Inputs/Switch'

import moment from 'jalali-moment'
import Modal from '../Modal'
import { withRouter } from 'react-router-dom'
import { contentUrl } from "../../Variables";
import Loader from 'react-loader-spinner'

class RegularTables extends React.Component {

    constructor(prop) {
        super(prop)
        this.state = {
            tabs: ['امروز', 'این هفته', 'این ماه', 'ماه بعد'],
            activeTab: 0,
            items: []

        }
    }


    componentWillMount() {

        // console.log(this.props.title)

        if (this.props.title && this.props.title.length > 0) {
            this.setState({ items: this.props.title })
        }
    }

    tabClicked = (index) => {
        this.setState({ activeTab: index })
    }



    renderHeaders = () => {

        return (this.props.info.headers.map((prop, key) => {
            // console.log(prop)
            //   if (prop.information.showMain) {
            return (
                <th key={key} style={{ borderLeftWidth: 1, borderLeftColor: '#77889920', borderLeftStyle: 'solid', padding: 0, paddingBottom: 7, paddingTop: 15, paddingBottom: 5, backgroundColor: 'transparent' }}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <p className={"mediumiransansfont " + (prop.information.required ? 'required' : '')} style={{ paddingRight: key == 0 ? 0 : 0, margin: 7, marginTop: 0, alignSelf: 'stretch', flex: 1, fontSize: 12, color: '#345', textAlign: key == 0 ? 'center' : 'center' }}>{prop.information.label}</p>
                    </div>
                </th>
            );
            //   }
        }))
    }


    renderBody = () => {
        return (this.state.items && this.state.items.map((prop, key) => {
            return (
                this.renderRow(key, prop)
            )
        }))
    }

    checkKey(values, key) {
        if (key && values) {
            let value = null
            if (key.includes('.')) {
                let split = key.split('.')
                if (values[split[0]])
                    value = values[split[0]][split[1]]
            } else {
                value = values[key]
            }
            if (value == null)
                value = ''

            return value
        }
    }



    getObjectLevel(value, param, cb) {
        if (param) {
            if (param.includes('.')) {
                if (value && value[param.split('.')[0]]) {
                    this.getObjectLevel(value[param.split('.')[0]], param.split('.')[1], cb)

                } else {
                    cb('')
                }
            } else {
                cb(value[param])
            }
        } else {
            cb({})
        }
    }


    getPageHelper() {
        let helper = null
        if (this.props.page) {
            if (this.props.page.helperKey && this.props.page.helperKey != '') {
                helper = this.props.page.helperKey
            }
        }

        return helper
    }



    renderRow = (index, prop) => {
        // console.log(prop)
        return (
            <tr key={index} style={{ justifyContent: 'center', alignItems: 'center', borderTopStyle: 'solid', borderWidth: 0, borderColor: '#f9f9f9', paddingRight: 0, height: 40 }} >

                <th style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: 12 }}>
                    <div onClick={() => this.removeItem(index)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: '#dd8aa420', borderRadius: 20, padding: 4, justifyContent: 'center', width: 25, height: 25 }}>
                        <p className="mediumiransansfont" style={{ alignSelf: 'stretch', flex: 1, fontSize: 14, color: '#dd8aa4', textAlign: 'center' }}><i className="fa fa-times"></i></p>
                    </div>
                </th>

                {this.props.info.headers.map((headerProp, headerIndex) => {



                    let values = prop

                    // if (!this.getPageHelper()) {
                    //     values = prop
                    // } else {
                    //     values = prop[this.getPageHelper()]
                    // }

                    let value
                    if (values) {
                        value = this.checkKey(values, headerProp.key)
                    } else {
                        value = ' '
                    }

                    // console.log(value)

                    // let value = this.checkKey(prop, headerProp.key)
                    let onChange = this.changeValue

                    // if (headerProp.type == 'selectApiCell') {

                    //     // if(headerProp.key == "vendor"){
                    //     //     console.log('!@#I$')
                    //     //     console.log(prop)
                    //     // }

                    //     this.getObjectLevel(value, '_id', (value1) => {
                    //         value = value1
                    //         // console.log(value1)

                    //     })
                    // }

                    let myDafault

                    if (headerProp.information.Instruction) {
                        let params = headerProp.information.Instruction.split('*')
                        myDafault = prop[params[0]][params[1]]

                    }

                    // if (headerProp.type == 'dateCell') {
                    //     value = (value && value != '') ? moment(value).locale('fa').format('jYYYY - jMMMM - jDD') : ''
                    // }

                    // console.log("INFORMATION")
                    // console.log(headerProp.information)

                    return (
                        <td key={headerIndex} className={this.props.className} style={{ minWidth: 140, alignItems: 'center', padding: 0, borderRightColor: '#fff', paddingRight: 15, borderRightWidth: 0, borderBottomColor: '#fff', borderBottomWidth: 0, borderBottomStyle: 'solid', borderRightStyle: 'solid', borderTopStyle: 'none', borderRadius: 0, overflow: 'visible', paddingRight: headerIndex == 0 ? 5 : 5, paddingLeft: headerIndex == this.props.info.headers.length - 1 ? 10 : 0 }}  >

                            {/* {headerProp.type == 'person' && (
                                <PersonCell title={value} />
                            )} */}

                            {headerProp.type == 'textCell' && (
                                <TextCell onChange={onChange} headerkey={headerProp.key} info={headerProp.information} title={value} extra={index} />
                            )}

                            {headerProp.type == 'switchCell' && (
                                <CheckInput info={headerProp.information} title={value} options={headerProp.options} onChange={onChange} headerkey={headerProp.key} extra={index} />
                            )}

                            {headerProp.type == 'priceCell' && (
                                <PriceCell onChange={onChange} headerkey={headerProp.key} info={headerProp.information} title={value} extra={index} myDafault={myDafault} />
                            )}

                            {headerProp.type == 'numberCell' && (
                                <NumberCell onChange={onChange} headerkey={headerProp.key} info={headerProp.information} title={value} extra={index} myDafault={myDafault} />
                            )}

                            {/* {headerProp.type == 'productCell' && (
                                <ProductCell type={'api'} onChange={onChange} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} extra={index} />
                            )} */}

                            {headerProp.type == 'dateCell' && (
                                <DateCell onChange={onChange} headerkey={headerProp.key} info={headerProp.information} title={value} extra={index} />
                            )}


                            {headerProp.type == 'selectApiCell' && (
                                <SelectInput type={'api'} onChange={onChange} headerkey={headerProp.key} info={headerProp.information} title={value} options={headerProp.options} setFull={this.props.setFull} extra={index} />
                            )}



                            {(headerProp.type == 'imageCell') && (
                                // <div className="text-center d-flex justify-content-center">
                                //     {typeof value == "object" && value.map((image, index) => {
                                //         return (
                                //             <img src={contentUrl + image} style={{ width: 30, height: 30, borderRadius: 20, objectFit: 'cover', marginLeft: 2 }} />
                                //         )
                                //     })
                                //     }

                                //     {typeof value != "object" && (
                                //         <img src={contentUrl + value} style={{ width: 30, height: 30, borderRadius: 20, objectFit: 'cover' }} />
                                //     )}

                                // </div>

                                <ImageRowCell onChange={onChange} changeFiles={this.changeFiles} headerkey={headerProp.key} info={headerProp.information} title={value} extra={index} />

                            )}

                            {/* {headerProp.type == 'switchCell' && (
                                <TextCell info={headerProp.information} title={value} onChange={onChange} headerkey={headerProp.key} />
                            )} */}

                            {/* {headerProp.type == 'button' && (
                                <ButtonCell info={headerProp.information} title={value} function={() => this.butAction(index, headerProp.function)} options={headerProp.options ? headerProp.options : {}} />
                            )} */}

                            {/* {headerProp.type == 'status' && (
                                <StatusCell info={headerProp.information} title={value} />
                            )} */}

                            {headerProp.type == 'selectCell' && (
                                <SelectCell type={'local'} info={headerProp.information} title={value} options={headerProp.options} onChange={onChange} headerkey={headerProp.key} />
                            )}






                        </td>
                    )
                    // }
                })}

            </tr>
        )
    }

    changeValue = (key, value, index) => {
        console.log(index)
        console.log(value)
        let items = [...this.state.items]
        if (!items) {
            items = []
        }
        if (!items[index]) {
            items[index] = ''
        }

        items[index][key] = value
        // console.log(key)
        console.log(items)

        this.setState({ items })
        this.props.onChange(this.props.headerkey, items)
    }

    changeFiles = (files, key, index, type) => {

        // let items = [...this.state.items]

        // items[index]["**files"] = value

        // if (!items["**files"]) {
        //     items["**files"] = {}
        // }

        // files.index = index

        // items["**files"][key + "*" + index] = files

        // this.setState({ items })

        this.props.onChange('**files', { [this.props.headerkey + '.' + key + "*" + index]: files })


        // console.log(items)

        // this.props.onChange(this.props.headerkey, items)

        // console.log(this.state.currentItem)
    }


    setFull = (key, values) => {
        this.props.setFull(key, values)
    }

    removeItem(index) {
        let items = [...this.state.items]
        items.splice(index, 1)
        this.setState({ items })
        this.props.onChange(this.props.headerkey, items)
    }

    addNewItem() {
        let items = [...this.state.items]
        items.push(this.makeNewItem())
        // console.log(items)
        this.setState({ items })
        this.props.onChange(this.props.headerkey, items)
    }


    makeNewItem = () => {
        let item = {}
        this.props.info.headers.forEach(header => {
            if (header.information.defaultValue) {
                item[header.key] = header.information.defaultValue
            } else {
                if (header.type == 'multiSelectCell') {
                    item[header.key] = []
                } else {
                    item[header.key] = ''
                }
            }

        });
        return item
    }

    render() {
        return (
            <div style={{ padding: '0px 20px 0px 20px' }}>

                <div className="row">


                    <div className="col-12 " style={{ marginTop: 0, overflow: 'auto', padding: 0, paddingBottom: 0, borderRadius: 8 }}>
                        <div className="no-scrollbar" style={{ marginTop: 0, padding: 0, paddingBottom: 10, borderRadius: 8 }}>

                            <div style={{ overflowX: 'visible', display: 'flex', direction: 'rtl', padding: 0 }}>

                                <div className={'lightiransansfont'} style={{ flex: 1, backgroundColor: '#fafafa' }} >
                                    <table className="table" style={{ borderColor: '#f9f9f9', borderStyle: 'solid', borderWidth: 0 }}>

                                        <thead style={{ borderStyle: 'none' }}>
                                            <tr style={{ borderStyle: 'none', backgroundColor: '#f0f2f4' }}>

                                                <th style={{ borderRightWidth: 0, borderRightColor: '#ffffff20', borderRightStyle: 'solid', padding: 0, paddingBottom: 0, paddingTop: 15, paddingBottom: 5 }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {/* <p className="mediumiransansfont" style={{ paddingRight: 0, margin: 7, marginTop: 0, alignSelf: 'stretch', flex: 1, fontSize: 12, color: '#3b3a40', textAlign: 'center' }}>حذف</p> */}
                                                    </div>
                                                </th>


                                                {this.renderHeaders()}



                                            </tr>
                                            <tr width={'100%'} style={{ borderStyle: 'none', }}>
                                                <td style={{ height: 10, borderStyle: 'none' }}></td>
                                            </tr>

                                        </thead>
                                        <tbody >

                                            {this.renderBody()}

                                            <tr>
                                                <td colSpan="100%" style={{ borderStyle: 'none', padding: 0 }}>
                                                    <div style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', borderTopStyle: 'solid', borderWidth: 0, borderColor: '#fff', paddingRight: 0, borderRadius: '0px 0px 10px 10px', minHeight: 10 }} >
                                                        <div style={{ width: '100%' }}>
                                                            <div className="d-inline-flex">
                                                                <p onClick={() => this.addNewItem()} style={{ color: '#fff', marginTop: 10, backgroundColor: '#202020', padding: '7px 25px 7px 25px', borderRadius: 20, cursor: 'pointer', fontSize: 12, marginBottom: 10 }}><i className="fa fa-plus ml-1"></i> افزودن</p>
                                                            </div>
                                                        </div>
                                                        {/* {(!this.props.isLoadingData && this.props.title.length == 0) && (
                            <div className="text-center mt-2 mb-3 " style={{ paddingBottom: 33 }}>
                              <p style={{ fontSize: 12, color: '#789' }}>موردی برای نمایش یافت نشد</p>
                            </div>
                          )} */}

                                                        {this.props.isLoadingData && (
                                                            <div className="text-center mt-2 mb-2 pb-3">
                                                                <Loader
                                                                    type="ThreeDots"
                                                                    color="#789"
                                                                    height="35"
                                                                    width="35"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default withRouter(RegularTables);
