import React from "react";
import Modal from "../Modal.jsx";
import Loader from 'react-loader-spinner'
// import removeIcon from '../../assets/img/remove.png'
import { colors, settings } from '../../Variables'


class RemoveModal extends React.Component {
  state = {
    isLoadingRemove: false,
    id: null,
    index:null
  }

  showModal = (id,index) => {
    this.setState({id,index})
    this.refs.removeModal.showModal()
  }

  hideModal = () => {
    this.refs.removeModal.hideModal()
  }


  render() {
    return (
      <Modal cancelModal={this.props.cancelModal}  className="lightiransansfont" ref={'removeModal'} maxWidth={350} style={{ alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>

        <div style={{ flexDirection: 'column', display: 'flex', direction: settings.direction, justifyContent: 'center', alignItems: 'center' }}>

          <img src={'/images/remove.png'} style={{ width: 70, height: 70 }} />

          <p style={{ textAlign: 'center', fontSize: 13, marginTop: 20 }}>آیا از حذف اطمینان دارید؟ </p>
          {/* {this.state.selectedIndex != null && this.state.selectedIndex < this.state.data.length && (<p style={{ fontSize: 10, color: '#000', opacity: 0.2, marginTop: 3, textAlign: 'center' }}>USERNAME: {this.state.data[this.state.selectedIndex].name.toUpperCase()}</p>)} */}
        </div>


        {!this.state.isLoadingRemove && (
          <div style={{ marginTop: 10, flexDirection: 'row', display: 'flex', direction: settings.direction, justifyContent: 'center' }}>
            <div onClick={() => {this.props.removeFunction(this.state.id,this.state.index,this);this.setState({isLoadingRemove:true})}} style={{ margin: 5, backgroundColor: colors.sharpRed, color: colors.primaryText, padding: 5,cursor:'pointer', borderRadius: 20, paddingLeft: 10, paddingRight: 10, justifyContent: 'center', alignItems: 'center', width: 60 }}><p style={{ margin: 0, padding: 0, lineHeight: 1, textAlign: 'center' }}>حذف</p></div>
            <div onClick={() => this.hideModal()} style={{ margin: 5, padding: 5, borderRadius: 20, paddingLeft: 10, paddingRight: 10, justifyContent: 'center', alignItems: 'center', backgroundColor: colors.inactive, width: 60,cursor:'pointer' }}><p style={{ margin: 0, padding: 0, lineHeight: 1, textAlign: 'center' }}>خیر</p></div>
          </div>
        )}
        <div style={{display:'flex',justifyContent:'center'}}>
        {this.state.isLoadingRemove && (
          <Loader
            type="Puff"
            color="rgba(0,122,255,1)"
            height="40"
            width="40"
          />)}
          </div>

      </Modal>
    );
  }
}



export default RemoveModal;
