import React from "react";
import { withRouter } from 'react-router-dom'
// import Modal from "../components/Modal.jsx";
// import { Link, Route } from 'react-router-dom'
import { contentUrl } from '../../Variables'
// import { modules, pagesData } from '../PagesData'
// import Container from '../components/Container'

// var distanceInWordsToNow = require('date-fns/distance_in_words_to_now')


class Dashboard extends React.Component {

  state = {
    hovered: false
  }


  render() {
    let prop = this.props.data
    return (




      <div className={'mt-2 col-6 col-md-4 col-lg-3 d-flex '} style={{ margin: 0, padding: 10, justifyContent: 'center' }} >
        <div onMouseOver={() => this.setState({ hovered: true })} onMouseLeave={() => this.setState({ hovered: false })} style={{ cursor: 'pointer', backgroundColor: '#fff', padding: 0, overflow: 'hidden', borderRadius: 15, boxShadow: '0px 0px 30px rgba(50,60,70,0.1)', width: '100%', position: 'relative' }} onClick={() => { if (!this.props.edit) { this.props.history.push(this.props.data.customPage ? this.props.data.customPage : '/page/' + prop._id) } else { this.props.history.push('/formbuilder/' + prop._id) } }} >

          <img src={prop.image ? contentUrl + prop.image : '/images/cover14.jpeg'} style={{ width: '100%', maxHeight: 150, objectFit: 'cover' }} />


          <div style={{ position: 'absolute', top: 15, left: 15, display: 'flex' }}>
            {/* <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#e9eff2', color: '#789', borderRadius: 20, fontSize: 10, textAlign: 'center', direction: 'ltr', fontFamily: 'arial', fontWeight: 'bold', height: 25, minWidth: 25, paddingRight: 10, paddingLeft: 10, marginRight: 3, marginLeft: 3 }}>3 OPTIONS</p> */}
            {/* <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ee5050', color: '#fff', borderRadius: 20, fontSize: 13, textAlign: 'center', direction: 'ltr', fontFamily: 'arial', fontWeight: 'bold', height: 25, minWidth: 25, paddingRight: 10, paddingLeft: 10 }}>25</p> */}
          </div>
          <div className="" style={{ padding: '15px 25px 25px 25px', width: '100%' }}>
            {/* <div className={'text-center'}>
              <img src={prop.image ? prop.image : '/images/chat-3.svg'} style={{transition:'all 0.5s', filter: this.state.hovered ? 'grayscale(10%)' : 'grayscale(80%)', width: '100%', marginTop: 0, maxWidth: 35 }} />
            </div> */}
            {/* <div className={'text-left mr-2'} style={{ flexDirection: 'column', flex: 1, width: '100%' }}> */}
            {/* <div style={{ flex: 1, paddingLeft: 5, paddingRight: 5, paddingBottom: 10 }}> */}

            <div className="" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'flex-end' }}>
              <p className="boldiransansfont mb-0" style={{ fontSize: 20, marginTop: 0, color: '#202020', fontWeight: 'bold', lineHeight: 1 }}>{prop.title}</p>
              <img src={'/images/chat-3.svg'} style={{ transition: 'all 0.5s', filter: this.state.hovered ? 'grayscale(10%)' : 'grayscale(80%)', marginTop: 0, width: 30 }} />
            </div>

            <p style={{ fontSize: 11, marginTop: 10, color: '#789', }}>{prop.description}</p>

            {/* 005a8e 6E57CF*/}
            {/* <p style={{ fontSize: 11, marginTop: 7, color: '#55595cee', }}>{prop.description}</p> */}
            {/* <p style={{ fontSize: 11, marginTop: 7, color: '#abc', }}>Table Page - Comments</p> */}

            {/* </div> */}
            {/* <p style={{ padding: 5, backgroundColor: '#e9eff2', color: '#789', borderRadius: 20, fontSize: 10, textAlign: 'center', marginTop: 8, direction: 'ltr',fontFamily:'arial',fontWeight:'bold' }}>3 Options</p> */}
            {/* </div> */}
          </div>
        </div>
      </div>



    );
  }
}

export default withRouter(Dashboard)
