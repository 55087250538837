import React from "react";

class SubmitButton extends React.Component {
    render() {
        return (
            <div onClick={()=>this.props.onClick()} className={this.props.class} style={{cursor:'pointer', height:40,display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:this.props.background,width:'100%',borderRadius:6,color:this.props.color}}>
              <p className="mediumiransansfont">{this.props.title}</p>
            </div>
        );
    }
}



export default SubmitButton;
