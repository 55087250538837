import { content } from './Variables'

function setObject(data, key, value, append) {



    if (!data) data = {}
    if (key.includes('.')) {
        let pList = key.split('.');
        let newKey = [...pList]
        newKey.splice(0, 1)
        newKey = newKey.join('.')
        data[pList[0]] = setObject(data[pList[0]], newKey, value, append)
        return data

    } else {
        if (append) {
            data[key] = data[key] ? data[key] : []
            if (typeof value == 'object') {
                if (Array.isArray(value)) {
                    data[key] = [...data[key], ...value]
                } else {

                    if (!data[key]) {
                        data[key] = {}
                    }
                    data[key] = {...data[key] }

                    for (const [ckey, cvalue] of Object.entries(value)) {
                        data[key][ckey] = cvalue
                    }
                }
            } else {
                data[key] = [...data[key], value]
            }
        } else {
            data[key] = value
        }
        return data
    }

}

function getObject(data, key) {

    if (data && key) {
        if (key.includes('.')) {
            let pList = key.split('.');
            let newKey = [...pList]
            newKey.splice(0, 1).join('.')
            newKey = newKey.join('.')
            return getObject(data[pList[0]], newKey)

        } else {
            return data[key]
        }
    } else {
        return null
    }

}



function imageAddress(image, type, prefered) {

    let imageInfo = {
        mini: { address: 'images/', defaultImg: '/images/male.png' },

    }

    let prefixAddress = 'images/'
    if (imageInfo[type]) {
        prefixAddress = imageInfo[type].address
    }

    if (image && image != '') {

        let imageName = ''
        if (prefered && image.sizes && image.sizes[prefered]) {
            imageName = image.sizes[prefered].address
        } else {
            if (typeof image === "object" && !Array.isArray(image)) {
                // console.log(image.address)
                imageName = image.address
            } else {
                imageName = image
            }
        }

        let final = content + prefixAddress + imageName
        return final
    } else {
        return imageInfo[type].defaultImg
    }

}


export { setObject, getObject, imageAddress }