import React from "react";
import { domain, headers, colors, settings, contentUrl } from '../Variables'
// import { object } from "prop-types";
import Loader from 'react-loader-spinner'
import DragDrop from '../components/DragDrop'
// import StageBuilder from '../components/StageBuilder'
import Dropzone from 'react-dropzone'
import Cropper from 'react-cropper';
import Button from "../components/CustomButton";
import Modal from "../components/Modal.jsx";
import RemoveModal from '../components/Modals/RemoveModal'


class FormBuilder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stage: 0,
            selectedIndex: null,
            itemsList: [],
            isLoading: false,
            helperKey: 'values',
            apiRoute: '',
            addUrl: '/values/submitform',
            editUrl: '/values/submitform',
            removeUrl: '/values/remove',
            fetchUrl: '/values/getAllWithData'

        }
    }


    componentWillMount() {
        let id = this.props.match.params.id
        if (id && id != 'add') {
            this.loadDatabase(id)
        }
    }


    loadDatabase(id) {
        fetch(domain + '/modules/getOne', {
            method: 'POST',
            body: JSON.stringify({ id: id }),
            headers: headers
        })

            .then(response => response.json()).then(data => {
                if (data.status == 200) {

                    console.log(data)
                    document.title = settings.siteName + ' ' + data.info.title
                    this.setState({ _id: data.info._id, name: data.info.title, orgImage: data.info.image, image: data.info.image, file: data.info.image, page: data.info.page, description: data.info.description, applet: data.info.applet, itemsList: data.info.headers, helperKey: data.info.helperKey, apiRoute: data.info.apiRoute, fetchUrl: data.info.fetchUrl, addUrl: data.info.addUrl, editUrl: data.info.editUrl, removeUrl: data.info.removeUrl, appletType: data.info.appletType }, () => {

                    })
                }

            }).catch(error => this.setState({ error, isLoading: false }));
    }



    submitForm() {

        var formData = new FormData();

        if (this.state._id)
            formData.append('_id', this.state._id);

        if (this.state.file)
            formData.append('image', this.state.file);

        formData.append('page', this.state.page);
        formData.append('applet', this.state.applet);
        formData.append('appletType', this.state.appletType);

        formData.append('apiRoute', this.state.apiRoute);
        formData.append('helperKey', this.state.helperKey);

        formData.append('fetchUrl', this.state.fetchUrl);
        formData.append('addUrl', this.state.addUrl);
        formData.append('editUrl', this.state.editUrl);
        formData.append('removeUrl', this.state.removeUrl);

        formData.append('description', this.state.description);
        formData.append('title', this.state.name);
        formData.append('headers', JSON.stringify(this.state.itemsList));

        // let body = { "headers": this.state.itemsList, title: this.state.name, description: this.state.description, page: this.state.page }
        console.log(formData)

        this.setState({ isLoading: true })
        fetch(domain + '/modules/add', {
            method: 'POST',
            body: formData,
            headers: { 'Accept': 'application/json' }
        }).then(response => response.json()).then(data => {
            if (data.status == 200) {
                this.setState({ isLoading: false })
                this.props.history.push('/dashboard')
                // console.log(data)
            }

        }).catch(error => this.setState({ error, isLoading: false }));
    }



    changeHeaders = (headers) => {
        // this.setState({ itemsList: [] },()=>{
            // console.log(headers)
        this.setState({ itemsList: headers })

        // })

    }



    backToOrgImg() {
        this.setState({ image: this.state.orgImage, file: this.state.orgImage })
    }


    removeApplet() {
        this.refs.removeModal.showModal()
    }


    removeFunction = () => {
        let id = this.props.match.params.id

        fetch(domain + '/modules/remove', {
            method: 'POST',
            body: JSON.stringify({ "id": id }),
            headers: headers
        }).then(response => response.json()).then(data => {
            if (data.status == 200) {

                this.refs.removeModal.hideModal()
                this.props.history.goBack()

            }

        }).catch(error => this.setState({ error, isLoading: false }));
    }

    render() {

        const dropzoneRef = React.createRef();

        return (

            <div style={{ width: 'calc(98% )', marginRight: 'calc(1% )', borderRadius: 15, paddingTop: 20, marginTop: '2%', marginBottom: '2%' }}>


                <RemoveModal removeFunction={this.removeFunction} ref={'removeModal'} />



                <div className={"content mediumiransansfont"} style={{ filter: this.state.modalIsOpen ? 'blur(5px)' : 'blur(0px)', transition: 'all 0.5s', direction: 'rtl', textAlign: 'right', paddingTop: 0, width: '100%' }} >
                </div>

                <div className={"content mediumiransansfont"} style={{ paddingRight: '5%', paddingLeft: '5%', paddingTop: 10, overflow: 'hidden', width: '100%', minHeight: '100vh', direction: 'ltr' }} >
                    <div className="row">

                        <div className="col-12 mb-3">

                            <div style={{ backgroundColor: '#fff', borderRadius: 10, padding: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                <div>
                                    <p className={'mediumiransansfont'} style={{ marginRight: 0, marginTop: 0, fontSize: 11, opacity: 1, letterSpacing: 0, color: '#007aff', direction: 'ltr', cursor: 'pointer' }}>Learn more about modules <i className="fa fa-info-circle"></i></p>
                                    <p className={'mediumiransansfont'} style={{ marginRight: 0, marginTop: 0, fontSize: 22, color: '#000', fontWeight: 'bold' }}>Create New Database</p>
                                </div>

                                <div>

                                    <div className="d-flex align-items-center">


                                        {(this.state.stage == 0) && this.props.match.params.id != 'add' && (
                                            <div style={{ justifyContent: 'center', display: 'flex', }}>
                                                <div className="d-inline-flex">
                                                    <div onClick={() => this.removeApplet()} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: '#ee5050', padding: '7px 25px 7px 25px', borderRadius: 20, boxShadow: '0px 0px 30px rgba(0,0,0,0.0)', margin: 3 }}>

                                                        <div>
                                                            <p className={'iransansfont'} style={{ fontWeight: 'bold', marginRight: 0, marginTop: 0, fontSize: 12, color: '#fff' }}>Remove</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}



                                        {!this.state.isLoading && (
                                            <div style={{ justifyContent: 'center', display: 'flex', }}>
                                                <div className="d-inline-flex">
                                                    <div onClick={() => { if (this.state.stage == 0) { this.props.history.goBack() } else { this.setState({ stage: this.state.stage - 1 }) } }} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: '#789', padding: '7px 25px 7px 25px', borderRadius: 20, boxShadow: '0px 0px 30px rgba(0,0,0,0.0)', margin: 3 }}>

                                                        <div>
                                                            <p className={'iransansfont'} style={{ fontWeight: 'bold', marginRight: 0, marginTop: 0, fontSize: 12, color: '#fff' }}>Back</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {(this.state.stage == 0) && (
                                            <div style={{ justifyContent: 'center', display: 'flex', }}>
                                                <div className="d-inline-flex">
                                                    <div onClick={() => this.setState({ stage: this.state.stage + 1 })} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: '#202020', padding: '7px 25px 7px 25px', borderRadius: 20, boxShadow: '0px 0px 30px rgba(0,0,0,0.0)', margin: 3 }}>

                                                        <div>
                                                            <p className={'iransansfont'} style={{ fontWeight: 'bold', marginRight: 0, marginTop: 0, fontSize: 12, color: '#fff' }}>Next Step</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div>
                                            {!this.state.isLoading && this.state.stage == 1 && (

                                                <div onClick={() => this.submitForm()} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: '#202020', padding: 3, paddingLeft: 3, paddingRight: 15, borderRadius: 20, boxShadow: '0px 0px 30px rgba(0,0,0,0.0)', margin: 3 }}>
                                                    <div className="mr-2" style={{ backgroundColor: '#fff', borderRadius: 50, padding: 0, display: 'flex', minWidth: 20, height: 20, justifyContent: 'center', alignItems: 'center', margin: 3 }}>
                                                        <p className={'iransansfont'} style={{ fontWeight: 'bold', marginRight: 0, marginTop: 0, fontSize: 14, color: '#202020', fontFamily: 'arial', fontWeight: 'bold' }}><i className="fa fa-check"></i></p>
                                                    </div>
                                                    <div>
                                                        <p className={'iransansfont'} style={{ fontWeight: 'bold', marginRight: 0, marginTop: 0, fontSize: 12, color: '#fff' }}>Submit Forms</p>
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.isLoading && (
                                                <div className=" mr-4">
                                                    <Loader
                                                        type="Oval"
                                                        color="rgba(0,0,0,1)"
                                                        height="30"
                                                        width="30"
                                                    />
                                                </div>
                                            )}
                                        </div>


                                    </div>





                                </div>
                            </div>
                        </div>
                    </div>




                    {this.state.stage == 0 && (

                        <div className="row">
                            <div className="col-12">
                                <div className="box p-0 " style={{ backgroundColor: '#fcfcfc' }}>


                                    <div className="p-3 text-center mb-1" style={{ borderBottom: '#f7f7f7 0px solid' }}>

                                        <img src="/images/forms.png" style={{ width: 120, marginTop: 15 }}></img>

                                        <p className="mt-4 font-weight-bold" style={{ fontSize: 18, color: '#345' }}>Database info</p>
                                        <p className="mb-0 mt-1" style={{ fontSize: 11, color: '#abc', letterSpacing: 0 }}>This name would be shown to other people</p>
                                        <p className="mb-3" style={{ fontSize: 11, color: '#abc', letterSpacing: 0 }}>but it can be customized for reasones</p>


                                        <div className={'d-flex'} style={{ justifyContent: 'center', alignItems: 'center' }}>

                                            <select value={this.state.appletType} onChange={e => this.setState({ appletType: e.target.value })} className="no-outline m-1 mb-2" placeholder="Database name ..." style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }}>
                                                <option selected hidden={false} value={''}>Select one ...</option>

                                                <option value={'Form'} selected={this.state.appletType == 'Form'}>Form</option>
                                                <option value={'Guarantee'} selected={this.state.appletType == 'Guarantee'}>Guarantee</option>
                                                <option value={'Quiz'} selected={this.state.appletType == 'Quiz'}>Quiz</option>
                                                <option value={'offCodes'} selected={this.state.appletType == 'offCodes'}>Off Codes</option>
                                                <option value={'Tree'} selected={this.state.appletType == 'Tree'}>Tree</option>

                                            </select>

                                            {/* <input  /> */}
                                            {/* <input value={this.state.description} onChange={e => this.setState({ description: e.target.value })} className="no-outline m-1 mb-2" placeholder="Description ..." style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} /> */}
                                        </div>



                                        <div className={'d-flex'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <input value={this.state.name} onChange={e => this.setState({ name: e.target.value })} className="no-outline m-1 mb-2" placeholder="Database name ..." style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} />
                                            <input value={this.state.description} onChange={e => this.setState({ description: e.target.value })} className="no-outline m-1 mb-2" placeholder="Description ..." style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} />
                                        </div>

                                        <div className={'d-flex mb-0'} style={{ justifyContent: 'center', alignItems: 'flex-start' }}>

                                            <input value={this.state.applet} onChange={e => this.setState({ applet: e.target.value })} className="no-outline m-1 " placeholder="Applet ..." style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} />

                                            <div className={'m-1 text-right'} style={{ flex: 1, maxWidth: '300px' }}>
                                                <Dropzone
                                                    ref={dropzoneRef}
                                                    multiple={false}
                                                    accept='image/jpeg'
                                                    onDrop={this.onDrop.bind(this)}
                                                    style={{ flex: 1, cursor: 'pointer' }}
                                                >
                                                    <div className="no-outline m-1" style={{ fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '10px 15px 10px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px', display: 'flex', alignItems: 'center', color: '#ddd' }}>
                                                        {!this.state.image && (<p className="mt-1">Choose Image ...</p>)}
                                                        {this.state.image && (
                                                            <div className="d-flex">
                                                                <div>
                                                                    <img src={(this.state.image.includes('.jpg')) ? contentUrl + this.state.image : this.state.image} style={{ width: 50, height: 30, objectFit: 'cover', borderRadius: 4 }}></img>
                                                                </div>
                                                                <p className="mt-1 ml-2">Image Selected</p>
                                                            </div>
                                                        )}

                                                    </div>

                                                </Dropzone>

                                                {(this.state.orgImage && this.state.orgImage != this.state.image) && (
                                                    <p onClick={() => this.backToOrgImg()} style={{ cursor: 'pointer', fontSize: 12, color: '#007aff', marginTop: 2 }}>بازگشت به عکس اولیه</p>
                                                )}

                                            </div>

                                        </div>

                                        <div className={'d-flex mb-3'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <input value={this.state.apiRoute} onChange={e => this.setState({ apiRoute: e.target.value })} className="no-outline m-1 mb-2" placeholder="API route" style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} />
                                            <input value={this.state.helperKey} onChange={e => this.setState({ helperKey: e.target.value })} className="no-outline m-1 mb-2" placeholder="Helper Key" style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} />
                                        </div>


                                        <div className={'d-flex mb-3'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <input value={this.state.fetchUrl} onChange={e => this.setState({ fetchUrl: e.target.value })} className="no-outline m-1 mb-2" placeholder="Fetch Url" style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} />

                                            <input value={this.state.addUrl} onChange={e => this.setState({ addUrl: e.target.value })} className="no-outline m-1 mb-2" placeholder="Add Url" style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} />

                                        </div>

                                        <div className={'d-flex mb-3'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <input value={this.state.editUrl} onChange={e => this.setState({ editUrl: e.target.value })} className="no-outline m-1 mb-2" placeholder="Edit Url" style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} />

                                            <input value={this.state.removeUrl} onChange={e => this.setState({ removeUrl: e.target.value })} className="no-outline m-1 mb-2" placeholder="Remove Url" style={{ flex: 1, fontWeight: 'bold', textAlign: 'left', fontSize: 20, padding: '0px 15px 0px 15px', backgroundColor: '#fff', borderRadius: 8, border: '#eee solid 1px', height: 45, width: '100%', maxWidth: '300px' }} />
                                        </div>


                                        <p className="mb-3" style={{ fontSize: 10, color: '#abc', letterSpacing: 0 }}>You can learn more about naming strategies <span style={{ color: '#007aff' }}>hear</span></p>

                                    </div>

                                </div>
                            </div>

                        </div>
                    )}

                    {this.state.stage == 1 && (
                        <DragDrop changeHeaders={this.changeHeaders} itemsList={this.state.itemsList} />
                    )}

                    {/* {this.state.stage == 2 && (
                        <StageBuilder changeHeaders={this.changeHeaders} />
                    )} */}


                </div>


                <Modal className="lightiransansfont" ref={'croperModal'} maxWidth={700} style={{}}>

                    <div>
                        <Cropper
                            ref='cropper'
                            src={this.state.image}
                            style={{ maxHeight: 400, }}
                            aspectRatio={5 / 3}
                            guides={false}
                            zoomable={false}
                            background={true}
                        />

                        <div className=" ml-auto mr-auto mediumiransansfont mt-3" style={{ textAlign: 'center', marginBottom: 0 }}>
                            <Button className={'mediumiransansfont'} style={{ alignSelf: 'center' }} onClick={() => { this.refs.croperModal.hideModal(); this._crop(); }} round style={{ backgroundColor: '#e7e7e7', color: '#777' }}>افزودن تصویر</Button>
                        </div>
                    </div>
                </Modal>


            </div >
        );
    }




    onDrop(files) {
        const newImage = URL.createObjectURL(files[0])
        this.setState({ image: newImage })
        this.refs.croperModal.showModal()
    }

    _crop() {
        // var images = this.state.images
        // var files = this.state.files
        var dataURL = this.refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 1)
        // images.push(dataURL)
        var file = this.dataURLtoFile(dataURL, 'a.jpg');
        // files.push(file)
        // this.setState({ images, files })
        this.setState({ image: dataURL, file })

        // this.props.changeFiles(files, this.props.headerkey, 'file')
    }


    dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        var ia = new Uint8Array(bstr.length);
        for (var i = 0; i < bstr.length; i++) {
            ia[i] = bstr.charCodeAt(i);
        }
        return new Blob([ia], { type: mime });
    }



}



export default FormBuilder

