import React from "react";

// used for making the prop types of this component
// import PropTypes from "prop-types";

class Header extends React.Component {
    state = {
        value: '',
        showValue: ''
    }

    componentWillMount() {
        // console.log(this.props.title)
        if (this.props.title)
            this.setState({ value: this.props.title, showValue: this.changeToPriceValue(this.props.title) })
    }

    // componentWillReceiveProps(newProps) {
    //     if (this.props.title != newProps.title) {
    //         this.setState({ value: this.props.title })
    //     }
    // }

    handleChange(evt) {
        let value = ''

        if (evt.target.value != "") {
            value = evt.target.value.replace(new RegExp(',', 'g'), '')
            value = String(Number(value))
        }

        if (Number(value) != null) {

            this.setState({ value, showValue: this.changeToPriceValue(value) });

            this.props.onChange(this.props.headerkey, value, this.props.extra)
        }
    }

    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }


    changeToPriceValue(value) {
        let newValue = String(value).split('')
        let final = ''
        let count = 0
        for (let i = newValue.length - 1; i >= 0; i--) {
            final = newValue[i] + final
            count++
            if (count == 3 && i != 0) {
                final = ',' + final
                count = 0
            }
        }

        return final
    }


    render() {
        let info = this.props.info

        return (
            <div className='mt-0 ' >
                {/* <label className={'mb-2 mr-1 ml-1 ' + (this.conditionalSettings('required', true) ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{this.props.label}</label> */}
                <input pattern="[0-9]*" onInput={this.handleChange.bind(this)} disabled={this.conditionalSettings('disabled', true)} className='mediumiransansfont form-control' value={this.state.showValue ? this.state.showValue : ''} placeholder={info.placeHolder} style={{ backgroundColor: '#f7f7f7', borderColor: '#ddd', color: '#222', height: 35 }} />
            </div>
        );
    }
}

export default Header;
