import React from "react";
// import {  Draggable } from 'react-smooth-dnd';
// import { applyDrag, generateItems } from '../utils';
// import TextCell from '../components/Inputs/Text'
import { FormBuilderItems, FormBuilderObjects } from '../Jsons/FormBuilderData'
import Loader from 'react-loader-spinner'
import Container from '../components/Container'
import { Collapse } from 'react-collapse'
import Modal from '../components/Modal'
import MultiLevelSelect from '../components/Inputs/MultiLevelSelect'
import MultiSelect from '../components/Inputs/MultiSelect'
import { domain, headers, colors, settings } from '../Variables'
import HandleAdd from '../handle.add'
import { setObject } from "../useful";
import { FilterFields, FilterOperators } from '../Jsons/FilterData'
import TablePageHeader from '../components/TablePageHeader'
import RemoveModal from '../components/Modals/RemoveModal'
import ItemModal from '../components/Modals/ItemModal'
import FilterModal from '../components/Modals/FilterModal'



class TreeApplet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // groups: [{ _id: '1', name: 'مالی', subgroups: [{ _id: '2', name: 'مدیر مالی', subgroups: [{ _id: '3', name: 'معاونت مالی' }, { _id: '4', name: 'مدیر مالی' }] }, { _id: '5', name: 'مدیر مالی' }, { _id: '6', name: 'مدیر مالی' }] }, { _id: '7', name: 'تایید مدیریت', subgroups: [{ _id: '8', name: 'مدیر مالی' }, { _id: '9', name: 'مدیر مالی' }, { _id: '10', name: 'مدیر مالی' }, { _id: '11', name: 'مدیر مالی' }, { _id: '12', name: 'مدیر مالی' }] }, { _id: '13', name: 'بسته بندی', subgroups: [] },],
      data: [],
      removeData: null,
      page: null,
      modalIsOpen: false,
      actions: [{ title: 'افزودن', action: 'add', color: '#78b2d0' }, { title: 'ویرایش', action: 'edit', color: '#78b2d0' }, { title: 'حذف', action: 'remove', color: '#dd8aa4' }],
      actionIndex: null,
      filterItem: { values: {} },

      selectedIndex: null,
      isLoading: false,
      collapses: {},

    }
  }


  componentWillMount() {
    this.setState({ innerWidth: window.innerWidth })
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ innerWidth: window.innerWidth, height: window.innerHeight });
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }



  componentDidMount() {
    this.fetch()
  }


  fetch() {

    this.setState({ isLoadingData: true })

    let body = { filter: {} }

    if (this.props.page.settings && this.props.page.settings.fetchFilter) {
      body.filter = this.props.page.settings.fetchFilter
    }
    if (this.props.page && this.props.page.applet) {
      body.filter.applet = this.props.page.applet
    }

    let api = this.props.page.fetchUrl ? this.props.page.fetchUrl : '/values/getAllWithData'
    // console.log(api)
    fetch(domain + api, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers
    })

      .then(response => response.json()).then(data => {

        this.setState({ isLoadingData: false })
        // console.log(data)
        if (data.status == 200 && Array.isArray(data.info)) {

          let formedData = []

          data.info.forEach(element => {
            if (element.values.parent == '' || !element.values.parent) {
              formedData.push(element)
            }
          });


          formedData.forEach(parent => {
            this.findChildren(parent, data.info)
          });

          // console.log(formedData)

          this.setState({ data: formedData })
        }

      }).catch(error => this.setState({ error, isLoading: false }));
  }

  findChildren(parent, ref) {
    // console.log(parent)
    let children = []
    ref.forEach(element => {
      // console.log(parent)
      let myParent = parent
      let myId = element.values.parent

      if (typeof myParent == 'object') {
        myParent = myParent._id
      }
      if (typeof myId == 'object') {
        myId = myId._id
      }

      if (myId == myParent) {
        children.push(element)
      }
    // }else {
    //   if(element.values.parent == parent) {
    //   children.push(element)
    // }
  

});

// console.log(children)

if (Array.isArray(children)) {
  children.forEach(child => {
    this.findChildren(child, ref)
  });
}

parent.subgroups = children
return
  }




fetchAdd = () => {
  this.setState({ errors: {} })

  let handleAdd = new HandleAdd()

  this.setState({ isLoadingSend: true })

  handleAdd.fetchAdd(this.props.page, this.state.currentItem, (data, errors) => {

    this.setState({ isLoadingSend: false })

    if (errors) {
      if (typeof errors == "object") {
        this.setState({ errors: errors })
      }
      this.setState({ isLoading: false })
      return
    }

    if (data.status == 200) {

      if (this.state.lockSend && this.state.selectedIndex == -1) {
        this.addAction()
      } else {
        this.setState({ modalIsOpen: false })
        this.refs.itemModal.hideModal()
      }

      let newData = this.state.data

      if (this.state.selectedIndex == -1) {
        this.setState({ data: [] }, () => {
          this.fetch()
        })
        // this.props.loadPageData()
      } else {
        delete this.state.currentItem['**files']
        // newData[this.state.selectedIndex] = this.state.currentItem
        // this.setState({ data: newData })
        this.setState({ data: [] }, () => {
          this.fetch()
        })

      }
    }


  })
}






fetchRemove = (id, index, modal) => {

  fetch(domain + this.props.urls.remove, {
    method: 'POST',
    body: JSON.stringify({ "id": id }),
    headers: headers
  }).then(response => response.json()).then(data => {
    if (data.status == 200) {

      this.state.data.splice(index, 1)
      modal.setState({ isLoadingRemove: false })
      this.refs.removeModal.hideModal()
    }

  }).catch(error => this.setState({ error, isLoading: false }));
}




editAction = (item, index) => {

  let newItem = JSON.stringify(item)
  newItem = JSON.parse(newItem)
  console.log("newItem")
  console.log(newItem)
  this.setState({ currentItem: {} }, () => {
    this.setState({ currentItem: newItem })
    this.setState({ selectedIndex: index }, () => {
      // console.log("EDIT")
      console.log(this.state.currentItem)
      this.refs.itemModal.showModal()
      this.setState({ modalIsOpen: true })
    })
  })
}

// setModalIsOpen = (action) => {
//   this.setState({ modalIsOpen: action })
// }

removeAction = (info, index) => {
  this.refs.removeModal.showModal(info._id, index)
  // this.setModalIsOpen(true)
}

addAction = (parentItem, index) => {
  console.log(parentItem)
  let item = {}
  item = this.makeNewItem()
  this.setState({ currentItem: {} }, () => {
    if (parentItem) {
      // console.log(item)
      // console.log(parentItem._id)
      item.values.parent = parentItem._id
    }
    this.setState({ currentItem: item })
    this.setState({ selectedIndex: -1 }, () => {
      this.refs.itemModal.showModal()
      this.setState({ modalIsOpen: true })
    })
  })

}


makeNewItem = () => {
  let helper = null
  let item = {}
  let temp = item

  if (this.props.page.helperKey && this.props.page.helperKey != '') {
    helper = this.props.page.helperKey
    item[helper] = {}
    temp = item[helper]
  }

  this.props.page.headers.forEach(header => {

    if (this.state.lockedItems && this.state.lockedItems[header.key]) {
      temp[header.key] = this.helperCheck(temp, header.key, helper)
    } else {
      if (header.type != 'button') {

        if (header.information.defaultValue) {
          temp[header.key] = header.information.defaultValue
        } else {
          temp[header.key] = ''
        }
      }
    }

  });

  return item

}

helperCheck(ref, key, helper) {
  if (helper) {
    return ref[helper][key]
  } else {
    return ref[key]
  }
}


changeValue = (key, value) => {

  let helper
  if (this.props.page.helperKey && this.props.page.helperKey != '') {
    helper = this.props.page.helperKey
  }

  // let currentItem = this.state.currentItem

  let newKey = key

  if (helper) {
    newKey = helper + '.' + key
  }


  let currentItem = { ...this.state.currentItem }
  currentItem = setObject(currentItem, newKey, value)

  this.setState({ currentItem })


}

changeFiles = (files, key, type) => {
  if (!this.state.currentItem["**files"]) {
    this.state.currentItem["**files"] = {}
  }
  this.state.currentItem["**files"][key] = files
}

cancelModal = () => {
  this.setState({ modalIsOpen: false })
}


openActionsModal = (index, parentsIndexes, actionItem) => {
  // console.log("HERE")
  // this.setModalIsOpen(true)
  this.setState({ actionIndex: index, actionparentsIndexes: parentsIndexes, actionItem }, () => {
    this.refs.actionsModal.showModal()

  })
}


butAction = (index, action) => {

  this.refs.actionsModal.hideModal()
  // console.log(action)
  switch (action) {
    case 'remove':
      this.removeAction(this.state.actionItem, index)
      break
    case 'edit':
      this.editAction(this.state.actionItem, index)
      break
    case 'add':
      this.addAction(this.state.actionItem, index)
      break
  }
}



openFilter = () => {

  // this.setState({ modalIsOpen: true })
  let item = {}
  item['values'] = {}

  this.props.page.headers.forEach(header => {
    if (FilterFields[header.type]) {
      item.values[header.key] = {}
      FilterFields[header.type].options.forEach(option => {
        if (option.default)
          item.values[header.key].operator = option.key
      });
    }
  });

  this.setState({ filterItem: item }, () => {
    this.refs.filterModal.showModal()
  })
}

changeFilter = (key, value, operator) => {
  if (operator) {
    this.state.filterItem.values[key].operator = value
  } else {
    this.state.filterItem.values[key].value = value
  }

}

fetchFilter = () => {

}

lockSend = () => {
  if (this.state.lockSend) {
    this.setState({ lockedItems: {} })
  }

  this.setState({ lockSend: !this.state.lockSend })
}

changeLockItems = (key) => {
  let lockedItems = this.state.lockedItems ? this.state.lockedItems : {}
  lockedItems[key] = lockedItems[key] ? !lockedItems[key] : true
  this.setState({ lockedItems })
}


changeCollapse(id) {

  let collapses = this.state.collapses

  collapses[id] = (collapses[id] != null) ? !collapses[id] : false
  this.setState({ collapses })
}



groupRow(group, padding, index, parentsIndexes) {
  // console.log(window.innerWidth)
  let newParentsIndexes = parentsIndexes ? [...parentsIndexes] : []
  newParentsIndexes.push(index)

  return (
    <div style={{ paddingRight: padding, paddingLeft: this.state.innerWidth > 600 ? 0 : 0 }}>
      <div style={{ width: '100%', marginTop: 5, backgroundColor: '#f2f6f8', padding: 0, borderRadius: 10, overflow: 'hidden', marginBottom: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 10px 10px 15px' }}>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 0, marginBottom: 0, borderRadius: 0, }}>
            {/* <img src="/images/male.png" style={{ width: 50, height: 50, borderRadius: 30, marginBottom: 0, marginTop: 0 }} /> */}
            <div className={'text-right mr-2 d-flex'}>

              {group.subgroups && group.subgroups.length > 0 && (
                <div onClick={() => this.changeCollapse(group._id)} style={{ backgroundColor: '#abc', borderRadius: 30, width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <i className={"fa " + (((this.state.collapses[group._id] != null) ? this.state.collapses[group._id] : true) ? 'fa-minus' : 'fa-plus')} style={{ color: '#f2f4f6' }}></i>
                </div>
              )}

              <p className="mr-2" style={{ color: '#222', margin: 0, fontSize: 13 }}>{group.values.name}</p>
              {/* <p style={{ color: '#abc', margin: 0, fontSize: 11, marginTop: 2 }}>No description</p> */}
            </div>
          </div>
          <div className={'d-flex'}>

            <div onClick={() => this.openActionsModal(index, parentsIndexes, group)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
              <i className={"fa fa-edit"} style={{ color: '#abc', fontSize: 18 }}></i>
            </div>


          </div>
        </div>
      </div>

      {group.subgroups && group.subgroups.length > 0 && (

        <Collapse isOpened={(this.state.collapses[group._id] != null) ? this.state.collapses[group._id] : true} className="mb-2">
          {group.subgroups.map((subgroup, sIndex) => {
            return (
              this.groupRow(subgroup, padding + this.state.innerWidth > 600 ? 20 : 15, sIndex, newParentsIndexes)
            )
          })}
        </Collapse>
      )}

    </div>
  )
}




render() {
  return (
    <div>
      <Container modules={this.props.modules} isOpen={true} modalIsOpen={this.state.modalIsOpen} location={this.props.location} >

        <div style={{ paddingRight: '6%', paddingLeft: '6%', }}>


          {!this.props.page && (
            <div className="text-center mt-4">
              <Loader
                type="ThreeDots"
                color="#789"
                height="60"
                width="60"
              />
            </div>
          )}

          {this.props.page && (
            <div>

              <TablePageHeader openFilter={this.openFilter} addAction={this.addAction} title={this.props.page.title} description={this.props.page.description} />

            </div>
          )}


          <div className="row w-100 m-0">
            <div className="col-12" style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3, backgroundColor: '#fff', padding: '10px 15px 10px 15px', borderRadius: 8 }}>
              <div onClick={() => this.addAction()} style={{ borderBottom: '1px solid #f7f7f7', transition: 'all 0.3s', marginLeft: 0, cursor: 'pointer', backgroundColor: '#202020', padding: '5px 5px 5px 5px', minWidth: 70, borderTopLeftRadius: 4, borderTopRightRadius: 20, borderRadius: 20, marginBottom: 0, display: 'flex', alignItems: 'center' }} >
                <span className="mr-2" style={{ color: '#fff', margin: 0, textAlign: 'center', fontSize: 12, direction: 'ltr', lineHeight: 1, fontWeight: 'bold' }}>افزودن </span>
                <div className="mr-2" style={{ backgroundColor: '#fff', borderRadius: 50, padding: 0, display: 'flex', minWidth: 20, height: 20, justifyContent: 'center', alignItems: 'center' }}>
                  <i className="fa fa-plus" style={{ color: '#202020' }}></i>
                </div>
              </div>

              <div style={{ direction: 'rtl', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <i className="fa fa-search" style={{ marginRight: 10, fontSize: 20, marginBottom: 0, color: '#323232', opacity: 0.3 }}></i>
              </div>
            </div>
          </div>


          <div className={"w-100 mediumiransansfont"} >
            <div className="row">
              <div className="col-12">
                <div className="box p-0 " style={{ backgroundColor: '#fcfcfc', position: 'relative' }}>
                  <div className="p-2 text-center mb-1" style={{ borderBottom: '#f7f7f7 0px solid' }}>


                    <div className="w-100 p-2 pb-3 no-scrollbar" style={{ display: 'flex', flexWrap: 'wrap', flex: 1, }}>

                      {this.state.data.map((group, index) => {
                        return (
                          <div key={index} className="p-2" style={{ width: '100%' }}>

                            {this.groupRow(group, 0, index, 0)}

                          </div>
                        )
                      })}

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Container>




      {this.props.page && (
        <div>
          <RemoveModal cancelModal={this.cancelModal} removeFunction={this.fetchRemove} ref={'removeModal'} />

          {/* {((this.state.selectedIndex != null && this.state.selectedIndex < this.state.data.length) || (this.state.selectedIndex == -1)) && ( */}
          <ItemModal isLoading={this.state.isLoadingSend} page={this.props.page} cancelModal={this.cancelModal} fetchAdd={() => this.fetchAdd()} changeValue={this.changeValue} changeFiles={this.changeFiles} removeFunction={this.fetchRemove} ref={'itemModal'} headers={this.props.page.headers} item={this.state.currentItem} lockSend={this.lockSend} isLocked={this.state.lockSend} lockedItems={this.state.lockedItems} changeLockItems={this.changeLockItems} />
          {/* )} */}


          <FilterModal cancelModal={this.cancelModal} fetchFilter={() => this.fetchFilter()} changeValue={this.changeFilter} ref={'filterModal'} headers={this.props.page.headers} item={this.state.filterItem} />


          <Modal cancelModal={this.cancelModal} className="lightiransansfont" ref={'actionsModal'} maxWidth={250} style={{ alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center' }} zIndex={1}>

            <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p style={{ textAlign: 'center', fontSize: 20, marginTop: 0, fontWeight: 'bold' }}>عملیات ها</p>
            </div>

            <div className="mt-3" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

              {this.state.actions.map((prop, index) => {
                return (
                  <div className="mb-2">
                    <div style={{ flex: 1, flexDirection: 'column', position: 'relative' }} >
                      <div onClick={() => this.butAction(this.state.actionIndex, prop.action)} style={{ cursor: 'pointer', padding: 10, paddingLeft: 15, paddingRight: 15, borderRadius: 20, backgroundColor: prop.color + '25', justifyContent: 'center', alignItems: 'center' }}>
                        <p className={'mediumiransansfont'} style={{ margin: 7, color: '#fff', textAlign: 'center', fontSize: 13, margin: 0, color: prop.color }}>{prop.title}</p>
                      </div>
                    </div>
                  </div>
                )
              })}

            </div>

          </Modal>

        </div>

      )}

    </div >

  );
}



}



export default TreeApplet

