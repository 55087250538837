import { domain, headers } from './Variables'
import Cookies from "universal-cookie";

class HandleAdd {

    page = {}

    url = ''
    headers = null

    fetchAdd(page, currentItem, cb) {

        this.currentItem = {...currentItem }
        this.cb = cb
        this.page = page

        // console.log("currentItemINIT")
        console.log(currentItem)

        if (this.validator(this.page.headers, this.currentItem)) {

            let keysToUplaod = []

            this.customizeForm(this.page.headers, keysToUplaod, null)

            if (keysToUplaod.length > 0) {
                console.log(keysToUplaod)
                let count = 0

                this.fileFetchLoop(this.page.headers, this.currentItem, count, keysToUplaod, () => {
                    // console.log("UPLOAD DONE")
                    this.fetchParams()
                })

            } else {
                this.fetchParams()
            }
        }
    }



    customizeForm(headers, keysToUplaod, subform) {

        let helper = this.getPageHelper()

        headers.forEach(element => {

            if (element.type == 'subformCell') {
                this.customizeForm(element.information.headers, keysToUplaod, element.key)
            }

            let fileKey

            if (subform && this.currentItem['**files']) {
                // if (helper) {
                //     if (this.currentItem[helper] && this.currentItem[helper][subform]) {
                //         fileKey = this.currentItem[helper][subform]['**files']
                //     }
                // } else {
                //     if (this.currentItem[subform]) {
                //         fileKey = this.currentItem[subform]['**files']
                //     }
                // }


                // if (fileKey && typeof fileKey == 'object') {

                for (const [key, value] of Object.entries(this.currentItem['**files'])) {
                    if (key.startsWith(subform + '.' + element.key + '*')) {
                        keysToUplaod.push({ key: key, headerKey: element.key, type: element.type, subform })
                    }
                }
                // }



            } else {
                fileKey = this.currentItem['**files']

                if (fileKey && fileKey[element.key] && (!Array.isArray(fileKey[element.key]) || fileKey[element.key].length > 0)) {
                    keysToUplaod.push({ key: element.key, type: element.type, subform })
                }

            }

            // console.log(element.key)
            // console.log(fileKey)



            // let fileKey

            // if (helper) {
            //     fileKey = this.currentItem[helper]['**files']
            //     if (subform && this.currentItem[helper][subform]) {
            //         fileKey = this.currentItem[helper][subform]['**files']
            //     }
            // } else {
            //     fileKey = this.currentItem['**files']
            //     if (subform && this.currentItem[subform]) {
            //         fileKey = this.currentItem[subform]['**files']
            //     }
            // }



            // console.log("SUBFORM")
            // console.log(subform)

            // if (fileKey && fileKey[element.key] && (!Array.isArray(fileKey[element.key]) || fileKey[element.key].length > 0)) {
            //     keysToUplaod.push({ key: element.key, type: element.type, subform })
            //     console.log("EQHEKL!!")
            // }



            // console.log(keysToUplaod)
            // if (element.type == 'fileCell' && this.currentItem['**files'] && this.currentItem['**files'][element.key] && this.currentItem['**files'][element.key].length > 0) {
            //     keysToUplaod.push({ key: element.key, type: 'fileCell', subform })
            // }

            // if (element.type == 'video' && this.currentItem['**files'] && this.currentItem['**files'][element.key]) {
            //     keysToUplaod.push({ key: element.key, type: 'video', subform })
            // }

            if ((element.type == 'selectApiCell' || element.type == 'productCell' || element.type == 'multiSelectApiCell')) {

                let temp
                if (helper) {
                    temp = this.currentItem[helper]
                } else {
                    temp = this.currentItem
                }


                if (subform) {

                    if (temp[subform]) {

                        temp[subform].forEach(subElement => {
                            if (typeof subElement[element.key] == 'object') {

                                if (Array.isArray(subElement[element.key])) {

                                    let allItems = []
                                    subElement[element.key].forEach(oneItem => {
                                        allItems.push(typeof oneItem == 'object' ? oneItem._id : oneItem)
                                    });

                                    subElement[element.key] = allItems

                                } else {
                                    subElement[element.key] = subElement[element.key]._id
                                }
                            }
                        });
                    }
                } else {
                    if ((typeof temp[element.key] == 'object')) {

                        // if (helper) {

                        if (Array.isArray(temp[element.key])) {

                            let allItems = []
                            temp[element.key].forEach(oneItem => {
                                allItems.push(typeof oneItem == 'object' ? oneItem._id : oneItem)
                            });

                            temp[element.key] = allItems

                        } else {
                            temp[element.key] = temp[element.key] ? temp[element.key]._id : null
                        }


                        // currentItem[helper][keys[count].key] = destinations
                        // } else {
                        //     this.currentItem[element.key] = this.currentItem[element.key]._id
                        // currentItem[keys[count].key] = destinations
                        // }

                        // temp[element.key] = this.currentItem.values[element.key]._id
                    }
                }
            }
        })
    }


    getPageHelper() {
        let helper = null
        if (this.page) {
            if (this.page.helperKey && this.page.helperKey != '') {
                helper = this.page.helperKey
            }
        }

        return helper
    }





    fileFetchLoop = (headers, currentItem, count, keys, cb) => {

        console.log(keys)
        let helper = this.getPageHelper()
        let temp
        let filesKey

        if (helper) {
            temp = currentItem[helper]
        } else {
            temp = currentItem
        }

        let newHeaders = headers


        if (keys[count].subform) {
            newHeaders = null
        }
        //     temp = temp[keys[count].subform]

        //     if (helper) {
        //         filesKey = currentItem[helper][keys[count].subform]['**files']
        //     } else {
        //         filesKey = currentItem[keys[count].subform]['**files']
        //     }

        // } else {
        filesKey = currentItem['**files']
            // }


        console.log("files")
        console.log(filesKey)
        console.log(keys[count])
        console.log(filesKey[keys[count].key])

        if ((keys[count].type == 'imageCell') || (keys[count].type == 'fileCell')) {

            this.fetchUpload(newHeaders, filesKey[keys[count].key], keys[count].key, (destinations) => {

                console.log(keys[count].key)
                console.log(destinations)
                    // if (helper) {
                    // let filnalKey = temp[keys[count].key]

                // if (keys[count].subform) {
                //     let keyIndex = keys[count].key.split('*')
                //     if (keyIndex[1]) {
                //         filnalKey = temp[keyIndex[1]][keyIndex[0]]
                //     }
                // }

                if (keys[count].subform) {
                    let subformSplit = keys[count].key.split('.')
                    console.log(subformSplit)
                    let keyIndex = subformSplit[1].split('*')
                    console.log(keyIndex)
                    temp[subformSplit[0]][keyIndex[1]][keyIndex[0]] = destinations

                } else {
                    if (Array.isArray(destinations)) {
                        temp[keys[count].key] = Array.isArray(temp[keys[count].key]) ? temp[keys[count].key] : []
                        temp[keys[count].key] = [...temp[keys[count].key], ...destinations]
                    } else {
                        temp[keys[count].key] = destinations
                    }
                }


                // console.log("currentItem")
                // console.log(currentItem)
                // console.log(keys)
                // console.log(count)
                // console.log(keys[count].key)
                // temp[keys[count].key] = [...temp[keys[count].key], destinations]
                // } else {
                //     currentItem[keys[count].key] = destinations
                // }

                if (count < keys.length - 1) {
                    let newCount = count + 1

                    this.fileFetchLoop(headers, currentItem, newCount, keys, cb)
                } else {
                    cb()
                }
            })
        } else {
            this.fetchVideoUpload(filesKey[keys[count].key], keys[count].key, (destinations) => {
                console.log(destinations)
                temp[keys[count].key] = destinations

                if (count < keys.length - 1) {
                    let newCount = count + 1
                    this.fileFetchLoop(headers, currentItem, newCount, keys, cb)
                } else {
                    cb()
                }
            })
        }
    }


    fetchVideoUpload = (fileData, field, cb) => {
        console.log(fileData)
        let destinations = ''
        var formData = new FormData();

        if (fileData.file) {
            formData.append('video', fileData.file);
        }

        formData.append('field', field);
        formData.append('coverTime', fileData.coverTime);

        fetch(domain + '/values/videoUploader', {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            },
        }).then(response => {
            return response.json()
        }).then(data => {

            destinations = data.info
            cb(destinations)

        });

    }



    fetchUpload = (headers, files, field, cb) => {
        let destinations = []
        let count = 0
        files.forEach((file, i) => {

            var formData = new FormData();

            if (file) {
                formData.append('images', file);
            }

            formData.append('field', field);

            fetch(domain + '/values/uploader', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                },
            }).then(response => {
                return response.json()
            }).then(data => {

                console.log("RESULT")
                console.log(data)
                if (headers) {
                    headers.forEach(header => {
                        if (header.key == field) {
                            if (header.information && header.information.single) {
                                destinations = data.info
                            } else {
                                destinations.push(data.info)
                            }
                        }

                    });
                } else {
                    destinations = data.info
                }

                count++
                if (count == files.length) {
                    cb(destinations)
                }
            });

        });
    }



    fetchParams = () => {

        let currentItem = this.currentItem
        delete currentItem['*files']

        let body = this.currentItem

        // console.log("currentItem")
        // console.log(currentItem)

        body.applet = this.page.applet
        body.database = this.page._id

        // console.log()

        // let body = { "applet": this.page.applet, helperKey: this.getPageHelper(), 'database': this.page._id, item: currentItem, _id: currentItem._id }

        const cookies = new Cookies();
        let newHeaders = headers
        newHeaders.authorization = 'bearer ' + cookies.get('adminToken')

        let api = '/values/submitForm'
        if (!currentItem._id) {
            api = this.page.addUrl ? this.page.addUrl : '/values/submitForm'
        } else {
            api = this.page.editUrl ? this.page.editUrl : '/values/submitForm'
        }
        // console.log("HERE")
        // console.log(api)
        // console.log(body)
        // console.log(newHeaders)

        return fetch(domain + api, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: newHeaders
        })

        .then(response => response.json()).then(data => {
            console.log(data)
            this.cb(data)

        }).catch(error => this.cb(null, error));
    }


    validator(headers) {
        let isValid = true
        let errors = {}
        let helper = this.getPageHelper()
        let temp

        if (helper) {
            temp = this.currentItem[helper]
        } else {
            temp = this.currentItem
        }

        headers.forEach(header => {
            if (header.information && header.information.required) {
                // console.log(temp[header.key])
                if (temp[header.key] == "" || temp[header.key] == null) {

                    if (header.type == 'imageCell' || header.type == 'fileCell' || header.type == 'videoCell') {

                        if (!(this.currentItem["**files"] && this.currentItem["**files"][header.key] && this.currentItem["**files"][header.key].length > 0)) {
                            errors[header.key] = "This field is required"
                            isValid = false
                        }
                    } else {
                        errors[header.key] = "This field is required"
                        isValid = false
                    }
                }
            }
        });

        if (!isValid) {
            this.cb(null, errors)
        }
        return isValid
    }

}


export default HandleAdd;