import React from "react";
import Dropzone from 'react-dropzone'
import { content } from "../../Variables";
// import Cropper from 'react-cropper';
// import Button from "../CustomButton.jsx";
// import Modal from "../Modal.jsx";
// import noVideo from "../../assets/img/no-video.png";


class VideoInput extends React.Component {

    state = {
        video: null,
        file: null,
        showVideo: false,
        coverTime: '00:00:01'
    }

    componentDidMount() {
        // console.log(this.props.title)
        if (this.props.title) {
            let title = JSON.parse(JSON.stringify(this.props.title))
            this.setState({ oldVideo: Array.isArray(title) ? title : title })
        } else {
            this.setState({ oldVideo: null })
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.title != this.props.title && (!this.props.title || this.props.title == "" || this.props.title == [])) {
            let title = JSON.parse(JSON.stringify(newProps.title))
            this.setState({ oldVideo: title })
        }
        // console.log(newProps)
    }

    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }

    render() {
        const dropzoneRef = React.createRef();
        console.log(this.state.oldVideo)
        return (
            <div>
                {/* <label className={'mb-2 mr-1 ml-1 ' + (this.conditionalSettings('required', true) ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{this.props.label}</label> */}
                <div style={{ width: '100%', textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#f7f7f7', padding: 0, paddingBottom: 0, borderRadius: 4 }}>

                    <Dropzone
                        ref={dropzoneRef}
                        multiple={false}
                        accept='video/mp4'
                        onDrop={this.onDrop.bind(this)}
                        style={{ flex: 1 }}
                    >
                        {!this.state.video && !this.state.oldVideo && (<div className="" style={{ flex: 1, alignSelf: 'center', padding: 20 }}>
                            {/* <img id="myImage" src={this.state.video} style={{ width: '100%' }} alt="..." /> */}
                            <img src={'/images/cloud.svg'} width={60} />
                            <p style={{ opacity: 1, fontSize: 13, color: '#007aff' }}>ویدیو مورد نظر خود را انتخاب کنید</p>
                        </div>
                        )}
                    </Dropzone>

                    {this.state.video && (
                        <div className="" style={{ flex: 1, width: '100%', alignSelf: 'center', position: 'relative' }}>
                            <video id="myImage" width={'100%'} style={{ width: '100%' }} src={this.state.video} key={this.state.video} controls>
                                <source id="mySource" src={this.state.video} type="video/mp4"></source>
                            </video>

                            <div onClick={() => { this.removeFile() }} style={{ position: 'absolute', top: 5, left: 5, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>

                        </div>
                    )}

                    {this.state.oldVideo && (
                        <div className="" style={{ flex: 1, width: '100%', alignSelf: 'center', position: 'relative' }}>
                            <video id="myImage" width={'100%'} style={{ width: '100%' }} poster={content + 'videos/thumbnails/' + this.state.oldVideo.cover} key={this.state.oldVideo.address} controls>
                                <source id="mySource1" src={content + 'videos/' + this.state.oldVideo.address} type="video/mp4"></source>
                            </video>

                            <div onClick={() => { this.removeFile() }} style={{ position: 'absolute', top: 5, left: 5, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>

                        </div>
                    )}


                    {/* <div className="p-0 m-0 mt-1 mb-2" style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                        {this.state.videos.map((prop, index) => {
                            return (
                                <div style={{ position: 'relative' }}>
                                    <img className={"m-1 mt-3"} src={prop} height="60px" style={{ borderRadius: 4 }} />
                                    <div onClick={() => { this.removeFile("key", index) }} style={{ position: 'absolute', top: 5, left: 0, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>
                                </div>
                            )
                        })}
                    </div> */}


                    {/* <Modal className="lightiransansfont" ref={'croperModal'} maxWidth={700} style={{}}>

                        <div>
                            <Cropper
                                ref='cropper'
                                src={this.state.video}
                                style={{ maxHeight: 400, }}
                                // aspectRatio={4 / 3}
                                guides={false}
                                zoomable={false}
                                background={true}
                            />

                            <div className=" ml-auto mr-auto mediumiransansfont mt-3" style={{ textAlign: 'center', marginBottom: 0 }}>
                                <Button className={'mediumiransansfont'} style={{ alignSelf: 'center' }} onClick={() => { this.refs.croperModal.hideModal(); this._crop(); }} round style={{ backgroundColor: '#e7e7e7', color: '#777' }}>افزودن تصویر</Button>
                            </div>
                        </div>
                    </Modal> */}

                </div>
            </div>
        );
    }




    onDrop(files) {
        const newVideo = URL.createObjectURL(files[0])
        // document.getElementById("myImage").src = newVideo
        // document.getElementById("mySource").src = newVideo
        this.setState({ showVideo: false })
        this.setState({ video: newVideo })
        this.setState({ showVideo: true })
        // this.setState({ file: files[0] })
        this.props.changeFiles({ file: files[0], cover: '', coverTime: this.state.coverTime }, this.props.headerkey, 'video')

    }




    removeFile(key, index) {
        // var videos = this.state.videos
        // var files = this.state.files
        // videos.splice(index, 1)
        // files.splice(index, 1)
        this.setState({ coverTime: '00:00:00', file: null, video: null, oldVideo: null })
        this.props.changeFiles(null, this.props.headerkey, 'video')
        this.props.onChange(this.props.headerkey, null, this.props.extra)

    }
}

export default VideoInput;
