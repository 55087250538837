import React from "react";
import Dropzone from 'react-dropzone'
import Cropper from 'react-cropper';
import Button from "../CustomButton.jsx";
import Modal from "../Modal.jsx";
import { contentUrl } from '../../Variables'
import { imageAddress } from "../../useful.js";


class File extends React.Component {

    state = {
        images: [],
        files: [],
        oldFiles: null

        // showVideo: false,

    }


    componentDidMount() {
        // console.log("title")
        // console.log(this.props.title)

        if (this.props.title) {
            let title = JSON.parse(JSON.stringify(this.props.title))
            this.setState({ oldFiles: Array.isArray(title) ? title : [title] })
        } else {
            this.setState({ oldFiles: null })
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.title != this.props.title && (!this.props.title || this.props.title == "" || this.props.title == [])) {
            let title = JSON.parse(JSON.stringify(newProps.title))
            this.setState({ oldFiles: Array.isArray(title) ? title : [title] })
        }
        // console.log(newProps)
    }


    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }

    render() {
        const dropzoneRef = React.createRef();

        return (
            <div className="mt-0">
                {/* <label for={this.props.headerkey} className={'mb-2 mr-1 ml-1 boldiransansfont ' + (this.conditionalSettings('required', true) ? 'required' : '')} style={{ marginBottom: 3, marginTop: 5, fontSize: 13, color: '#456', fontWeight: 'bold' }}>{this.props.label}</label> */}
                <div style={{ width: '100%', textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#f7f7f7', padding: 20, paddingBottom: 5, borderRadius: 8 }}>

                    {(this.state.images.length > 0 && this.props.options && this.props.options.single) ? (<div></div>) : (

                        <Dropzone
                            ref={dropzoneRef}
                            multiple={false}
                            // accept='image/jpeg'
                            onDrop={this.onDrop.bind(this)}
                            style={{ flex: 1, cursor: 'pointer' }}
                        >
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <img src={'/images/cloud.svg'} width={60} />
                                <p style={{ opacity: 1, fontSize: 13, marginTop: 6, color: '#007aff' }}>Choose File</p>
                            </div>
                        </Dropzone>
                    )}


                    <div className="p-0 m-0 mt-1 mb-2" style={{ display: 'inline-flex', flexWrap: 'wrap' }}>

                        {this.state.oldFiles && Array.isArray(this.state.oldFiles) && this.state.oldFiles.map((prop, index) => {
                            return (
                                <div style={{ position: 'relative' }}>
                                    <a href={imageAddress(prop)} target="_blank">
                                        <img className={"m-1 mt-3"} src={'/images/file.png'} height="60px" style={{ borderRadius: 4 }} />
                                        <p style={{ maxWidth: 90,wordBreak:'break-word' }} className="text-center">{prop.address}</p>
                                    </a>
                                    <div onClick={() => { this.removeOldFile(index) }} style={{ position: 'absolute', top: 5, left: 0, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>
                                </div>
                            )
                        })}

                        {/* {this.state.oldFiles && !Array.isArray(this.state.oldFiles) && (
                            <div style={{ position: 'relative' }}>
                                <a href={imageAddress(this.state.oldFiles)} target="_blank">
                                    <img className={"m-1 mt-3"} src={'/images/file.png'} height="60px" style={{ borderRadius: 4 }} />
                                    <p style={{ maxWidth: 90 }} className="text-center">{this.state.oldFiles}</p>
                                </a>
                                <div onClick={() => { this.removeOldFile(0) }} style={{ position: 'absolute', top: 5, left: 0, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>
                            </div>
                        )} */}

                        {this.state.files.map((prop, index) => {
                            // console.log(prop)
                            return (
                                <div style={{ position: 'relative', marginLeft: 5, marginRight: 5, textAlign: 'center' }}>
                                    <img className={"m-1 mt-3"} src={'/images/file.png'} height="60px" style={{ borderRadius: 4 }} />
                                    <p style={{ fontSize: 12, maxWidth: '80px', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{prop.name}</p>
                                    <div onClick={() => { this.removeFile("key", index) }} style={{ position: 'absolute', top: 5, left: 0, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>
                                </div>
                            )
                        })}
                    </div>




                </div>
            </div>
        );
    }


    removeOldFile(index) {
        var oldFiles = this.state.oldFiles
        oldFiles.splice(index, 1)
        this.setState({ oldFiles })
        this.props.onChange(this.props.headerkey, oldFiles, this.props.extra)
    }


    onDrop(files) {
        // const newImage = URL.createObjectURL(files[0])
        // document.getElementById("myImage").src = newImage
        // document.getElementById("mySource").src = newImage
        // this.setState({ showVideo: false })
        // this.setState({ image: newImage })
        // this.refs.croperModal.showModal()
        // this.setState({ showVideo: true })
        // this.setState({ files: files })

        var allFiles = this.state.files
        // var dataURL = this.refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 1)
        // images.push(dataURL)
        // files.push(this.blobToFile(this.dataURLtoFile(dataURL),'a.jpeg'))
        // var file = this.dataURLtoFile(dataURL, 'a.jpg');
        allFiles.push(files[0])
        this.setState({ files: allFiles })
        this.props.changeFiles(allFiles, this.props.headerkey, 'file')

    }

    urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    dataURLtoFile(dataurl, filename) {
        // var mime = 'image/jpeg';
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        // this.setState({testBlob:bstr})

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }


        var ia = new Uint8Array(bstr.length);
        for (var i = 0; i < bstr.length; i++) {
            ia[i] = bstr.charCodeAt(i);
        }
        // return new File([u8arr], filename, {type:mime});
        // return new Blob([u8arr], {type:mime});
        return new Blob([ia], { type: mime });
    }

    blobToFile = (blob, fileName) => {
        // var b: any = theBlob;
        var myBlob = blob
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        myBlob.lastModifiedDate = new Date();
        myBlob.name = fileName;

        //Cast to a File() type
        return new File(myBlob);
    }


    _crop() {
        // image in dataUrl
        var images = this.state.images
        var files = this.state.files
        var dataURL = this.refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 1)
        images.push(dataURL)
        // files.push(this.blobToFile(this.dataURLtoFile(dataURL),'a.jpeg'))
        var file = this.dataURLtoFile(dataURL, 'a.jpg');
        files.push(file)
        this.setState({ images, files })
        this.props.changeFiles(files, this.props.headerkey, 'file')

        // this.setState({croppedImage: this.refs.cropper.getCroppedCanvas().toDataURL()})

        // var file = this.dataURLtoFile(dataURL, 'a.jpg');
        // this.setState({ file: file });
    }


    // dataURLtoFile(dataurl) {
    //     // var mime = 'image/jpeg';
    //     var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);

    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     var ia = new Uint8Array(bstr.length);
    //     for (var i = 0; i < bstr.length; i++) {
    //         ia[i] = bstr.charCodeAt(i);
    //     }

    //     return new Blob([ia], { type: mime });
    // }





    removeFile(key, index) {
        // var images = this.state.images
        var files = this.state.files
        // images.splice(index, 1)
        files.splice(index, 1)
        this.setState({ files })
    }
}

export default File;
