const uuidv1 = require('uuid/v1');

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) {
    // console.log("SET1")
    return arr;
  }

  const result = [...arr];
  let itemToAdd = convertItem(payload);

  // console.log("ITEMTOADD")
  // console.log(payload)

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
    // console.log("SET1")
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
    // console.log("SET2")
  }

  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

export const convertItem = (item) => {
  let information = {}
  if (item.headers) {
    item.headers.forEach(header => {
      // console.log(header.key)
      // console.log(typeof header.information.defaultValue)

      if ((typeof header.information.defaultValue == 'object') || (header.information.defaultValue != null && header.information.defaultValue != '')) {
        // console.log(header.information.defaultValue)

        information[header.key] = header.information.defaultValue
      }
    });
    let guid = uuidv1()
    // console.log(guid)
    return { type: item.type, key: guid, information: information }
  } else {
    return item
  }
};