const FilterOperators = {

    Equals: { operator: '$eq:***', title: 'برابر با' },
    Contains: { operator: '$regex:/***/', title: 'شامل' },
    StartsWith: { operator: '$regex:^/***/', title: 'Starts with' },
    EndsWith: { operator: '$regex:/***/^', title: 'Ends with' },
    Between: { operator: '$lt:***-gt:***', title: 'Between', fieldsCount: 2 }
}

const FilterFields = {

    textCell: {
        options: [
            { key: "Equals", default: true }, { key: 'Contains', default: false },
        ],

        fields: [{ placeHolder: '', type: 'textCell', default: true }]
    },

    numberCell: {
        fields: [{ placeHolder: '', type: 'numberCell', default: true }]
    },
    selectCell: {
        fields: [{ placeHolder: '', type: 'selectCell', default: true }]
    },

    selectApiCell: {
        fields: [{ placeHolder: '', type: 'selectApiCell', default: true }]
    },

    multiSelectApiCell: {
        fields: [{ placeHolder: '', type: 'selectApiCell', default: true }]
    },

    multiLevelCell: {
        fields: [{ placeHolder: '', type: 'multiLevelCell', default: true }]
    },

    switchCell: {
        fields: [{ placeHolder: '', type: 'switchCell', default: true }]
    },

    // numberCell: {
    //     options: [
    //         { key: "Equals", default: true }, { key: 'between' },
    //     ],
    //     fields: [{ placeHolder: 'از', type: 'numberCell', default: true }, { placeHolder: 'تا', type: 'numberCell' }]
    // },
}

// const FormBuilderItems = Object.values(FormBuilderObjects)


export { FilterFields, FilterOperators }