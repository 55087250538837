import React from "react";
import { domain, headers, colors, settings } from '../../Variables'
import Loader from 'react-loader-spinner'


class Header extends React.Component {
    state = {
        options: [],
        isLoading: false
    }


    componentWillMount() {
        this.init()
    }

    init() {
        // console.log(this.props)
        if (this.props.type == "local" || !this.props.type) {
            this.setState({ options: [] }, () => {
                this.setState({ options: this.props.info.options })

            })
        } else if (this.props.type == "api") {
            this.fetch()
            // }
        }
    }



    componentWillReceiveProps(newProps) {
        if (this.props.info.options != newProps.info.options) {
            // console.log("RECi")
            // console.log(this.props.info)
            // console.log(newProps.info)

            this.init()
        }
    }



    fetch() {

        this.setState({ isLoading: true })

        let address = domain + this.props.info.address
        // console.log(address)
        // console.log(this.props.info.filter)
        // console.log(typeof this.props.info.filter)
        let filter = typeof this.props.info.filter == "string" ? JSON.parse(this.props.info.filter) : this.props.info.filter
        // console.log(domain + '/values/' + this.props.info.address)
        fetch(address, {
            method: 'POST',
            // body: JSON.stringify(this.props.options.filter),
            body: JSON.stringify(filter),
            headers: headers
        }).then(response => response.json()).then(data => {
            // console.log(data)
            if (data.status == 200) {
                var newData = []
                data.info.forEach(element => {
                    var newTitle = this.spliter(element, this.props.info.titleField)

                    var newValue = this.spliter(element, '_id')

                    newData.push({ value: newValue, title: newTitle })
                });

                // console.log(newData)

                this.setState({
                    options: newData,
                    isLoading: false
                })
            }

        }).catch(error => this.setState({ error, isLoading: false }));
    }


    spliter(source, string) {
        var stringArray = string.split('.')
        var finalString = source
        stringArray.forEach(element => {
            finalString = finalString[element]
        });
        return finalString
    }


    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }

    onChange(e) {
        // console.log(e.target.value)
        this.props.onChange(this.props.headerkey,  e.target.value, this.props.extra)
    }


    render() {
        let info = this.props.info
        // console.log("title")
        // console.log(this.props.title)
        let value = (typeof this.props.title == "object") ? this.props.title._id : this.props.title
        // console.log(typeof value)
        // value = String(value)
        // console.log(this.props.title)

        return (
            <div className='mt-0 ' style={{ position: 'relative' }}>
                {/* <label className={'mb-2 mr-1 ml-1 ' + (info.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{info.label}</label> */}
                <div style={{ position: 'relative' }}>

                    <select value={value} onChange={e => { this.onChange(e) }} className='mediumiransansfont form-control' style={{ backgroundColor: '#f7f7f7', color: '#3b3a40' }}>
                        <option selected={'' == value || null == value} disabled={info.required ? true : false} hidden={info.required ? true : false} value={''}>{info.placeHolder ? info.placeHolder : 'Select one ...'}</option>
                        {this.state.options && this.state.options.map((prop, index) => {
                            // if (this.props.type == "api") {
                            //     console.log("value")
                            //     console.log(value)
                            //     console.log(prop.value)
                            // }
                            return (
                                <option value={prop.value} selected={prop.value == value}>{prop.title}</option>
                            )
                        })}
                    </select>

                    {this.state.isLoading && (
                        <div style={{ position: 'absolute', top: 8, left: 25 }}>
                            <Loader
                                type="Oval"
                                color="rgba(0,122,255,1)"
                                height="25"
                                width="25"
                            />
                        </div>
                    )}

                </div>

            </div>
        );
    }
}

export default Header;
