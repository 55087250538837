import React from "react";
import { Collapse } from 'react-collapse';
// import Checkbox from '../CustomCheckbox'
// import RadioBox from '../CustomRadiobox'
// used for making the prop types of this component
// import PropTypes from "prop-types";

class Header extends React.Component {

    state = {
        isOpen: true,
        seletedOption: null,

        // options:[]
    }

    componentWillMount() {
        // console.log(this.props.info)
        if (this.props.info.defaultValue) {
            let index = null
            this.props.info.options.forEach((option, i) => {
                // console.log(option.value + ' ' + this.props.info.defaultValue)
                if (option.value == this.props.info.defaultValue) {
                    index = i
                }
            });

            // console.log("HERE")
            // console.log(index)

            // let index = this.props.info.options.filter(a => a.value == this.props.info.defaultValue)
            this.setState({ seletedOption: index })
        }
    }


    // changeSelected = (index, checked) => {
    //     let selecteds = []
    //     if (this.props.type == 'multi')
    //         selecteds = this.state.seletedOptions
    //     selecteds[index] = checked

    //     for (const [key_, value_] of Object.entries(selecteds, index)) {
    //         if (value_ != true) {
    //             delete selecteds[index]
    //         }
    //     }

    //     this.setState({ seletedOptions: selecteds })
    //     console.log(index)
    // }

    addOption(index) {
        let options = [...this.props.info.options, { title: '', value: '' }]
        this.props.onChange(this.props.headerkey, options)
    }


    removeOption = (index) => {

        let options = [...this.props.info.options]
        options.splice(index, 1)

        if (index == this.state.seletedOption) {
            this.changeDefaultOption('', null)
        }
        if (index < this.state.seletedOption) {
            this.setState({ seletedOption: this.state.seletedOption - 1 })
        }

        this.props.onChange(this.props.headerkey, options)


    }


    changeOptionValue(value, index) {

        let options = [...this.props.info.options]
        options[index] = { title: value, value: value }
        this.props.onChange(this.props.headerkey, options)

        if (this.state.seletedOption == index) {
            console.log(value)
            this.props.onChange('defaultValue', value)
        }
    }


    changeDefaultOption(value, index) {
        // console.log(index)
        this.setState({ seletedOption: index })
        this.props.onChange('defaultValue', value)
        // let options = [...this.props.info.options]
        // this.props.onChange(this.props.headerkey, options)

    }

    render() {
        let info = this.props.info
        // console.log("HERE")
        // console.log(info)
        return (
            <div className='mt-0 '>

                {/* <label className={'mb-2 mr-1 ml-1 ' + (info.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{info.label}</label> */}


                <Collapse isOpened={this.state.isOpen}>
                    <div className="mt-1">

                        {this.state.seletedOption != null && (<p onClick={() => this.changeDefaultOption('', null)} style={{ color: '#abc', marginTop: 5, marginBottom: 5, fontSize: 10, textAlign: 'center', cursor: 'pointer' }}>Make without default</p>)}


                        {info.options.map((prop, index) => {
                            let data = prop
                            if (typeof prop != "object") {
                                data = { _id: prop, values: { name: prop } }
                                // console.log("NOT OBJECT")
                            }


                            return (
                                <div key={index} className={'pl-2 d-flex mt-2 align-items-center'}>
                                    <div className="mr-2">
                                        <div onClick={() => this.changeDefaultOption(prop.value, index)} style={{ backgroundColor: (this.state.seletedOption == index) ? '#007aff' : '#cde', width: 20, height: 20, borderRadius: 20, border: '0px solid #bcd' }}>
                                        </div>
                                    </div>

                                    <input className="no-outline" onChange={e => this.changeOptionValue(e.target.value, index)} style={{ width: '100%', backgroundColor: '#f4f6f8', borderRadius: 4, border: '1px #e6e8ef solid', height: 30, padding: 5 }} value={prop.value} />
                                    <div className="ml-1">
                                        <div onClick={() => this.removeOption(index)} style={{ borderRadius: 4, backgroundColor: '#007aff20', border: '1px solid #007aff25', height: 30, width: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                            <i className="fa fa-minus" style={{ color: '#007aff' }}></i>
                                        </div>
                                    </div>
                                    {/* {this.props.type == 'single' && (
                                        <SingleBox data={data} onChange={this.changeSelected} index={index} checked={this.state.seletedOptions[index] ? true : false} />
                                    )}

                                    {this.props.type == 'multi' && (
                                        <Checkbox data={data} onChange={this.changeSelected} index={index} checked={this.state.seletedOptions[index] ? true : false} />
                                    )}
                                    {this.props.type == 'radio' && (
                                        <RadioBox data={data} onChange={this.changeSelected} index={index} checked={this.state.seletedOptions[index] ? true : false} />
                                    )} */}
                                </div>
                            )
                        })}



                    </div>

                    <p onClick={() => this.addOption()} style={{ color: '#007aff', marginTop: 10, fontSize: 12, textAlign: 'center', cursor: 'pointer' }}>+ Add option</p>

                </Collapse>


                {/* <input disabled={info.disabled} onChange={e => { this.props.onChange(this.props.headerkey, e.target.value) }} className='form-control' defaultValue={this.props.title} placeholder={info.placeHolder} style={{ backgroundColor: '#f7f7f7', color: '#3b3a40' }} /> */}
            </div>
        );
    }
}

export default Header;
