import React from "react";
import FormEditModal from './Modals/FormEditModal'


class GuaranteePageHeader extends React.Component {

  constructor(prop) {
    super(prop)
    this.state = {
      users: [{ image: '/images/male.png' }, { image: '/images/female.png' },],
    }
  }

  formClicked = () => {
    this.refs.formEditModal.showModal()
  }


  render() {
    return (
      <div style={{ backgroundColor: '#fff', borderRadius: 8, padding: '20px 15px 20px 15px', marginBottom: 20 }}>
        <div className="row" style={{ paddingBottom: 0, alignItems: 'center' }}>

          <div className="col-6" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <img src="https://img.icons8.com/clouds/100/000000/form.png" style={{ height: 50 }} />
            <div className="mr-3">
              <p className={'mediumiransansfont'} style={{ marginRight: 0, marginTop: 0, fontSize: 11, opacity: 1, letterSpacing: 0, color: '#007aff', direction: 'ltr', cursor: 'pointer' }}>{this.props.description}</p>

              <p className={'mediumiransansfont'} style={{ marginRight: 0, marginTop: 0, fontSize: 22, color: '#000', fontWeight: 'bold' }}>{this.props.title.toUpperCase()}</p>
            </div>

          </div>

          {/* <div className="col-6 text-left">
            <div className='d-inline-flex text-right'>

              <div onClick={() => this.props.openFilter()} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: '#202020', padding: 3, paddingLeft: 3, paddingRight: 15, borderRadius: 20, boxShadow: '0px 0px 30px rgba(0,0,0,0.0)', margin: 3 }}>

                <div>
                  <p className={'iransansfont'} style={{ fontWeight: 'bold', marginRight: 0, marginTop: 0, fontSize: 12, color: '#fff' }}>جست و جوی پیشرفته</p>
                </div>
                <div className="mr-2" style={{ backgroundColor: '#fff', borderRadius: 50, padding: 0, display: 'flex', minWidth: 20, height: 20, justifyContent: 'center', alignItems: 'center', margin: 3 }}>
                  <i className='fa fa-info' style={{ fontWeight: 'bold', marginRight: 0, marginTop: 0, fontSize: 14, color: '#202020', fontWeight: 'bold' }}></i>
                </div>
              </div>
            </div>
          </div> */}


        </div>

        {/* <FormEditModal ref={'formEditModal'} /> */}

      </div>
    );
  }
}

export default GuaranteePageHeader;
