import React from "react";
import PersonCell from './Table/Person'
import TextCell from './Table/Text'
import ButtonCell from './Table/Button'
import StatusCell from './Table/Status'
import SelectCell from './Table/Select'
import moment from 'jalali-moment'
import Modal from './Modal'
import { withRouter } from 'react-router-dom'
import { contentUrl, content } from "../Variables";
import Loader from 'react-loader-spinner'
import { imageAddress } from '../useful'
import stripHtml from "string-strip-html";

class TablePage extends React.Component {

  constructor(prop) {
    super(prop)
    this.state = {
      tabs: ['امروز', 'این هفته', 'این ماه', 'ماه بعد'],
      activeTab: 0,
      data: []

    }
  }




  tabClicked = (index) => {
    this.setState({ activeTab: index })
  }



  renderHeaders = () => {

    return (this.props.headers.map((prop, key) => {
      // console.log(prop.information.label)
      if (prop.information.showMain) {
        return (
          <th key={key} style={{ borderRightWidth: 0, borderRightColor: '#fff', borderRightStyle: 'solid', padding: 0, paddingBottom: 0, paddingTop: 15, paddingBottom: 5 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className="mediumiransansfont" style={{ paddingRight: key == 0 ? 32 : 0, margin: 7, marginTop: 0, alignSelf: 'stretch', flex: 1, fontSize: 12, color: '#000', textAlign: key == 0 ? 'center' : 'center' }}>{prop.information.label}</p>
            </div>
          </th>
        );
      }
    }))
  }


  renderBody = () => {
    return (this.props.data.map((prop, key) => {
      return (
        this.renderRow(key, prop)

      )
    }

    )
    )
  }

  checkKey(values, key) {
    if (key && values) {
      let value = null
      if (key.includes('.')) {
        let split = key.split('.')
        // console.log(split)
        // console.log(values)
        if (split[0] && values[split[0]]) {
          value = values[split[0]][split[1]]
        }
        // } 
        // else if (key.includes('*')) {
        //   let split = key.split('*')

        //   if (values[split[0]]) {

        //     values[[split[0]]].forEach(element => {
        //       for (const [key_, value_] of Object.entries(element)) {
        //         if (key_ == split[1]) {
        //           value = value_
        //           // console.log("***")
        //           // console.log(value)
        //         }
        //       }
        //     });
        //   }
      } else {
        value = values[key]
      }

      return value
    }
  }


  changeToPriceValue(value) {
    // console.log(value)
    let newValue = String(value).split('')
    let final = ''
    let count = 0
    for (let i = newValue.length - 1; i >= 0; i--) {
      final = newValue[i] + final
      count++
      if (count == 3 && i != 0) {
        final = ',' + final
        count = 0
      }
    }

    return final
  }


  getObjectLevel(value, param, cb) {
    if (value) {
      if (param && param.includes('.')) {
        // console.log(value)
        // console.log(param)
        // console.log(value.param)

        // console.log(param.split('.')[0])
        if (value && value[param.split('.')[0]]) {
          this.getObjectLevel(value[param.split('.')[0]], param.split('.')[1], cb)

        } else {
          cb('')
        }
      } else {
        // console.log(value)
        // console.log(param)
        cb(value[param])
      }
    } else {
      cb(null)
    }
  }


  getPageHelper() {
    let helper = null
    if (this.props.page) {
      if (this.props.page.helperKey && this.props.page.helperKey != '') {
        helper = this.props.page.helperKey
      }
    }

    return helper
  }

  renderRow = (index, prop) => {
    // console.log(prop)
    return (
      <tr key={index} style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff', borderTopStyle: 'solid', borderWidth: 1, borderColor: '#f9f9f9', paddingRight: 0, height: 45 }} >

        <td style={{ width: 30, alignItems: 'center', padding: 0, borderRightColor: '#fff', borderRightWidth: 0, borderBottomColor: '#fff', borderBottomWidth: 0, borderBottomStyle: 'solid', borderRightStyle: 'solid', borderTopStyle: 'none', borderRadius: 0, overflow: 'visible' }}  >
          <p className="pr-4" style={{ color: '#abc' }}>{index + 1}</p>
        </td>


        {this.props.headers.map((headerProp, headerIndex) => {

          if (!headerProp.information.showMain)
            return

          // console.log(prop)
          // console.log(this.getPageHelper())
          let values = {}

          if (!this.getPageHelper()) {
            values = prop
          } else {
            values = prop[this.getPageHelper()]
          }
          // console.log(values)
          let value = this.checkKey(values, headerProp.key)


          if (headerProp.type == 'selectApiCell') {
            this.getObjectLevel(value, headerProp.information.titleField, (value1) => {
              // console.log("VALUE")
              // console.log(value1)
              value = value1
            })
          }

          if (headerProp.type == 'dateCell') {
            value = (value && value != '') ? moment(value).locale('fa').format('jYYYY - jMMMM - jDD') : ''
          }

          if (headerProp.type == 'priceCell') {
            value = this.changeToPriceValue(value)
          }
          // console.log(values)

          // if (typeof value == 'object') {
          //   value = value[headerProp.information.titleField]
          // }

          // console.log(value)


          return (
            <td key={headerIndex} className={this.props.className} style={{ minWidth: 140, alignItems: 'center', padding: 0, borderRightColor: '#fff', paddingRight: 15, borderRightWidth: 0, borderBottomColor: '#fff', borderBottomWidth: 0, borderBottomStyle: 'solid', borderRightStyle: 'solid', borderTopStyle: 'none', borderRadius: 0, overflow: 'visible', paddingRight: headerIndex == 0 ? 20 : 0 }}  >
              {headerProp.type == 'person' && (
                <PersonCell title={value} />
              )}
              {headerProp.type == 'textCell' && (
                <TextCell title={value} />
              )}

              {headerProp.type == 'textAreaCell' && (
                <TextCell title={value} />
              )}

              {headerProp.type == 'dateCell' && (
                <TextCell title={value} />
              )}
              {headerProp.type == 'selectApiCell' && (
                <TextCell title={value} />
              )}

              {headerProp.type == 'numberCell' && (
                <TextCell title={value} />
              )}

              {headerProp.type == 'priceCell' && (
                <TextCell title={value} />
              )}

              {headerProp.type == 'editorCell' && (
                <p className="text-center">{value ? stripHtml(value) : 0}</p>
              )}

              {headerProp.type == 'subformCell' && (
                <p className="text-center">{value ? value.length : ''}</p>
              )}


              {(headerProp.type == 'imageCell') && (
                <div className="text-center d-flex justify-content-center w-100" style={{ overflow: 'auto' }}>
                  {Array.isArray(value) && value.map((image, index) => {
                    // console.log(image)
                    return (
                      <img key={index} src={imageAddress(image, "mini", 'mini')} style={{ width: 30, height: 30, borderRadius: 20, objectFit: 'cover', marginLeft: 2 }} />
                    )
                  })
                  }

                  {!Array.isArray(value) && value && (
                    <img src={imageAddress(value, "mini", "mini")} style={{ width: 30, height: 30, borderRadius: 20, objectFit: 'cover' }} />
                  )}

                </div>

              )}


              {(headerProp.type == 'videoCell') && value && (
                <div className="text-center d-flex justify-content-center w-100" style={{ overflow: 'auto' }}>

                  <img src={content + 'videos/thumbnails/' + value.cover} style={{ width: 30, height: 30, borderRadius: 20, objectFit: 'cover' }} />


                </div>

              )}





              {headerProp.type == 'switchCell' && (
                <TextCell title={value} />
              )}

              {headerProp.type == 'button' && (
                <ButtonCell title={headerProp.title} function={() => this.butAction(index, headerProp.function)} options={headerProp.options ? headerProp.options : {}} />
              )}

              {headerProp.type == 'status' && (
                <StatusCell title={value} />
              )}

              {headerProp.type == 'selectCell' && (
                <SelectCell title={value} information={headerProp.information} type={'local'} />
              )}

              {/* {headerProp.type == 'selectApiCell' && (
                <SelectCell title={value} information={headerProp.information}  type={'api'} />
              )} */}


            </td>
          )
          // }
        })}

        <td style={{ minWidth: 140, alignItems: 'center', padding: 0, borderRightColor: '#fff', paddingRight: 15, borderRightWidth: 0, borderBottomColor: '#fff', borderBottomWidth: 0, borderBottomStyle: 'solid', borderRightStyle: 'solid', borderTopStyle: 'none', borderRadius: 0, overflow: 'visible', paddingRight: 0 }}  >

          <ButtonCell title={(this.props.actions.length+ " عملیات ")} function={() => this.props.openActionsModal(index)} options={{ color: "#78b2d0" }} />

        </td>
      </tr>
    )
  }

  render() {
    return (
      <div style={{ padding: '0px 20px 0px 20px' }}>

        <div className="row">
          <div className="col-12" style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3, backgroundColor: '#fff', padding: '10px 15px 10px 15px', borderRadius: 8 }}>


            <div onClick={() => this.props.addAction()} style={{ borderBottom: '1px solid #f7f7f7', transition: 'all 0.3s', marginLeft: 0, cursor: 'pointer', backgroundColor: '#202020', padding: '5px 5px 5px 5px', minWidth: 70, borderTopLeftRadius: 4, borderTopRightRadius: 20, borderRadius: 20, marginBottom: 0, display: 'flex', alignItems: 'center' }} >

              <span className="mr-2" style={{ color: '#fff', margin: 0, textAlign: 'center', fontSize: 12, direction: 'ltr', lineHeight: 1, fontWeight: 'bold' }}>افزودن </span>
              <div className="mr-2" style={{ backgroundColor: '#fff', borderRadius: 50, padding: 0, display: 'flex', minWidth: 20, height: 20, justifyContent: 'center', alignItems: 'center' }}>
                <i className="fa fa-plus" style={{ color: '#202020' }}></i>
              </div>
            </div>


            <div style={{ direction: 'rtl', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              {/* <p></p> */}
              {/* <i className="fa fa-filter" style={{ marginLeft: 10, fontSize: 20, marginBottom: 0, color: '#323232', opacity: 0.3 }}></i> */}


              {/* {this.state.tabs.map((prop, index) => {
                return (
                  <div key={index} onClick={() => this.tabClicked(index)} style={{ borderBottom: '0px solid #f7f7f7', transition: 'all 0.3s', marginLeft: 3, cursor: 'pointer', backgroundColor: index == this.state.activeTab ? '#789' : '#f7f7f7', padding: '7px 15px 7px 15px', minWidth: 70, borderTopLeftRadius: 8, borderTopRightRadius: 8, borderRadius: 30, textAlign: 'center' }} >
                    <p style={{ color: this.state.activeTab == index ? '#fff' : '#789', margin: 0, textAlign: 'center', fontSize: 12, fontWeight: 'bold' }}>{prop}</p>
                  </div>
                )
              })} */}



              {/* <div> */}
              <div className="flexcc">
                {this.props.page.applet == 'questions' && (
                  <p onClick={() => this.props.openQuestions()} className="mt-2" style={{ color: '#007aff', cursor: 'pointer' }}>مشاهده سوالات</p>
                )}
                <i className="fa fa-search" onClick={() => this.props.openFilter()} style={{ marginRight: 10, fontSize: 20, marginBottom: 0, color: '#323232', opacity: 0.3 }}></i>
              </div>
              {/* </div> */}
            </div>


          </div>

          <div className="col-12 no-scrollbar" style={{ marginTop: 0, overflow: 'scroll', padding: 0, paddingBottom: 10, borderRadius: 8 }}>

            <div style={{ overflowX: 'visible', display: 'flex', direction: 'rtl', padding: 0 }}>

              <div className={'lightiransansfont'} style={{ flex: 1 }} >
                <table className="table" style={{ borderColor: '#f9f9f9', borderStyle: 'solid', borderWidth: 0 }}>

                  <thead style={{ borderStyle: 'none' }}>
                    <tr style={{ borderStyle: 'none', backgroundColor: '#fff' }}>

                      <th width={20} style={{ borderRightWidth: 0, borderRightColor: '#fff', borderRightStyle: 'solid', padding: 0, paddingBottom: 0, paddingTop: 15, paddingBottom: 5 }}>
                      </th>

                      {this.renderHeaders()}

                      <th style={{ borderRightWidth: 0, borderRightColor: '#fff', borderRightStyle: 'solid', padding: 0, paddingBottom: 0, paddingTop: 15, paddingBottom: 5 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className="mediumiransansfont" style={{ paddingRight: 0, margin: 7, marginTop: 0, alignSelf: 'stretch', flex: 1, fontSize: 12, color: '#3b3a40', textAlign: 'center' }}>عملیات ها</p>
                        </div>
                      </th>

                    </tr>
                  </thead>
                  <tbody >

                    {this.renderBody()}


                    <tr >
                      <td colSpan="100%" style={{ borderStyle: 'none', padding: 0 }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff', borderTopStyle: 'solid', borderWidth: 1, borderColor: '#fff', paddingRight: 0, borderRadius: '0px 0px 10px 10px', minHeight: 10 }} >


                          {(!this.props.isLoadingData && this.props.data.length == 0) && (
                            <div className="text-center mt-2 mb-3 " style={{ paddingBottom: 33 }}>
                              <p style={{ fontSize: 12, color: '#789' }}>موردی برای نمایش یافت نشد</p>
                            </div>
                          )}

                          {this.props.isLoadingData && (
                            <div className="text-center mt-2 mb-2 pb-3">
                              <Loader
                                type="ThreeDots"
                                color="#789"
                                height="35"
                                width="35"
                              />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default withRouter(TablePage);
