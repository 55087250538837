import React from "react";
import TablePage from './TablePage'
import TablePageHeader from './TablePageHeader'
import RemoveModal from './Modals/RemoveModal'
import ItemModal from './Modals/ItemModal'
import { pagesData } from '../PagesData'
import { Route, Redirect } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { domain, headers, colors, settings } from '../Variables'
import Sidebar from "./SidebarR.jsx";
import dashboardRoutes from "../routers.jsx";
import Footer from "./Footer.jsx";

class RegularTables extends React.Component {

  constructor(prop) {
    super(prop)
    this.state = {
      data: [],
      removeData: null,
      page: null,
      modalIsOpen: false
    }
  }



  componentWillMount() {
    this.updateSize()
    window.addEventListener('resize', this.updateSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
  }

  updateSize = () => {
    if (window.innerWidth < 720) {
      this.setState({ size: 'mobile' })
      // this.setState({ isOpen: false })

    } else {
      this.setState({ isOpen: false })
      this.setState({ size: 'desktop' })
    }
  }



  render() {
    // if (this.state.page) {
    return (

      <div className={" mediumiransansfont"} style={{ transition: 'all 0.5s', textAlign: 'right', paddingTop: 0, width: '100%', height: '100vh', overflow: 'hidden' }} >

        <div style={{ width: this.state.size == "desktop" ? 'calc(100% - 240px)' : '100%', marginRight: this.state.size == "desktop" ? 'calc(1% + 240px)' : '0px', borderRadius: 15, paddingTop: '3%', paddingBottom: '0%', overflow: 'auto', height: '100%' }}>
          {/* <div style={{ paddingRight: '6%', paddingLeft: '6%', }}> */}
          <div style={{ minHeight: '87vh' }}>
            {this.props.children}
          </div>

          {/* <div style={{position:'relative' }}> */}
          <Footer />
          {/* </div> */}
          {/* </div> */}
        </div>

        <Sidebar
          {...this.props}
          routes={dashboardRoutes}
          modules={this.props.modules}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          isOpen={this.state.size != "mobile"}
          location={this.props.location}
        />

      </div>



    );
    // } else {
    //   return (<div></div>)
    // }
  }

}

export default RegularTables;
