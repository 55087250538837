import React from "react";

// used for making the prop types of this component
// import PropTypes from "prop-types";

class Header extends React.Component {
    state = {
        value: ''
    }

    componentWillMount() {
        // console.log(this.props.title)
        if (this.props.title)
            this.setState({ value: this.props.title })
    }

    componentWillReceiveProps(newProps) {
        // console.log("recieved")
        // console.log(newProps.title)

        if (this.props.title != newProps.title) {
            // console.log('MATCH')

            this.setState({ value: newProps.title })
        }
    }

    handleChange(evt) {
        console.log(evt.target.value)
        console.log(evt.target.validity.valid)
        const value = (evt.target.validity.valid) ? evt.target.value : this.state.value;
        console.log(value)
        this.setState({ value });
        if (evt.target.validity.valid) {
            // console.log(evt.target.value)
            this.props.onChange(this.props.headerkey, Number(evt.target.value), this.props.extra)
        }
    }

    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }


    render() {
        let info = this.props.info

        return (
            <div className='mt-0 ' >
                {/* <label className={'mb-2 mr-1 ml-1 ' + (this.conditionalSettings('required', true) ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{this.props.label}</label> */}
                <input pattern="[0-9]*" onInput={this.handleChange.bind(this)} disabled={this.conditionalSettings('disabled', true)} className='mediumiransansfont form-control' value={this.state.value ? this.state.value : ''} placeholder={info.placeHolder} style={{ backgroundColor: '#f7f7f7', borderColor: '#ddd', color: '#222', height: 35 }} />
            </div>
        );
    }
}

export default Header;
