import React from "react";
import Modal from "../Modal.jsx";
import { Container, Draggable } from 'react-smooth-dnd';
import { FormBuilderItems, FormBuilderObjects } from '../../Jsons/FormBuilderData'
const uuidv1 = require('uuid/v1');

// used for making the prop types of this component
// import PropTypes from "prop-types";

class Header extends React.Component {

    state = {
        fields: []
    }


    componentWillMount() {
        
        if (this.props.info.headers && this.props.info.headers.length > 0)
            this.setState({ fields: this.props.info.headers })
    }

    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }

    openModal() {
        this.refs.addItemModal.showModal()
    }


    componentWillReceiveProps(newProps) {

        if (!newProps.isSelected) {

            if (this.state.selectedIndex != null) {
                this.props.changeSubIndex(null)
                this.state.selectedIndex = null
            }
        }
    }

    renderForm = (item) => {

        const MyComponent = this.props.components[item.type]
        let info = Object.assign({}, item.information)
        info.disabled = true
        // console.log(item)
        return (
            <div className={'mt-0'}>
                <label className={'mb-1 mr-1 ml-1 mt-1 ' + (info.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 13, color: '#456', fontWeight: 'bold' }}>{info.label}</label>
                <MyComponent info={info} title={info.defaultValue} />
            </div>

        )

    }


    addField(field) {
        this.refs.addItemModal.hideModal()

        // console.log(this.convertItem(field))
        this.state.fields.push(this.convertItem(field))

        this.props.onChange(this.props.headerkey, this.state.fields)
    }


    convertItem(item) {
        let information = {}
        if (item.headers) {
            item.headers.forEach(header => {

                if ((typeof header.information.defaultValue == 'object') || (header.information.defaultValue != null && header.information.defaultValue != '')) {
                    information[header.key] = header.information.defaultValue
                }
            });
            let guid = uuidv1()
            return { type: item.type, key: guid, information: information }
        } else {
            return item
        }
    };


    changeIndex(index) {
        this.props.changeSubIndex(null)
        this.setState({ selectedIndex: null }, () => {
            this.setState({ selectedIndex: index })
            this.props.changeSubIndex(index)

        })
    }

    changeSort(headers,e) {

        const { removedIndex, addedIndex, payload } = e



        const result = [...headers];
        let itemToAdd = payload;

        if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0]
            // console.log("SET1")
        }

        if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd);
            // console.log("SET2")
        }

        this.props.onChange(this.props.headerkey, result)
        this.props.changeSubIndex(null)
        // this.setState({ selectedIndex: null, fields: result })


    }



    removeItem(index) {

        setTimeout(() => {
            this.setState({ selectedIndex: null }, () => {

                this.state.fields.splice(index, 1)
                this.props.onChange(this.props.headerkey, this.state.fields)
                this.props.changeSubIndex(null)
            })

        }, 50)
    }

    duplicateItem(index) {

        setTimeout(() => {
            this.setState({ selectedIndex: null }, () => {

                let items = [...this.state.fields]
                let item = JSON.stringify(this.state.fields[index])
                item = JSON.parse(item)
                item.key = uuidv1()
                items.splice(index + 1, 0, item)

                this.props.onChange(this.props.headerkey, items)
                this.props.changeSubIndex(null)
            })

        }, 50)

    }

    render() {
        let info = this.props.info
        return (
            <div className='mt-0 '>
                {/* <label className={'mb-2 mr-1 ml-1 ' + (info.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{info.label}</label> */}
                <div disabled={info.disabled} className='form-control' style={{ backgroundColor: '#fff', borderColor: '#e0e0e0', color: '#222', minHeight: 35, padding: 8 }} >

                    {info.headers && info.headers.length > 0 ? (
                        <Container groupName={"subform" + this.props.headerkey} style={{ zIndex: 1, paddingBottom: 0, overflow: 'visible' }}  getChildPayload={i => info.headers[i]} onDrop={e => { this.changeSort(info.headers,e) }}>
                            {info.headers.map((p, i) => {
                                return (
                                    <Draggable groupName={"subform" + this.props.headerkey} onClick={() => this.changeIndex(i)} key={i} className="pl-3 pr-3 pt-0 pb-3" style={{ position: 'relative', overflow: 'visible', position: 'relative', cursor: 'pointer', transition: 'all 0.5s', borderRadius: 10, backgroundColor: (this.state.selectedIndex == i && this.props.isSelected) ? '#ffd20010' : '#ffffff40', border: (this.state.selectedIndex == i && this.props.isSelected) ? '2px dotted #ffd20040' : '2px dotted #ffffff50', }}>
                                        <div className="" className="draggable-item" >
                                            {this.renderForm(p)}
                                        </div>

                                        {p.type != 'subformCell' && (<div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: 10 }}>
                                        </div>)}

                                        {this.state.selectedIndex == i && (
                                            <div style={{ position: 'absolute', top: 4, right: 15, padding: 0, backgroundColor: 'transparent', borderRadius: 4, zIndex: 20 }}>
                                                <img onClick={() => this.duplicateItem(i)} width={20} src="https://image.flaticon.com/icons/svg/760/760848.svg" style={{ cursor: 'pointer' }} />
                                                <img onClick={() => this.removeItem(i)} width={20} src="https://image.flaticon.com/icons/svg/1000/1000883.svg" style={{ cursor: 'pointer' }} className="ml-2 mr-2" />
                                            </div>
                                        )}


                                    </Draggable>
                                )
                            })
                            }
                        </Container>
                    ) : (<div style={{ marginBottom: 8 }}></div>)}

                    <div style={{ textAlign: 'center', marginBottom: 10, marginTop: 5 }}>
                        <div style={{ display: 'inline-block' }}>
                            <p onClick={() => this.openModal()} style={{ fontSize: 14, color: '#fff', cursor: 'pointer', padding: '3px 15px 3px 15px', background: '#202020', borderRadius: 30 }}><i className="fa fa-plus"></i> ADD FIELD</p>
                        </div>
                    </div>
                </div>


                <Modal ref={'addItemModal'} maxWidth={400}>
                    <div>
                        <p style={{ color: '#202020', fontSize: 16, fontWeight: 'bold' }}>ADD FIELD</p>
                        <p style={{ color: '#abc', fontSize: 10 }}>TO THIS SUBFORM</p>
                        <div className="row">
                            {FormBuilderItems.map((prop, index) => {
                                if (!prop.notInSubform)
                                    return (
                                        <div onClick={() => this.addField(prop)} className="col-4 p-0 d-flex" key={index}>
                                            <div className="draggable-item text-center" style={{ cursor: 'grab', backgroundColor: '#fff', width: '100%', border: '1px solid #f4f6f8', marginTop: -1, padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <img className="mb-1 mt-2" src={prop.icon} height={50}></img>
                                                <p style={{ fontSize: 12, color: '#222', margin: 0, padding: 0, fontFamily: 'arial', fontWeight: 'normal', marginTop: 3, fontWeight: 'bold', whiteSpace: 'nowrap', marginBottom: 5 }}>{prop.name}</p>
                                            </div>
                                        </div>
                                    );
                            })
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Header;
