import React from "react";


class ChatListItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div className="chat-list-item w-100 d-flex p-2" onClick={() => this.props.goTochatPage(this.props.index)} style={{ backgroundColor: this.props.selectedIndex == this.props.index ? '#cde' : null }}>
                <div style={{width:40,height:40}}>
                    <img src="/images/male.png"  style={{ objectFit: 'cover', backgroundColor: '#eee', borderRadius: 40 }} />
                </div>
                <div className='ml-2 w-100'>
                    <div className="d-flex justify-content-between">
                        <span style={{ fontSize: 14, color: '#456',fontWeight:'bold' }}>Usersname</span>
                        <span style={{ fontSize: 13, color: '#789' }}>14:12 PM</span>

                    </div>
                    <span style={{ fontSize: 13, color: '#789', textOverflow: 'ellipsis' }}>The last message Would be shown here</span>

                </div>
            </div>
        )
    }
}

export default ChatListItem