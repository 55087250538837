import React from "react";
// used for making the prop types of this component
// import PropTypes from "prop-types";

class Header extends React.Component {

    state = {
        value: [],
        newValue: '',
        isOpen: true
    }

    componentWillMount() {
        if (this.props.title)
            this.setState({ value: this.props.title })
    }


    componentWillReceiveProps(newProps) {

        console.log("recieved")
        console.log(newProps.title)
        if (this.props.title != newProps.title) {
            console.log("MATCH")
            this.setState({ value: newProps.title })
        }
    }

    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }

    handleChange(evt) {

        this.setState({ newValue: evt.target.value });
        // this.props.onChange(this.props.headerkey, evt.target.value, this.props.extra)
    }

    addValue() {
        if (this.state.newValue != '' && this.state.newValue) {
            let value = this.state.value
            value.splice(0, 0, this.state.newValue)
            this.setState({ value, newValue: '' })
            this.props.onChange(this.props.headerkey, value, this.props.extra)
        }
    }


    render() {
        let info = this.props.info
        return (
            <div className='mt-0'>
                {/* <label className={'mb-2 mr-1 ml-1 ' + (info.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{info.label}</label> */}
                <div className="d-flex">
                    <input disabled={info.disabled} value={this.state.newValue} onChange={e => { this.handleChange(e) }} className='form-control mediumiransansfont' placeholder={info.placeHolder} style={{ backgroundColor: '#f7f7f7', borderColor: '#ddd', color: '#222', height: 35 }} />
                    <button onClick={() => this.addValue()} className="mediumiransansfont " style={{ backgroundColor: '#007aff', padding: '2px 7px 2px 7px', borderRadius: 4, color: '#fff' }}>افزودن</button>
                </div>
                {!this.state.isOpen && this.state.value.length > 0 && (
                    <p onClick={() => this.setState({ isOpen: true })} className="mt-2" style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 13, color: '#007aff' }}>نمایش همه {this.state.value.length}</p>
                )}

                {this.state.value.length == 0 && (
                    <p onClick={() => this.setState({ isOpen: true })} className="mt-1" style={{ fontSize: 10, color: '#9ab', fontWeight: 'bold' }}>گزینه ای ثبت نشده است</p>
                )}

                {this.state.isOpen && this.state.value.length > 0 && (
                    <div className="mt-1" style={{ backgroundColor: '#f3f6f9', borderRadius: 4, padding: 10 }}>

                        <p onClick={() => this.setState({ isOpen: false })} className="mb-1 mt-1" style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 11, color: '#007aff' }}>عدم نمایش گزینه ها</p>

                        {this.state.value && Array.isArray(this.state.value) && this.state.value.map((prop, index) => {
                            return (
                                <p style={{ fontSize: 13, color: '#789' }}>{prop}</p>
                            )
                        })}
                    </div>
                )}

            </div>
        );
    }
}

export default Header;
