import React from "react";
import { Container, Draggable } from 'react-smooth-dnd';
import { applyDrag, generateItems } from '../utils';
// import TextCell from '../components/Inputs/Text'
import { FormBuilderItems, FormBuilderObjects } from '../Jsons/FormBuilderData'


// import VideoCell from './Inputs/Video'

import TextCell from './Inputs/Text'
import NumberCell from './Inputs/Integer'
import PriceCell from './Inputs/Price'

import DateCell from './Inputs/Date'

import FileCell from './Inputs/File'
import PhoneCell from './Inputs/PhoneInput'

import ImageCell from './Inputs/Image'
import VideoCell from './Inputs/Video'

import SwitchCell from './Inputs/Switch'
import EditorCell from './Inputs/Editor'
import SelectCell from './Inputs/Select'
import MultiLevelSelect from './Inputs/MultiLevelSelect'
import MultiSelect from './Inputs/MultiSelect'
import MapCell from './Inputs/MapInput'
import TextAreaCell from './Inputs/TextAreaInput'
import OptionMakerCell from './Inputs/OptionMaker'


import SubformCell from './Inputs/Subform'
// import ProductCell from './Inputs/Product'
import { Collapse } from 'react-collapse'

const uuidv1 = require('uuid/v1');



class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stage: 1,
            selectedIndex: null,
            itemsList: [],
            isLoading: false,
            collapseMainInfo: false,
            subFormIsSelected: false
        }
    }


    components = {
        textCell: TextCell,
        dateCell: DateCell,
        imageCell: ImageCell,
        fileCell: FileCell,
        switchCell: SwitchCell,
        editorCell: EditorCell,
        selectCell: SelectCell,
        selectApiCell: SelectCell,
        subformCell: SubformCell,
        numberCell: NumberCell,
        priceCell: PriceCell,
        phoneCell: PhoneCell,
        multiLevelCell: MultiLevelSelect,
        multiSelectApiCell: MultiSelect,
        videoCell: VideoCell,
        mapCell: MapCell,
        textAreaCell:TextAreaCell


    };







    renderForm = (item, index) => {

        const MyComponent = this.components[item.type]
        let info = Object.assign({}, item.information)
        info.disabled = true
        let isSelected = this.state.selectedIndex == index

        return (
            <div className={'mt-0'}>
                <label className={'mb-2 mr-1 ml-1 mt-2 ' + (info.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 13, color: '#456', fontWeight: 'bold' }}>{info.label}</label>
                <MyComponent onChange={this.changeSelfValue} headerkey={item.key} info={info} title={info.defaultValue} components={item.type == 'subformCell' ? this.components : null} changeSubIndex={item.type == 'subformCell' ? this.changeSubIndex : null} isSelected={isSelected} />
            </div>
        )
    }


    changeSubIndex = (index, cb) => {
        if (index != null) {
            this.setState({ collapseMainInfo: true, subFormIsSelected: true, selectedSubIndex: index })

        } else {
            this.setState({ collapseMainInfo: false, subFormIsSelected: false, selectedSubIndex: null })
        }
    }


    changeSelfValue = (key, value) => {
        // console.log("HERE!")
        // console.log(value)

        let itemsList = this.props.itemsList

        // console.log("ITEMLIST")
        // console.log(itemsList)

        // console.log(itemsList[this.state.selectedIndex].information.defaultValue)
        if (this.state.selectedIndex) {
            itemsList[this.state.selectedIndex].information.headers = value
        }
        // console.log(itemsList)
        // this.setState({ itemsList })

        this.changeItemsList(itemsList)
    }


    removeItem(index) {
        let items = this.props.itemsList
        items.splice(index, 1)
        this.setState({ selectedIndex: null }, () => {
            // this.setState({ itemsList: items })
            this.changeItemsList(items)
        })
    }

    duplicateItem = (index) => {
        let items = this.props.itemsList
        let item = JSON.stringify(items[index])//Object.assign({},this.props.itemsList[index])//{type:'textCell',information:{}}//this.props.itemsList[index]
        item = JSON.parse(item)
        item.key = uuidv1()
        items.splice(index + 1, 0, item)

        this.changeItemsList(items)
        // this.setState({ itemsList: items })
        // })
    }

    changeIndex(index) {
        console.log(index)
        console.log(this.state.selectedIndex)
        if (this.state.selectedIndex != index || this.state.selectedIndex == null)
            this.setState({ selectedIndex: null }, () => {
                this.setState({ selectedIndex: index, collapseMainInfo: false })
            })
    }


    changeItemsList(items) {
        this.props.changeHeaders(items)
    }


    render() {
        return (


            <div className={"w-100 mediumiransansfont"} >
                <div className="row">

                    <div className="col-3">
                        <div className="box p-0">
                            <div className="p-2 text-center mb-1" style={{ backgroundColor: '#fcfcfc', borderBottom: '#f7f7f7 1px solid' }}>
                                <p className="mt-2 font-weight-bold" >FIELDS</p>
                                <p style={{ fontSize: 10, color: '#456', letterSpacing: 1 }}>FORMS AND TABLES</p>
                            </div>
                            <Container style={{ display: 'flex', flexWrap: 'wrap' }} groupName="main" behaviour="copy" getChildPayload={i => FormBuilderItems[i]} onDrop={e => applyDrag(FormBuilderItems, e)}>
                                {FormBuilderItems.map((p, i) => {

                                    return (
                                        <Draggable className="col-6 p-0 d-flex" key={i}>
                                            <div className="draggable-item text-center" style={{ cursor: 'grab', backgroundColor: '#fff', width: '100%', border: '1px solid #f4f6f8', borderTop: 'none', padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <img className="mb-1 mt-2" src={p.icon} height={50}></img>
                                                <p style={{ fontSize: 12, color: '#222', margin: 0, padding: 0, fontFamily: 'arial', fontWeight: 'normal', marginTop: 3, fontWeight: 'bold', whiteSpace: 'nowrap', marginBottom: 5 }}>{p.name}</p>
                                            </div>
                                        </Draggable>
                                    );
                                })
                                }
                            </Container>

                        </div>
                    </div>


                    <div className="col-6">
                        <div className="box" style={{ overflow: 'visible', paddingLeft: 10, paddingRight: 10, paddingTop: 20, paddingBottom: 20 }}>

                            <Container groupName="main" style={{ zIndex: 1, paddingBottom: 10, overflow: 'visible' }} groupName="main" getChildPayload={i => this.props.itemsList[i]} onDrop={e => { this.changeItemsList(applyDrag(this.props.itemsList, e)); this.setState({ selectedIndex: null }) }}>
                                {this.props.itemsList.map((p, i) => {
                                    return (
                                        <Draggable groupName="main" onClick={() => this.changeIndex(i)} key={i} className="pl-3 pr-3 pt-0 pb-3" style={{ position: 'relative', overflow: 'visible', position: 'relative', cursor: 'pointer', transition: 'all 0.5s', borderRadius: 10, backgroundColor: this.state.selectedIndex == i ? '#007aff05' : '#ffffff40', border: this.state.selectedIndex == i ? '2px dotted #007aff20' : '2px dotted #ffffff50' }}>
                                            <div className="" className="draggable-item" >
                                                {this.renderForm(p, i)}
                                            </div>

                                            {p.type != 'subformCell' && (<div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: 10 }}>
                                            </div>)}

                                            {this.state.selectedIndex == i && (
                                                <div style={{ position: 'absolute', top: 8, right: 15, padding: 0, backgroundColor: 'transparent', borderRadius: 4, zIndex: 20 }}>
                                                    <img onClick={() => this.duplicateItem(i)} width={20} src="https://image.flaticon.com/icons/svg/760/760848.svg" style={{ cursor: 'pointer' }} />
                                                    <img onClick={() => this.removeItem(i)} width={20} src="https://image.flaticon.com/icons/svg/1000/1000883.svg" style={{ cursor: 'pointer' }} className="ml-2 mr-2" />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })
                                }
                            </Container>
                            {this.props.itemsList.length == 0 && (
                                <div style={{ position: 'absolute', top: 18, textAlign: 'center', width: '100%', color: '#789', fontSize: 12 }}>
                                    <p style={{ fontSize: 13, fontFamily: 'arial', fontWeight: 'bold', color: '#007aff' }}>DROP FIELDS HERE</p>
                                    <p style={{ fontSize: 10, fontFamily: 'arial', fontWeight: 'bold', color: '#789', letterSpacing: 1 }}>Rhino Form Builder</p>

                                </div>
                            )}
                        </div>
                    </div>


                    <div className="col-3">
                        <div className="box pb-3">
                            <div onClick={() => { if (this.state.collapseMainInfo) { this.setState({ collapseMainInfo: false }) } }} className="p-2 text-center mb-3" style={{ cursor: 'pointer', backgroundColor: '#fcfcfc', borderBottom: '#f7f7f7 1px solid', borderTopLeftRadius: 15, WebkitBorderTopRightRadius: 15 }}>
                                <p className="mt-2 font-weight-bold">Field Options</p>
                                {/* <p style={{ fontSize: 10, color: '#456', letterSpacing: 0 }}>Field Options</p> */}
                            </div>

                            {this.state.collapseMainInfo && (
                                <div className="text-center">
                                    <div className="d-inline-flex">
                                        <p onClick={() => this.setState({ collapseMainInfo: false })} style={{ backgroundColor: '#f2f4f6', padding: '5px 15px 5px 15px', borderRadius: 20, cursor: 'pointer', color: '#789', fontSize: 11, fontWeight: 'bold', marginBottom: 0 }}>SHOW INFO</p>
                                    </div>
                                </div>
                            )}
                            <Collapse isOpened={!this.state.collapseMainInfo}>

                                {this.state.selectedIndex != null && this.props.itemsList[this.state.selectedIndex] && (
                                    <div>

                                        {this.renderInformationBox(this.props.itemsList[this.state.selectedIndex])}
                                        <div className={'col-12'}>
                                            <label className={'mb-1 mr-1 ml-1 mt-2 required'} style={{ marginBottom: 3, fontSize: 12, color: '#456', }}>Key</label>
                                            <TextCell onChange={this.changeKey} headerkey={'key'} info={{ label: 'unique key' }} title={this.props.itemsList[this.state.selectedIndex].key} />
                                            <p style={{ color: '#abc', lineHeight: 1.5, fontFamily: 'arial', fontWeight: 'normal', fontSize: 12, marginRight: 5, marginLeft: 5, marginTop: 7, alignItems: 'justify' }}>This key sould be unique in the form. dont change it if it is not required</p>
                                        </div>
                                    </div>
                                )}
                            </Collapse>
                            {/* )} */}


                        </div>

                        {this.state.subFormIsSelected && (
                            <div className="box pb-4 mt-2">
                                <div onClick={() => { if (!this.state.collapseMainInfo) { this.setState({ collapseMainInfo: true }) } }} className="p-2 text-center mb-3" style={{ cursor: 'pointer', backgroundColor: '#fcfcfc', borderBottom: '#f7f7f7 1px solid', borderTopLeftRadius: 15, WebkitBorderTopRightRadius: 15 }}>
                                    <p className="mt-2 font-weight-bold">SubForm Field</p>
                                    {/* <p style={{ fontSize: 10, color: '#456', letterSpacing: 1 }}>FIELDS OPTIONS</p> */}
                                </div>

                                {/* {this.state.collapseMainInfo && ( */}

                                {!this.state.collapseMainInfo && (
                                    <div className="text-center">
                                        <div className="d-inline-flex">
                                            <p onClick={() => this.setState({ collapseMainInfo: true })} style={{ backgroundColor: '#f2f4f6', padding: '5px 15px 5px 15px', borderRadius: 20, cursor: 'pointer', color: '#789', fontSize: 11, fontWeight: 'bold', marginBottom: 0 }}>SHOW INFO</p>
                                        </div>
                                    </div>
                                )}

                                <Collapse isOpened={this.state.collapseMainInfo}>
                                    {/* {console.log(this.state.selectedSubIndex)}
                                    {console.log(this.props.itemsList[this.state.selectedIndex].information.defaultValue[this.state.subFormIsSelected])} */}

                                    {this.state.selectedIndex != null && this.props.itemsList[this.state.selectedIndex] && this.props.itemsList[this.state.selectedIndex].information.headers && (
                                        <div>
                                            {this.renderInformationBox(this.props.itemsList[this.state.selectedIndex].information.headers[this.state.selectedSubIndex], true)}
                                            <div className={'col-12'}>
                                                <label className={'mb-1 mr-1 ml-1 mt-2 required'} style={{ marginBottom: 3, fontSize: 12, color: '#456', }}>Key</label>
                                                <TextCell onChange={this.changeSubKey} headerkey={'key'} info={{ label: 'unique key' }} title={this.props.itemsList[this.state.selectedIndex].information.headers[this.state.selectedSubIndex].key} />
                                                <p style={{ color: '#abc', lineHeight: 1.5, fontFamily: 'arial', fontWeight: 'normal', fontSize: 12, marginRight: 5, marginLeft: 5, marginTop: 7, alignItems: 'justify' }}>This key sould be unique in the form. dont change it if it is not required</p>
                                            </div>
                                        </div>
                                    )}
                                </Collapse>
                                {/* )} */}

                            </div>
                        )}


                    </div>

                </div>
            </div>

        );
    }


    changeInfoValue = (key, value) => {
        let itemsList = this.props.itemsList

        itemsList[this.state.selectedIndex].information[key] = value

        this.changeItemsList(itemsList)
    }


    changeSubInfoValue = (key, value) => {
        let itemsList = this.props.itemsList

        itemsList[this.state.selectedIndex].information.headers[this.state.selectedSubIndex].information[key] = value

        this.changeItemsList(itemsList)
    }



    changeKey = (key, value) => {
        let itemsList = this.props.itemsList
        itemsList[this.state.selectedIndex].key = value
        // this.setState({ itemsList })
        // console.log("changeKEY")
        this.changeItemsList(itemsList)
    }


    changeSubKey = (key, value) => {
        let itemsList = this.props.itemsList
        itemsList[this.state.selectedIndex].information.headers[this.state.selectedSubIndex].key = value
        // this.setState({ itemsList })
        console.log(itemsList[this.state.selectedIndex].information.headers[this.state.selectedSubIndex])
        this.changeItemsList(itemsList)
    }



    renderInformationBox = (item, isSubForm) => {
        let type = item.type
        if (FormBuilderObjects[type])

            return (


                FormBuilderObjects[type].headers.map((headerProp, index) => {
                    let value = item.information[headerProp.key]//.information[headerProp]//""//this.props.item.values

                    let info = Object.assign({}, headerProp.information)

                    info.options = item.information["options"] ? item.information["options"] : info.options

                    info.defaultValue = item.information["defaultValue"] ? item.information["defaultValue"] : info.defaultValue


                    let onChange = isSubForm ? this.changeSubInfoValue : this.changeInfoValue


                    // console.log(this.props.itemsList[this.state.selectedIndex])
                    return (
                        <div className={'col-12'} style={{}} key={index}>

                            <label className={'mb-1 mr-1 ml-1 mt-3 ' + (info.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, color: '#456', }}>{info.label}</label>

                            {headerProp.type == 'person' && (
                                <TextCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}
                            {headerProp.type == 'mapCell' && (
                                <MapCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}

                            {headerProp.type == 'textCell' && (
                                <TextCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}

                            {headerProp.type == 'textAreaCell' && (
                                <TextAreaCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}


                            {headerProp.type == 'dateCell' && (
                                <DateCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}

                            {headerProp.type == 'status' && (
                                <TextCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}

                            {headerProp.type == 'numberCell' && (
                                <NumberCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}

                            {headerProp.type == 'priceCell' && (
                                <PriceCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}

                            {headerProp.type == 'phoneCell' && (
                                <PhoneCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}


                            {headerProp.type == 'select' && (
                                <SelectCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} options={headerProp.options} />
                            )}

                            {headerProp.type == 'multiLevelCell' && (
                                <MultiLevelSelect onChange={onChange} headerkey={headerProp.key} info={info} title={value} options={headerProp.options} />
                            )}

                            {headerProp.type == 'multiSelectApiCell' && (
                                <MultiSelect onChange={onChange} headerkey={headerProp.key} info={info} title={value} options={headerProp.options} />
                            )}



                            {headerProp.type == 'editor' && (
                                <EditorCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} options={headerProp.options} />
                            )}

                            {headerProp.type == 'booleanCell' && (
                                <SwitchCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}

                            {headerProp.type == 'imageCell' && (
                                <ImageCell changeFiles={this.props.changeFiles} info={info} headerkey={headerProp.key} options={headerProp.options} />
                            )}

                            {headerProp.type == 'videoCell' && (
                                <VideoCell changeFiles={this.props.changeFiles} info={info} headerkey={headerProp.key} options={headerProp.options} />
                            )}


                            {headerProp.type == 'fileCell' && (
                                <FileCell changeFiles={this.props.changeFiles} info={info} headerkey={headerProp.key} options={headerProp.options} />
                            )}

                            {/* {headerProp.type == 'video' && (
                                <VideoCell changeFiles={this.props.changeFiles} info={info} headerkey={headerProp.key} options={headerProp.options} />
                            )} */}

                            {headerProp.type == 'optionMakerCell' && (
                                <OptionMakerCell onChange={onChange} headerkey={headerProp.key} info={info} title={value} />
                            )}



                        </div>
                    )
                })
            )

    }



}



export default Dashboard

