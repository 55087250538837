import React from "react";
// import autosize from "autosize"

class InputBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            canSend: false,
            message: ''
        }
    }

    componentDidMount() {
        // autosize(this.textarea);
    }

    changeText(value) {

        if (value == "") {
            this.setState({ canSend: false })
        } else if (!this.state.canSend) {
            this.setState({ canSend: true })
        }

        this.setState({ message: value })
    }

    refresh = () => {
        this.setState({ message: '' })
    }

    render() {
        return (
            <div className="w-100 d-flex align-items-center pl-2 pr-2 pt-3 pb-1" style={{ backgroundColor: '#fffffff7', borderTop: '#f2f4f6 solid 1px' }}>
                <div className="mb-2">
                    <img src="/images/gear1.png" height={25} />
                </div>
                <div className='ml-2 mr-2 w-100'>
                    <textarea onChange={e => this.changeText(e.target.value)} ref={c => (this.textarea = c)} rows={1} value={this.state.message} className="w-100" style={{ fontSize: 15, resize: 'none', boxSizing: 'border-box', minHeight: 30, maxHeight: '75px', padding: '6px 20px 6px 20px', backgroundColor: '#f4f6f8', borderRadius: 20, border: '#f0f2f4 solid 1px' }} />
                </div>
                <div className="mb-2">
                    <img src="/images/send1.png" height={25} style={{ opacity: this.state.canSend ? 1 : 0.4 }} />
                </div>
            </div>
        )
    }
}

export default InputBox